import * as React from 'react';
// import { Typography, useTheme, Grid, Container, Stack, Avatar, Divider, Alert } from '@mui/material';
import { useTheme, Menu, MenuItem, listClasses } from '@mui/material';
import _ from 'lodash';
import Fuse from 'fuse.js';
// import ReactTimeAgo from 'react-time-ago';

import PContactList from '../../../components/library/PContactList';

import { ReactComponent as Search } from '../../../assets/Icons/Search.svg';
// import { ReactComponent as ArrowDown } from '../../../assets/Icons/Arrow-Down.svg';

import ManualListModal from '../../setup/simpleSetupComponents/Lists/ManualListModal.js';
import AddContactsModal from './addContactsModal';
import EditModal from './sequenceSettingsModal/edit.js';
import PauseModal from './sequenceSettingsModal/pause.js';
import DeleteModal from './sequenceSettingsModal/delete.js';
import RenameModal from './sequenceSettingsModal/rename.js';
import Http from '../../../http/httpClient';
import {
  SequenceMailableStepIds,
  SequenceStepStatus,
  TwitterStepIds,
  RULE_ENGINE_CODES,
} from '../../../constants/constant.js';
import { truncateText } from '../../../helper/utilities.js';
import EmailModal from '../../../components/sequence/emailModal.js';
import PEditableHeader from '@/components/library/PEditableHeader';
const isEmail = (contact, listItem) => {
  const step = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);
  return ['meeting', 'followup', 'email', 'checkin.recurring'].includes(step?.id);
};

const isGift = (contact, listItem) => {
  const step = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);
  return ['gift'].includes(step?.id);
};

function getContactType(contact, listItem) {
  if (isEmail(contact, listItem)) return 'email';
  if (isGift(contact, listItem)) return 'gift';
  return 'social'; // Default to social if none of the above
}

export default function SequenceReview(props) {
  const theme = useTheme();
  let {
    userBio,
    contacts,
    contacts2,
    stepTypes,
    listItem,
    handleSequenceModification,
    fetchSequenceData,
    setCreatedSequenceId,
    setSequenceList,
    sequenceList,
    handleSequenceDelete,
    targetAudience,
    availableList,
    forDelegation,
  } = props;

  // const user = React.useMemo(() => userBio, [userBio]);
  const sequenceContacts = React.useMemo(() => contacts, [contacts]);
  const sequenceContacts2 = React.useMemo(() => contacts2, [contacts2]);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState('All');
  const [sortFields, setSortFields] = React.useState(['hasNull', 'name', 'title', 'company']);
  const [searchContact, setSearchContact] = React.useState('');
  const [editContact, setEditContact] = React.useState(null);
  const [editContactModalOpen, setEditContactModalOpen] = React.useState(false);
  const [showEditSequence, setShowEditSequence] = React.useState(false);
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [currentContact, setCurrentContact] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [renameOpen, setRenameOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [pauseOpen, setPauseOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const fuse = new Fuse(sequenceContacts, {
    keys: ['name', 'company', 'title'],
    threshold: 0.4,
    location: 0,
    distance: 100,
    findAllMatches: true,
    minMatchCharLength: 1,
  });
  const fuse2 = new Fuse(sequenceContacts2, {
    keys: ['name', 'company', 'title'],
    threshold: 0.4,
    location: 0,
    distance: 100,
    findAllMatches: true,
    minMatchCharLength: 1,
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => setModalOpen(false);
  const editContactModalClose = () => setEditContactModalOpen(false);

  const handleButtonClick = (action) => {
    if (['Remove', 'Pause', 'Unpause'].includes(action)) {
      handleContactAction(action, selectedContacts);
    } else if (['All', 'Upcoming', 'Recent', 'Active', 'Paused'].includes(action)) {
      setActiveButton(action);
    }
  };

  const handleToggleAll = (newChecked) => {
    if (newChecked) {
      setSelectedContacts(preparedContacts.map((contact) => contact._id));
    } else {
      setSelectedContacts([]);
    }
  };

  const getSkipLabel = (contact, rule) => {
    const statusCodes = contact?.stepStatus?.status?.split(',').map((code) => code.trim()) || [];
    return statusCodes.includes(rule.CODE);
  };

  const formatTimeUntilNextStep = (contact, currentStep) => {
    if (contact?.stepStatus?.stepType === 'linkedInEmail') {
      if (contact?.stepStatus?.status === SequenceStepStatus.FAILED) {
        return 'Failed';
      } else if (contact?.stepStatus?.status === SequenceStepStatus.MANUALLY_DELETED) {
        return 'Manually removed';
      }
    }

    const status = contact?.stepStatus?.status;
    switch (status) {
      case SequenceStepStatus.MANUALLY_DELETED:
        return 'Manually removed';
      case SequenceStepStatus.LIMITEXCEEDED:
        return 'Daily email limit exceeded';
      default:
        break;
    }

    const paused = contact.status === 'paused';
    if (paused) {
      return contact.pausedType ? contact.pausedType : 'Paused';
    }

    const skipped = contact?.stepStatus?.status === 'skipped';
    if (skipped) {
      return 'Failed';
    }

    const skipLabels = RULE_ENGINE_CODES.filter((rule) => getSkipLabel(contact, rule));

    if (skipLabels && skipLabels.length > 0) {
      // sort by weight largest to smallest
      skipLabels.sort((a, b) => {
        return b.WEIGHT - a.WEIGHT;
      });
      return skipLabels[0].LABEL;
    }

    const nextStepExecTime = contact?.stepStatus?.sentAt || contact?.stepStatus?.startDate;
    const isTwiiiterStep = TwitterStepIds.includes(contact?.stepStatus?.stepType);
    if (!contact.nextStepId && isTwiiiterStep && new Date(nextStepExecTime) > new Date()) {
      return 'Next step';
    }

    if (!nextStepExecTime) {
      const likeStepExist = contact.stepStatus?.stepType === 'like';
      const lastStepCompletedTime = contact?.lastStepCompletedTime;
      const lastCompletedStepId = contact?.lastCompletedStepId;
      const isExceeded =
        contact.stepStatus?.id?.toString() === lastCompletedStepId?.toString() &&
        contact.stepStatus?.status === SequenceStepStatus.LIMITEXCEEDED;

      if (isExceeded) {
        return 'Daily email limit exceeded';
      }
      if (!lastStepCompletedTime) {
        return likeStepExist ? 'Auto-liking their posts frequently.' : '';
      }

      const stepCompletedTime = new Date(lastStepCompletedTime);

      const formattedTime = stepCompletedTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });

      return (
        <>
          Completed on {formattedTime}
          {likeStepExist && <p>Auto-liking their posts frequently.</p>}
        </>
      );
    }

    const nextStepTime = new Date(nextStepExecTime);
    let sentAtTime = null;
    const now = new Date();

    let diff = nextStepTime - now;
    const isPast = diff <= 0;
    if (isPast) {
      const status = contact?.stepStatus?.status;
      const ONE_DAYms = 1000 * 60 * 60 * 24;
      if (status === SequenceStepStatus.NOTAPPROVED && diff <= -ONE_DAYms) {
        return 'Expired';
      }
      switch (status) {
        case SequenceStepStatus.MANUALLY_DELETED:
          return 'Manually removed';
        case SequenceStepStatus.NOTAPPROVED:
          return 'Waiting for approval';

        case SequenceStepStatus.LIMITEXCEEDED:
          return 'Daily email limit exceeded';

        case SequenceStepStatus.QUEUED:
          const stepType = getContactType(contact, listItem);
          if (stepType === 'email' || stepType === 'gift') {
            return status === SequenceStepStatus.NOTAPPROVED
              ? `Next ${stepType} step waiting for approval`
              : `Next ${stepType} step scheduled for processing`;
          } else {
            return 'Next social step scheduled for processing';
          }

        default:
          diff = -diff;
          break;
      }
    }

    const formattedTime = getFormattedTime(sentAtTime, currentStep, nextStepTime, now);
    if (contact.status === 'active' && contact.message?.startsWith('OOO')) {
      return `${contact.message}. ${formattedTime}`;
    }

    return formattedTime;
  };

  // Grossly extracted from above. Will need rework.
  const getFormattedTime = (sentAtTime, currentStep, nextStepTime, now) => {
    const diff = nextStepTime - now;
    const isPast = diff <= 0;

    const mins = 60 * 1000;
    const hrs = 60 * mins;
    const day = 24 * hrs;
    const week = 7 * day;
    const month = 30 * day;

    const months = Math.floor(diff / month);
    const weeks = Math.floor((diff % month) / week);
    const days = Math.floor((diff % month) / day);
    const hours = Math.floor((diff % day) / hrs);
    const minutes = Math.floor((diff % hrs) / mins);

    let formattedTime = '';
    if (isPast) {
      if (months > 0) {
        formattedTime = sentAtTime?.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else if (weeks > 0) {
        formattedTime = `${weeks}w ago`;
      } else if (days > 0) {
        formattedTime = `${days}d ago`;
      } else if (hours > 0) {
        formattedTime = `${hours}h ago`;
      } else if (minutes > 0) {
        formattedTime = `${minutes}m ago`;
      } else {
        formattedTime = 'less than a minute ago';
      }

      return formattedTime;
    } else {
      const verb = currentStep && currentStep.approveRequired ? 'Starts' : 'Sends';
      if (months > 0) {
        const monthName = nextStepTime.toLocaleDateString('en-US', {
          month: 'long',
        });
        formattedTime = `${verb} in ${monthName}`;
        // formattedTime = paused ? (`Would send the email ${months === 1 ? 'a month' : `${months} months`} after unpausing`) : `Starts in ${nextStepTime.getDate()}, ${monthName}`
        //formattedTime += `Starts in ${months} ${months === 1 ? 'month' : 'months'}`
      } else {
        if (days > 0) {
          formattedTime += `${verb} ${days === 1 ? 'tomorrow' : `in ${days} days`}`;
          // formattedTime += paused ? `Would send the email ${days === 1 ? 'the day' : `${days} days`} after unpausing` : `Starts ${days === 1 ? 'tomorrow' : `in ${days} days`}`
        } else {
          // Using "else if" to reduce granularity
          if (hours > 0) {
            formattedTime += ` ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
          } else if (minutes > 0) {
            formattedTime += ` ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
          }

          if (formattedTime.length === 0) {
            formattedTime += 'less than a minute';
          }
          // formattedTime = paused ? `Would send the email ${formattedTime} after unpausing` : `Starts in ${formattedTime}`
          formattedTime = `${verb} in ${formattedTime}`;
        }
      }
    }

    return formattedTime;
  };

  const formatTimeToolTip = (contact, currentStep) => {
    if (contact?.stepStatus?.status === 'failed' && !!contact.stepStatus?.linkedInResponse?.failReason) {
      return `Failed due to ${contact.stepStatus.linkedInResponse.failReason.toLowerCase()}`;
    } else if (contact?.stepStatus?.status === 'queued' && !!contact.stepStatus?.linkedInResponse?.delayedDueToLimit) {
      if (contact.stepStatus?.linkedInResponse?.action === 'profileVisit') {
        return `Rescheduled due to reaching the daily limit for LinkedIn profile visits.`;
      } else {
        return `Rescheduled due to reaching the daily limit.`;
      }
    }
    return '';
  };

  const handleContactAction = (action, contactIds) => {
    setIsLoading(true);
    const sequenceData = { sequenceId: listItem._id, contactIds: contactIds };
    Http.postData('updateSequenceContactSettings', {
      type: action,
      sequenceData,
      pausedType: 'Manually paused',
    })
      .then((res) => {
        if (res && res.status) {
          if (action === 'Remove') setSelectedContacts([]);
          handleSequenceModification();
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getContactStepStatus = (contact) => {
    let status = '';
    if (contact?.nextStepExecTime) {
      const stepDetails = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

      let emailTemplate = stepDetails?.emailTemplate;
      if (stepDetails?.id === 'checkin.recurring') {
        const nextStepStatus = contact.stepStatus;
        emailTemplate = stepDetails?.emailTemplates?.[nextStepStatus?.recurrenceIndex];
      }
      const preciseSubject = truncateText(emailTemplate?.subjectLine, 30);
      // status = stepTypes.find(
      //   (st) => st.id === contact.stepStatus?.filter((s) => !TwitterStepIds.includes(s.stepType))?.[0].stepType,
      // )?.desc;
      status = preciseSubject;
    } else if (contact?.lastCompletedStepId) {
      const lastStep = contact?.stepStatus?.id?.toString() === contact.lastCompletedStepId?.toString();
      if (
        lastStep &&
        SequenceMailableStepIds.includes(contact.stepStatus?.stepType) &&
        contact.stepStatus?.status === 'failed'
      ) {
        status = 'Bounced';
      }
    }

    const isTwiiiterStep = TwitterStepIds.includes(contact?.stepStatus?.stepType);
    if (isTwiiiterStep) {
      const stepType = contact?.stepStatus?.stepType;
      status = `Auto-${getStepTypeWord(stepType)} ${getTarget(stepType)}`;
    }

    return status;
  };

  const getStepTypeWord = (stepType) => {
    switch (stepType) {
      case 'share':
        return 'reshared';
      case 'connect':
        return `request to connect`;
      case 'follow':
      case 'comment':
        return `${stepType}ed`;
      default:
        return `${stepType}d`;
    }
  };

  const getTarget = (stepType) => {
    switch (stepType) {
      case 'connect':
        return `on social media`;
      case 'follow':
        return `them on social media`;
      default:
        return `their posts`;
    }
  };

  const closeEmail = () => {
    setEmailOpen(false);
  };

  const handleSeeEmail = (contact) => {
    setCurrentContact(contact);
    setCurrentStep(listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id));
    setEmailOpen(true);
  };

  const _hasMultipleEmails = (contact) => {
    const step = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);
    return step.id === 'checkin.recurring' && step.frequency !== 'Once a year';
  };

  React.useEffect(() => {
    const sortingFieldsMap = {
      All: ['hasNull', 'name', 'title', 'company'],
      Role: ['hasNull', 'title', 'name', 'company'],
      Company: ['hasNull', 'company', 'name', 'title'],
      Channel: [],
    };

    const sortingFields = sortingFieldsMap[activeButton] || ['hasNull', 'name', 'title', 'company'];
    setSortFields(sortingFields);
    setSelectedContacts([]);
  }, [activeButton]);

  function customSort(contact) {
    if (!contact.nextStepExecTime) {
      return contact.status === 'active' ? 1 : 0;
    } else {
      return contact.status === 'paused' ? 0 : -1;
    }
  }

  const handleSearchSequenceContact = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchContact(value);
    if (value === '') {
      setFilteredContacts(sequenceContacts);
    } else {
      const results = fuse.search(value).map(({ item }) => item);
      setFilteredContacts(results);
    }
  };

  const handleClearSearch = () => {
    setSearchContact('');
    setFilteredContacts(sequenceContacts);
  };

  React.useEffect(() => {
    const searchQuery = searchContact.toLowerCase();

    let contacts = [];
    if (activeButton === 'All') {
      contacts = searchQuery ? fuse2.search(searchQuery).map((contact) => contact.item) : sequenceContacts2;
    } else {
      contacts = searchQuery ? fuse.search(searchQuery).map((contact) => contact.item) : sequenceContacts;
    }

    const preliminaryFiltered = contacts?.filter((contact) => {
      if (contact === null) return false;

      const isStatusActive = contact.status === 'active';
      const isStatusPaused = contact.status === 'paused';
      const stepStatus = contact.stepStatus;
      const stepType = stepStatus?.stepType;
      const startDate = new Date(stepStatus?.startDate);
      const currentDate = new Date();
      const ONE_DAYms = 1000 * 60 * 60 * 24;

      switch (activeButton) {
        case 'All':
          return true;

        case 'Upcoming':
          return (
            isStatusActive &&
            ![SequenceStepStatus.MANUALLY_DELETED].includes(stepStatus?.status) &&
            ((contact.nextStepId === stepStatus?.id &&
              SequenceMailableStepIds.includes(stepStatus?.stepType) &&
              startDate.getTime() + ONE_DAYms > currentDate.getTime()) ||
              (contact.nextStepId === stepStatus?.id && !SequenceMailableStepIds.includes(stepStatus?.stepType)) ||
              (contact.nextStepId === null && TwitterStepIds.includes(stepType) && startDate > currentDate))
          );

        case 'Recent':
          return (
            isStatusActive &&
            ([
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) ||
              (TwitterStepIds.includes(stepType) && startDate < currentDate))
          );

        case 'Paused':
          return isStatusPaused;

        case 'Active':
          return (
            isStatusActive &&
            ![
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) &&
            !TwitterStepIds.includes(stepType)
          );

        case 'Expired':
          return (
            isStatusActive &&
            stepStatus?.status === SequenceStepStatus.NOTAPPROVED &&
            startDate.getTime() < currentDate.getTime() - ONE_DAYms
          );

        default:
          return false;
      }
    });

    let filtered = [];
    if (activeButton === 'Paused') {
      const uniqueIds = new Set();
      filtered = preliminaryFiltered.filter((contact) => {
        const isDuplicate = uniqueIds.has(contact._id);
        uniqueIds.add(contact._id);
        return !isDuplicate;
      });
    } else {
      filtered = preliminaryFiltered;
    }

    const sortedContacts = _.orderBy(filtered, activeButton === 'Active' ? customSort : sortFields, ['asc']);
    setFilteredContacts([...sortedContacts]);
  }, [searchContact, sortFields, sequenceContacts, props.refreshKey]);

  const closeRename = () => setRenameOpen(false);
  const closeEdit = () => setEditOpen(false);
  const closePause = () => setPauseOpen(false);
  const closeDelete = () => setDeleteOpen(false);

  const _filterContacts = () => {
    const searchQuery = searchContact.toLowerCase();

    const contacts = searchQuery ? fuse.search(searchQuery).map((contact) => contact.item) : sequenceContacts;

    const filtered = contacts.filter((contact) => {
      return (
        contact !== null &&
        ((activeButton === 'Upcoming' && contact.status === 'active' && contact.nextStepExecTime) ||
          (activeButton === 'Recent' && contact.status === 'active' && contact.lastCompletedStepId != null) ||
          (activeButton === 'Paused' && contact.status === 'paused') ||
          activeButton === 'All')
      );
    });

    const sortedContacts = _.orderBy(filtered, activeButton === 'All' ? customSort : sortFields, ['asc']);
    setFilteredContacts([...sortedContacts]);
  };

  const handlePauseClick = () => {
    setPauseOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
    handleMenuClose();
  };

  const handleRenameClick = () => {
    setRenameOpen(true);
    handleMenuClose();
  };

  const handleEditClick = () => {
    setEditOpen(true);
    handleMenuClose();
  };

  const handleContactSelection = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      const isSelected = prevSelectedContacts.includes(contactId);
      return isSelected ? prevSelectedContacts.filter((id) => id !== contactId) : [...prevSelectedContacts, contactId];
    });
  };

  const handleContactClick = (contact) => {
    setEditContact(contact);
    setEditContactModalOpen(true);
  };

  const _handleAllContactsSelection = () => {
    setSelectedContacts((prevSelectedContacts) => {
      const alreadySelected = filteredContacts.every((fc) => prevSelectedContacts.includes(fc._id));
      if (alreadySelected) {
        return prevSelectedContacts.filter((scId) => !filteredContacts.some((fc) => fc._id === scId));
      } else {
        const nonSelectedContacts = filteredContacts
          .filter((fc) => !prevSelectedContacts.includes(fc._id))
          .map((fc) => fc._id);
        return [...prevSelectedContacts, ...nonSelectedContacts];
      }
    });
  };

  const preparedContacts = filteredContacts
    .map((contact) => {
      if (!contact) return null;

      const currentStep = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

      const stepIndex = listItem?.template?.steps?.findIndex((s) => s._id === contact?.stepStatus?.id) + 1;
      const totalSteps = listItem?.template?.steps?.length;

      const emailSubject = currentStep?.emailTemplate?.subjectLine;
      const stepSubtitle = `Step ${stepIndex}/${totalSteps}`;
      const stepStatus = getContactStepStatus(contact);

      return {
        _id: contact._id,
        name: contact.name,
        photo_url: contact.photo_url,
        role: contact.title,
        company: contact.company,
        time: formatTimeUntilNextStep(contact, currentStep),
        timeToolTip: formatTimeToolTip(contact, currentStep),
        seeEmail: () => handleSeeEmail(contact),
        emailSubject: emailSubject || null,
        stepSubtitle: emailSubject ? stepSubtitle : stepStatus,
        checked: selectedContacts.includes(contact._id),
        onCheck: () => handleContactSelection(contact._id),
        onClickName: () => handleContactClick(contact),
        isLinkedInMail: contact?.stepStatus?.stepType === 'linkedInEmail',
      };
    })
    .filter(Boolean);

  const uniqueContactIDs = [...new Set(preparedContacts.map((contact) => contact._id))];
  const uniqueSelectedIDs = [...new Set(selectedContacts)];

  // ==============================
  const [headerText, setHeaderText] = React.useState(listItem.name || '');

  React.useEffect(() => {
    setHeaderText(listItem.name || '');
  }, [listItem]);

  const handleRename = () => {
    const newName = headerText.trim() || 'Untitled';
    Http.postData('updateSequenceSettings', {
      type: 'Rename',
      sequenceData: {
        _id: listItem._id,
        sequenceName: newName,
      },
    })
      .then((res) => {
        if (res.status) {
          const newSequence = res.userSequence.sequences.find((item) => item._id === listItem._id);
          console.log('newSequence', newSequence);

          if (newSequence) {
            setCreatedSequenceId(listItem._id);

            const updatedSequenceList = sequenceList.map((item) =>
              item._id === listItem._id ? { ...item, name: newSequence.name } : item,
            );

            setSequenceList(updatedSequenceList);
          }
        }
      })
      .catch((error) => {
        console.error('Error renaming sequence:', error);
      });
  };
  // ==============================

  return (
    (!showEditSequence && (
      <>
        <PauseModal
          forDelegation={forDelegation}
          open={pauseOpen}
          onClose={closePause}
          activeItem={listItem}
          onRefresh={fetchSequenceData}
        />
        <DeleteModal
          forDelegation={forDelegation}
          open={deleteOpen}
          onClose={closeDelete}
          handleSequenceDelete={() => handleSequenceDelete(listItem._id)}
        />
        <RenameModal
          forDelegation={forDelegation}
          open={renameOpen}
          onClose={closeRename}
          activeItem={listItem}
          setCreatedSequenceId={setCreatedSequenceId}
          setSequenceList={setSequenceList}
          sequenceList={sequenceList}
        />
        {editOpen && (
          <EditModal
            open={editOpen}
            onClose={closeEdit}
            activeItem={listItem}
            template={listItem.template}
            onRefresh={fetchSequenceData}
          />
        )}
        {modalOpen && (
          <AddContactsModal
            open={modalOpen}
            onClose={closeModal}
            source={'sequence'}
            sequenceItem={listItem}
            handleModification={handleSequenceModification}
            targetAudience={targetAudience}
            existingList={availableList}
          />
        )}
        {/* {confirmModal && <ConfirmationModal open={confirmModal} onClose={closeConfirmModal} activeItem={listItem}
        contact={selectedContact} modalType={modalType} handleModification={handleSequenceModification} /> } */}

        {!forDelegation && (
          <PContactList
            headerComponent={<PEditableHeader text={headerText} onChange={setHeaderText} onAction={handleRename} />}
            header1Text="Edit"
            header1Theme="secondary"
            header1OnClick={(event) => handleMenuOpen(event)}
            {...(!forDelegation && {
              header2Text: 'Add contacts',
              header2Theme: 'primary',
              header2OnClick: () => setModalOpen(true),
            })}
            inputPlaceHolder="Search name, role, company"
            inputIcon={Search}
            inputOnChange={handleSearchSequenceContact}
            onClear={handleClearSearch}
            activeFilter={activeButton}
            filterAction={handleButtonClick}
            filterChange={setActiveButton}
            filterToggleAll={handleToggleAll}
            filterTotalCount={uniqueContactIDs.length}
            filterSelectedCount={uniqueSelectedIDs.length}
            contacts={preparedContacts}
            loadingState={isLoading}
          />
        )}

        {isMenuOpen && (
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                borderRadius: '8px',
                elevation: 0,
                minWidth: '200px',
                boxShadow:
                  '0px 1px 2px -1px rgba(0,0,0,0.1), ' +
                  '0px 2px 3px 0px rgba(0,0,0,0.07), ' +
                  '0px 1px 5px 0px rgba(0,0,0,0.06)',
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={handleRenameClick}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '14px',
                color: theme.palette.primary.black,
              }}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={handleEditClick}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '14px',
                color: theme.palette.primary.black,
              }}
            >
              Edit steps
            </MenuItem>
            <MenuItem
              onClick={handlePauseClick}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '14px',
                color: theme.palette.primary.black,
              }}
            >
              {listItem.status === 'Active' ? 'Pause' : 'Unpause'}
            </MenuItem>
            <MenuItem
              onClick={handleDeleteClick}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '14px',
                color: theme.palette.primary.orange,
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        )}
        {emailOpen && (
          <EmailModal
            open={emailOpen}
            onClose={closeEmail}
            step={currentStep}
            setupList={null}
            isEditEmail={false}
            listItem={listItem}
            readOnly={true}
            contact={currentContact}
            userBio={userBio}
          />
        )}
        {editContactModalOpen && (
          <ManualListModal
            open={editContactModalOpen}
            onClose={editContactModalClose}
            contact={editContact}
            forSetup={false}
            refreshListContacts={() => {
              editContactModalClose();
              handleSequenceModification();
            }}
          />
        )}
      </>
    )) ||
    (showEditSequence && listItem && (
      <EditModal
        open={editOpen}
        onClose={closeEdit}
        activeItem={listItem}
        template={listItem.template}
        onRefresh={fetchSequenceData}
      />
    ))
  );
}
