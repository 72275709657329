import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import BusinessIcon from '@mui/icons-material/Business'
import CodeIcon from '@mui/icons-material/Code'
import DataObjectIcon from '@mui/icons-material/DataObject';

import ManageUsers from './manageUsers/manageUsers'
import UserContents from './userContents/userContents'
import UsageAnalytics from './usageAnalytics/usageAnalytics'
import PostQueueAnalytics from './postQueueAnalytics'
import ManageCompanies from './manageCompanies/manageCompanies'
import FocusContactAnalytics from './focusContactAnalytics'
import ScriptManager from './scriptManager/scriptManager'
import AISearchAuditLogs from './AISearchAuditLogs'
import FeatureFlagPanel from './featureFlagPanel'
import ApiTokenManager from './apiTokenManager'
import FundzUploadData from './fundz/fundzUploadData'
import ManageInvites from './manageInvites/ManageInvites'
import ManageFundz from './fundz/manageFundz'
import Automation from './automation/automation'
import PostTemplateManagement from './postTemplates/postTemplateManagement'
import LinkedInScraping from './linkedin/linkedInScraping/linkedInScraping'
import RelationshipScores from './relationshipScores/relationshipScores'

const drawerWidth = 240

export default function AdminSettings () {
  const [selectedMenu, setSelectedMenu] = React.useState('Manage Users')

  const handleListItemClick = (event, selMenu) => {
    setSelectedMenu(selMenu)
  }

  let content = null
  switch (selectedMenu) {
    case 'Manage Users':
      content = <ManageUsers />
      break
    case 'User Contents':
      content = <UserContents />
      break
    case 'Usage Analytics':
      content = <UsageAnalytics />
      break
    case 'Post Queue Analytics':
      content = <PostQueueAnalytics />
      break
    case 'Manage Companies':
      content = <ManageCompanies />
      break
    case 'Focus Contact Analytics':
      content = <FocusContactAnalytics />
      break
    case 'AI Search Audit Logs':
      content = <AISearchAuditLogs />
    break
    case 'Feature Flags':
      content = <FeatureFlagPanel />
      break
    case 'Script Manager':
      content = <ScriptManager />
      break
    case 'Manage API Tokens':
      content = <ApiTokenManager />
      break
    case 'Fundz Management':
      content = <ManageFundz />
      break
    case 'Manage Invites':
      content = <ManageInvites />
      break
    case 'Automation':
      content = <Automation />
      break
    case 'Post Templates':
      content = <PostTemplateManagement />
      break
    case 'LinkedIn Scraping':
      content = <LinkedInScraping />
      break
    case 'Relationship Scores':
      content = <RelationshipScores />
      break
    default:
      content = <ManageUsers />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant='h6' noWrap component='div'>
            Admin Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem key='Manage Users' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Manage Users'}
              onClick={(event) => handleListItemClick(event, 'Manage Users')}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Users' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Manage Companies' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Manage Companies'}
              onClick={(event) => handleListItemClick(event, 'Manage Companies')}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Companies' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Manage Invites' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Manage Invites'}
              onClick={(event) => handleListItemClick(event, 'Manage Invites')}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Invites' />
            </ListItemButton>
          </ListItem>
          <ListItem key='User Contents' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'User Contents'}
              onClick={(event) => handleListItemClick(event, 'User Contents')}
            >
              <ListItemIcon>
                <FolderSharedIcon />
              </ListItemIcon>
              <ListItemText primary='User Contents' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Usage Analytics' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Usage Analytics'}
              onClick={(event) => handleListItemClick(event, 'Usage Analytics')}
            >
              <ListItemIcon>
                <DataUsageIcon />
              </ListItemIcon>
              <ListItemText primary='Usage Analytics' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Post Queue Analytics' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Post Queue Analytics'}
              onClick={(event) => handleListItemClick(event, 'Post Queue Analytics')}
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary='Post Queue Analytics' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Focus Contact Analytics' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Focus Contact Analytics'}
              onClick={(event) => handleListItemClick(event, 'Focus Contact Analytics')}
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary='Focus Contact Analytics' />
            </ListItemButton>
          </ListItem>
          <ListItem key='AI Search Audit Logs' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'AI Search Audit Logs'}
              onClick={(event) => handleListItemClick(event, 'AI Search Audit Logs')}
            >
              <ListItemIcon>
                <DataObjectIcon />
              </ListItemIcon>
              <ListItemText primary='AI Search Audit Logs' />
            </ListItemButton>
          </ListItem>
          <ListItem key='FeatureFlags' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Feature Flags'}
              onClick={(event) => handleListItemClick(event, 'Feature Flags')}
            >
              <ListItemIcon>
                <DataObjectIcon />
              </ListItemIcon>
              <ListItemText primary='Feature Flags' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Script Manager' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Script Manager'}
              onClick={(event) => handleListItemClick(event, 'Script Manager')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Script Manager' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Manage API Tokens' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Script Manager'}
              onClick={(event) => handleListItemClick(event, 'Manage API Tokens')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Manage API Tokens' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Fundz Management' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Fundz Management'}
              onClick={(event) => handleListItemClick(event, 'Fundz Management')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Fundz Management' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Automation' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Automation'}
              onClick={(event) => handleListItemClick(event, 'Automation')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Automation' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Post Templates' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Post Templates'}
              onClick={(event) => handleListItemClick(event, 'Post Templates')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Post Templates' />
            </ListItemButton>
          </ListItem>
          <ListItem key='LinkedIn Scraping' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'LinkedIn Scraping'}
              onClick={(event) => handleListItemClick(event, 'LinkedIn Scraping')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='LinkedIn Scraping' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Relationship Scores' disablePadding>
            <ListItemButton
              selected={selectedMenu === 'Relationship Scores'}
              onClick={(event) => handleListItemClick(event, 'Relationship Scores')}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary='Relationship Scores' />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box
        component='main'
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <>
          {content}
        </>
      </Box>
    </Box>
  )
}
