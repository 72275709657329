import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Stack } from '@mui/material';
import PTypography from '@Library/PTypography';
import { useTheme } from '@mui/material/styles';
import { truncateText, getTimeUntilScheduledDate, formatLargeNumber } from '@/helper/utilities';

const avatarStyle = {
  height: '32px',
  width: '32px',
};
const boxStyle = {
  mt: '12px',
  maxWidth: '100%',
};
const messageStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const StockSalesCard = ({ post, onContactClick }) => {
  const { milestoneDetails } = post?.sequenceAttr;
  const theme = useTheme();
  const sourceStyle = theme.palette.primaryCL.Black100;
  const stackStyle = {
    backgroundColor: theme.palette.primaryCL.Black50,
    borderRadius: 'var(--8, 8px)',
    width: '100%',
    ml: '1px',
    mb: '20px',
    p: '12px',
    overflow: 'hidden',
  };

  const getEmailLabel = () => {
    const { email } = post?.sequenceAttr?.contactDetails || {};
    if (email) {
      return ` - ${email}`;
    }
    return ' - LinkedIn Message';
  };

  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (onContactClick) {
        onContactClick();
        event.stopPropagation();
      }
    },
    [onContactClick],
  );

  return (
    <Box>
      <Stack direction="column" spacing="24px">
        <Stack direction="column" justifyContent="space-between" sx={stackStyle}>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Box onClick={handleContactClick}>
              <Avatar sx={avatarStyle} src={post.ImageUrl} />
            </Box>

            <Box onClick={handleContactClick}>
              <Stack direction="column" spacing="2px" alignItems="flex-start">
                {post?.name && (
                  <PTypography size="body2" weight="bold">
                    {post?.name}
                    {getEmailLabel()}
                  </PTypography>
                )}
                {post?.role && (
                  <PTypography size="footnote" weight="regular">
                    {post.role}
                  </PTypography>
                )}
                {post?.company && (
                  <PTypography size="footnote" weight="regular">
                    {post.company}
                  </PTypography>
                )}
              </Stack>
            </Box>
          </Stack>

          <Box sx={boxStyle}>
            <PTypography size="body2" weight="regular" preformatted sx={messageStyle}>
              {truncateText(post.message, 150) || ''}
            </PTypography>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ mt: 3 }} spacing={1} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column" spacing="2px" alignItems="flex-start">
          <PTypography size="body2" weight="bold">
            Stock sales info
          </PTypography>
        </Stack>
        <Stack direction="column" spacing="2px" alignItems="flex-start">
          {milestoneDetails?.approximateSaleDate && (
            <PTypography size="footnote" weight="regular" color={sourceStyle}>
              Expires {getTimeUntilScheduledDate(milestoneDetails?.approximateSaleDate)}
            </PTypography>
          )}
        </Stack>
      </Stack>
      <Box sx={{ mt: 0.5 }}>
        <PTypography size="footnote" weight="regular">
          {milestoneDetails?.issuerName && `${milestoneDetails?.issuerName} ᛫ `}
          {milestoneDetails?.accountHolderName && `${milestoneDetails?.accountHolderName} ᛫ `}
          {milestoneDetails?.noOfUnitsSold &&
            `${formatLargeNumber(milestoneDetails?.noOfUnitsSold)} Shares ᛫ `}
          {milestoneDetails?.aggregateMarketValue &&
            `$${formatLargeNumber(milestoneDetails?.aggregateMarketValue)}`}
        </PTypography>
      </Box>
    </Box>
  );
};

StockSalesCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default StockSalesCard;
