import * as React from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Link from '@mui/material/Link'

import LoginDetailsModal from './loginDetailsModal'
import ReplyPostModal from './replyPostModal'
import LeadershipPostModal from './leadershipPostModal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function UserStat ({ stats, handleCloseModal, buttonLoad }) {
  const [loginModalOpen, setLoginModalOpen] = React.useState(false)
  const [replyPostModalOpen, setReplyPostModalOpen] = React.useState(false)
  const [replyDeletedModalOpen, setReplyDeletedModalOpen] = React.useState(false)
  const [postModalOpen, setPostModalOpen] = React.useState(false)
  const [postDeletedModalOpen, setPostDeletedModalOpen] = React.useState(false)

  const handleLinkClick = () => {
    setLoginModalOpen(true)
  }

  const handleClose = () => {
    setLoginModalOpen(false)
    setReplyPostModalOpen(false)
    setReplyDeletedModalOpen(false)
    setPostModalOpen(false)
    setPostDeletedModalOpen(false)
  }

  const handlePostReplyClick = () => {
    setReplyPostModalOpen(true)
  }

  const handleReplyDeletedClick = () => {
    setReplyDeletedModalOpen(true)
  }

  const handlePostPublishedClick = () => {
    setPostModalOpen(true)
  }

  const handlePostDeletedClick = () => {
    setPostDeletedModalOpen(true)
  }

  return (
    <>
      <StyledTableRow key={'10'}>
        <StyledTableCell component='th' scope='row'>{stats.user?.name}</StyledTableCell>
        <StyledTableCell>{stats.user?.id}</StyledTableCell>
        <StyledTableCell>
          <Link to="#" onClick={handleLinkClick} style={{ cursor: 'pointer' }} >
            {stats.logins?.length}
          </Link>
          {loginModalOpen && <LoginDetailsModal open={loginModalOpen} onClose={handleClose} loginArr={stats.logins}/>}
        </StyledTableCell>
        <StyledTableCell>
          <Link to="#" onClick={handlePostReplyClick} style={{ cursor: 'pointer' }} >
            {stats.repliesPosted?.length}
          </Link>
          {replyPostModalOpen && <ReplyPostModal open={replyPostModalOpen} onClose={handleClose} posts={stats.repliesPosted} type={'published'}/>}
        </StyledTableCell>
        <StyledTableCell>
          <Link to="#" onClick={handlePostPublishedClick} style={{ cursor: 'pointer' }} >
            {stats.postsPosted?.length}
          </Link>
          {postModalOpen && <LeadershipPostModal open={postModalOpen} onClose={handleClose} posts={stats.postsPosted} type={'published'}/>}
        </StyledTableCell>
        <StyledTableCell>
          <Link to="#" onClick={handleReplyDeletedClick} style={{ cursor: 'pointer' }} >
            {stats.repliesDeleted?.length}
          </Link>
          {replyDeletedModalOpen && <ReplyPostModal open={replyDeletedModalOpen} onClose={handleClose} posts={stats.repliesDeleted} type={'deleted'}/>}
        </StyledTableCell>
        <StyledTableCell>
          <Link to="#" onClick={handlePostDeletedClick} style={{ cursor: 'pointer' }} >
            {stats.postsDeleted?.length}
          </Link>
          {postDeletedModalOpen && <LeadershipPostModal open={postDeletedModalOpen} onClose={handleClose} posts={stats.postsDeleted} type={'deleted'}/>}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}
