import * as React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses }  from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export default function LeadershipPostModal({ open, onClose, posts, type }) {
  const [modalTitle, setModalTitle] = React.useState('')

  React.useEffect(() => {
    if (type === 'published') {
      setModalTitle('Published Leadership Posts')
    } else if (type === 'deleted') {
      setModalTitle('Deleted Leadership Posts')
    }
  }, [type])

  const handleOnClose = (event) => {
    onClose(event)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth="true">
      <DialogTitle
        sx={{
          fontWeight: 800,
          fontSize: 18,
          letterSpacing: '-0.02em',
          py: 3,
        }}
      >
        { modalTitle }
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Post Text</StyledTableCell>
                <StyledTableCell>Date and Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {posts && posts.length > 0 ? (
                posts.map((post, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>{post.text ?? post.post?.message}</TableCell>
                    <TableCell>{post.actionTime ? new Date(post.actionTime).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }) : ''} </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={"2"} style={{ borderBottom: 'none' }}>
                    <Typography align='center'>
                      There is no post details associated with this user.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider />
      <Box sx={{ mr: 2, mb: 2, mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ mr: 2, mt: 1 }}
            variant="contained"
            onClick={(e) => { handleOnClose(e) }}>
          Close
        </Button>
      </Box>
    </Dialog>
  )
}
