import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/system';
import { Avatar, Badge } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PTypography from '@Library/PTypography';
import PButton from '@Library/PButton';
import PToolTip from '@Library/PToolTip';
import PBadge from '@Library/PBadge';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const PButtonStyle = {
  display: 'flex',
  width: '40px',
  height: '40px',
  minWidth: '0',
  padding: '0',
};

const stackStyle = { mt: '12px' };
const boxStyle = { display: 'flex', gap: '8px' };

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        hoverBackground: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Black70,
        hoverBorder: theme.palette.primaryCL.Black70,
        activeBorder: theme.palette.primaryCL.Blue100,
      };
      break;

    default:
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        hoverBackground: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Black70,
        hoverBorder: theme.palette.primaryCL.Black70,
        activeBorder: theme.palette.primaryCL.Blue100,
      };
      break;
  }

  return colorScheme;
};

const StyledPCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, disabled }) => {
  const { background, text, border, hoverBorder, activeBorder, hoverBackground } = getVariantStyles(theme, pVariant);

  return {
    'borderRadius': '14px',
    'border': '1px solid',
    'borderColor': border,
    'padding': '16px',
    'width': '100%',
    'fontFamily': 'Inter',
    'cursor': 'pointer',
    'color': text,
    'backgroundColor': disabled ? disabledBackground : background,
    '&:hover': {
      borderColor: hoverBorder,
      backgroundColor: hoverBackground,
    },
    '&:active': {
      borderColor: activeBorder,
    },
    '&:hover': {
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    },
  };
});

const buttonActions = [
  // Hide if for now, will open up again when we have a use case for it for phase 2 of relationship scoring
  // {
  //   id: 'Edit',
  //   tooltipText: 'Edit Contact',
  //   pVariant: 'plainBlack',
  //   tooltipPlacement: 'bottom',
  //   icon: EditIcon,
  // },
  {
    id: 'Delete',
    tooltipText: 'Remove Contact',
    pVariant: 'plainRed',
    tooltipPlacement: 'bottom',
    icon: ClearIcon,
    iconProps: { sx: { fontSize: '20.5349021815px' } },
  },
  {
    id: 'Approve',
    tooltipText: 'Add Contact',
    pVariant: 'plainGreen',
    tooltipPlacement: 'bottom',
    icon: AddIcon,
  },
];

const PContactCardInfo = ({ contact, pVariant, disabled, onClick, recommendationId, sequenceDetails }) => {
  const theme = useTheme();

  const handleButtonClick = useCallback(
    (event, action) => {
      event.stopPropagation();
      const payload = { recommendationId, action: action.id };
      if (onClick) {
        onClick(payload);
      }
    },
    [onClick, contact, recommendationId],
  );

  const badgeElement = React.useMemo(() => {
    let badgeText;

    if (sequenceDetails.sequenceName) {
      badgeText = sequenceDetails?.sequenceName;
    }

    if (badgeText) {
      return <PBadge pVariant="black">{badgeText}</PBadge>;
    }

    return <div></div>;
  }, [sequenceDetails]);

  return (
    <StyledPCard pVariant={pVariant} disabled={disabled}>
      {/* Display contact information */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{
              '& .MuiBadge-badge': {
                color: '#4999E9',
                backgroundColor: 'white',
                border: '1px solid #4999E9',
              },
            }}
          >
            <Avatar sx={{ cursor: 'pointer' }} src={contact.avatarUrl} />
          </Badge>
          <Stack direction="column" spacing={0.5}>
            <PTypography size="body2" weight="bold">
              {contact.name}
            </PTypography>
            <PTypography size="body2" weight="regular">
              {contact.title} at {contact.company}
            </PTypography>
            <PTypography size="body2" weight="regular">
              Email: {contact.email}
            </PTypography>
            <PTypography size="body2" weight="regular">
              LinkedIn: {contact.linkedin}
            </PTypography>
          </Stack>
        </Stack>
      </Stack>

      {/* Footer with badge and action buttons */}
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={stackStyle}>
        <Box display="flex">
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            {badgeElement}
            <PTypography size="body1" weight="regular">
              Suggestion: Add contact to relationship assistant?
            </PTypography>
          </Stack>
        </Box>
        <Box sx={boxStyle}>
          {buttonActions.map((action) => (
            <PToolTip key={action.id} title={action.tooltipText} placement={action.tooltipPlacement}>
              <PButton
                pVariant={action.pVariant}
                Icon={action.icon}
                iconProps={action.iconProps}
                onClick={(event) => handleButtonClick(event, action)}
                additionalStyles={PButtonStyle}
                isIconicButton
              />
            </PToolTip>
          ))}
        </Box>
      </Stack>
    </StyledPCard>
  );
};

PContactCardInfo.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    title: PropTypes.string,
    company: PropTypes.string,
    avatarUrl: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
  pVariant: PropTypes.oneOf(['primary']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PContactCardInfo.defaultProps = {
  pVariant: 'primary',
  disabled: false,
};

export default PContactCardInfo;
