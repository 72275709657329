import React, { useState } from 'react';
import { IconButton, InputBase, Paper, useTheme, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs from 'dayjs'
import { TwitterStepIds, Milestones } from '../../constants/constant';

export default function Timing({ step, setSequenceItemTiming, setIsChecked }) {
  const theme = useTheme()
  const milestoneStep = step.milestone && step.milestone.length > 0 ? true : false

  const getOccasion = (day) => {
    return (day === 0 ? 'on' : (day > 0 ? 'after' : 'before'))
  }

  const handleIncrement = () => {
    let stepDay = parseInt(step.day) + 1
    setSequenceItemTiming({
      ...step,
      day: stepDay,
      occasion: milestoneStep ? getOccasion(stepDay) : null
    })
    setIsChecked(true)
  };

  const handleDecrement = () => {
    let stepDayTemp = parseInt(step.day);
  
    // Allow Day 0 for Acquisition and Funding, but not before
    if ((step.milestone === 'Acquisition' || step.milestone === 'Funding') && stepDayTemp <= 0) {
      return;
    }

    if ((step.milestone === 'Job Change' || step.milestone === 'Job Promotion') && stepDayTemp <= 0) {
      return;
    }

    if (step.milestone === 'New Certification' && stepDayTemp <= 0) {
      return;
    }

    // Don't allow Day 0 on non-mileston emails
    if (!step.milestone && stepDayTemp <= 1) {
      return;
    }

    if (milestoneStep || stepDayTemp > 0) {
      const stepDay = stepDayTemp - 1;
      setSequenceItemTiming({
        ...step,
        day: stepDay,
        occasion: milestoneStep ? getOccasion(stepDay) : null
      });
      setIsChecked(true);
    }
  };
  

  const handleDateChange = (dateVal) => {
    setSequenceItemTiming({
      ...step,
      customDate: dayjs(dateVal).format(),
    })
    setIsChecked(true)
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  function getTextWidth(inputText) {
    const font = '16px "Inter", sans-serif';
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const width = context.measureText(inputText).width;
    const formattedWidth = Math.ceil(width + 1) + "px";
    return formattedWidth
  }

  function isFrequencyItem(step) {
    return (step.frequency && step.frequency !== '0') || step.id === 'checkin.recurring'
  }
  function isSaleValueItem(step) {
    return (step.stepSaleValues && step.stepSaleValues !== '0') && step.milestone === Milestones.SEC144
  }
  function isFundzValueItem(step) {
    return (step.fundzValues && step.fundzValues !== '0') && step.milestone === Milestones.FUNDING
  }

  return (
    <div style={{ visibility: ((step.id === 'connect' || step.id === 'follow') ? 'hidden' : 'visible'), display: 'flex', justifyContent: "flex-end" }}>
        {(step.day !== null && step.day !== undefined && !TwitterStepIds.includes(step.id) && step.id !== 'checkin.recurring' && step.milestone !== Milestones.SEC144) && (
          <Paper
            component="form"
            elevation={3}
            sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 1, p: 0.5, border: `1px solid #E0E0E0`, borderRadius: 4 }}
          >
            <IconButton type="button" sx={{ p: '2px' }} aria-label="search">
              <RemoveIcon onClick={handleDecrement} />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black, textAlign: 'center', }}
              inputProps={{
                style: { width: `${getTextWidth(milestoneStep ? (parseInt(step.day) === 0 ? 'on' : (parseInt(step.day) > 0 ? `${step.day} days after` : `${Math.abs(parseInt(step.day))} days before`)) : `Day ${step.day}`)}` },
              }}
              value={milestoneStep ?
                      (parseInt(step.day) === 0 ?
                        'on' : (parseInt(step.day) > 0 ?
                                `${step.day} days after` : `${Math.abs(parseInt(step.day))} days before`)) : `Day ${step.day}`}
            />
            <IconButton sx={{ p: '2px' }} aria-label="menu">
              <AddIcon onClick={handleIncrement} />
            </IconButton>
          </Paper>
        )}

        {isFrequencyItem(step) && (
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 1, p: 1, border: `1px solid #E0E0E0`, borderRadius: 4 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black, textAlign: 'right' }}
              inputProps={{
                style: { width: `${getTextWidth(step.frequency)}` },
              }}
              value={step.frequency}
            />
          </Paper>
        )}
        {isSaleValueItem(step) && (
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 1, p: 1, border: `1px solid #E0E0E0`, borderRadius: 4 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black, textAlign: 'right' }}
              inputProps={{
                style: { width: `${getTextWidth(step.stepSaleValues)}` },
              }}
              value={step.stepSaleValues}
            />
          </Paper>
        )}
        {isFundzValueItem(step) && (
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 1, p: 1, ml: 1, border: `1px solid #E0E0E0`, borderRadius: 4 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black, textAlign: 'right' }}
              inputProps={{
                style: { width: `${getTextWidth(step.fundzValues)}` },
              }}
              value={step.fundzValues}
            />
          </Paper>
        )}
      {milestoneStep && step.milestone !== Milestones.SEC144 && step.milestone !== Milestones.FUNDING &&
        <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, ml: 1, mt: 1, mb: 1, p: 0.5, border: `1px solid #E0E0E0`, borderRadius: 4 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: 500, color: theme.palette.primary.black, textAlign: 'center', }}
            inputProps={{
              style: { width: `${getTextWidth(truncateText(step.milestone, 15))}` },
            }}
            value={truncateText(step.milestone, 17)} />
        </Paper>
      }
      {step.customDate &&
        <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', boxShadow: 0, mt: 1, mb: 1, p: 1, border: `1px solid #E0E0E0`, borderRadius: 4, width: '50%'}}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <MobileDatePicker
              sx={{
                '& input': {
                  border: 'none',
                  padding: '5px',
                },
                '& fieldset': {
                  border: 'none'
                }
              }}
              value={dayjs(step.customDate)}
              disablePast={true}
              closeOnSelect={true}
              minDateTime={dayjs().add(1, 'day')}
              format={"DD MMM YYYY"}
              onError={(newError) => {
                console.log("invalid time:", newError)
              }}
              onChange={(newValue) => {
                handleDateChange(dayjs(newValue).startOf('day'))
              }}
            />
          </LocalizationProvider>
        </Paper>
      }
    </div>
  );
};
