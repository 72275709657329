import React from "react";
import PropTypes from "prop-types";
import PTypography from "../PTypography";
import { replaceEmailSubjectPlaceholders, replaceEmailBodyPlaceholders } from "../../../helper/emailUtility";
import PPostCardCommonContent from "./PPostCardCommonContent";
import { Avatar, Box } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { TopicType } from '@/constants/constant';

const EmailCard = ({ post, onContactClick }) => {
  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();

      if (onContactClick) {
        onContactClick();
      }
    },
    [onContactClick],
  );

  const getEmailLabel = () => {
    if (post?.topic_type === TopicType.LINKEDIN_EMAIL) {
      return " - LinkedIn Message";
    }
    const { email } = post?.sequenceAttr?.contactDetails || {};
    if (email) {
      return ` - ${email}`;
    }
    return " - LinkedIn Message";
  };

  const headerNode = React.useMemo(() => {
    return (
      <Box onClick={handleContactClick}>
        <PTypography size="body2" weight="bold">
          { post.topic_type === 'linkedInEmail' && <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }}/> }
          {post?.sequenceAttr?.contactDetails?.name ?? post?.name}
          {getEmailLabel()}
        </PTypography>
      </Box>
    );
  }, [handleContactClick, post]);

  const avatarNode = React.useMemo(() => {
    return (
      <Box onClick={handleContactClick}>
        <Avatar
          sx={{ width: '32px', height: '32px' }}
          src={post.authorType === 'contact' ? post.responseTo?.ImageUrl : post?.ImageUrl}
        />
      </Box>
    );
  }, [handleContactClick, post]);

  return (
    <PPostCardCommonContent post={post} avatarNode={avatarNode} headerNode={headerNode}>
      <PTypography size="body2" weight="regular">
        {replaceEmailSubjectPlaceholders(post.subject, null, post.sequenceAttr?.contactDetails)}
      </PTypography>

      <div style={{ marginTop: '8px' }}>
        <PTypography size="body1" weight="regular" preformatted>
          {replaceEmailBodyPlaceholders(post.message, null, post.sequenceAttr?.contactDetails)}
        </PTypography>
      </div>
    </PPostCardCommonContent>
  );
};

EmailCard.propTypes = {
  post: PropTypes.object.isRequired,
  onContactClick: PropTypes.func,
};

export default EmailCard;
