import React from "react";
import { ReactComponent as Charge } from "@Assets/Icons/Charge.svg";
import { ReactComponent as Settings } from "@Assets/Icons/Settings.svg";
import { ReactComponent as Teams } from "@Assets/Icons/Teams.svg";
import { ReactComponent as Home } from "@Assets/Icons/Home.svg";

import { useDispatch } from "react-redux";

import { setFeatureFlags, setPage } from "@/redux/authSlice";
import { GetCookie } from "@/helper/cookieManager";
import { UserConst } from "@/constants/userConst";

import PSidebar from "@Library/PSidebar";

import BrandTopics from "@/pages/setup/simpleSetupComponents/Brands/brandTopics";
import Socials from "@/pages/setup/simpleSetupComponents/Brands/socials";
import httpClient from "@/http/httpClient";

import featureFlagService from "@/services/featureFlagService";

function Sidebar({ full }) {
  const dispatch = useDispatch();
  const [socialsModalOpen, setSocialsModalOpen] = React.useState(false);
  const [topicsModalOpen, setTopicsModalOpen] = React.useState(false);
  const [featureFlags, setFeatureFlagsLocal] = React.useState(null);
  const [isFetchingTopics, setIsFetchingTopics] = React.useState(false);

  const [menu, setMenu] = React.useState([]);
  const [topics, setTopics] = React.useState();
  const [user, setUser] = React.useState({ name: null, type: null });

  const curUser = React.useMemo(() => GetCookie("user"), []);

  React.useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const newFeatureFlags = await featureFlagService.getFeatureFlags();
        setFeatureFlagsLocal(newFeatureFlags);
      } catch (error) {
        console.error("Failed to get feature flags:", error);
      }
    };
    fetchFeatureFlags();
  }, []);

  // When the topics modal is closed, we want to fetch the topics again,
  // so we check the previous value of topicsModalOpen to see if it was open.
  React.useEffect(() => {
    const fetchFn = async () => {
      if (topicsModalOpen) {
        try {
          const topicsResponse = await fetchTopics();
          if (topicsResponse?.topics?.length > 0) {
            setTopics(topicsResponse.topics);
          }
        } catch (error) {
          console.error("Failed to set modal:", error);
        }
      }
    }

    fetchFn();
  }, [topicsModalOpen]);

  React.useEffect(() => {
    if (featureFlags) {
      dispatch(setFeatureFlags(featureFlags));
    }
  }, [featureFlags]);

  React.useEffect(() => {
    if (featureFlags) {
      const sbClientRetention = { title: "Home", icon: Home, page: "retention", active: false };
      const sbClientAcquisition = { title: "Assistants", icon: Charge, page: "acquisition", active: false };
      const sbSettings = { title: "Settings", icon: Settings, page: "settings", active: false };
      const sbTeams = { title: "Teams", icon: Teams, page: "teams", active: false };

      let sbItems = [sbClientRetention];
      let feature = featureFlags?.find((x) => x.name === "sequences");
      if (feature?.isFeatureEnabled) {
        sbItems.push(sbClientAcquisition);
      }
      if (curUser.companyId) {
        sbItems.push(sbTeams);
      }
      sbItems.push(sbSettings);
      setMenu(sbItems);
    }
  }, [featureFlags]);

  React.useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      name: curUser.name,
      type: curUser.userType === UserConst.UserTypes.TRIAL_USER ? "Free Plan" : "Premium",
    }));
  }, [curUser]);

  const setMainPage = (page) => {
    dispatch(setPage(page));
  };

  const handleMenuItemClick = (title, hasSubmenu, page) => {
    const updatedMenu = menu.map((item) => ({
      ...item,
      active: item.title === title,
    }));

    setMenu(updatedMenu);
    if (page) {
      setMainPage(page);
    }
  };

  const fetchTopics = async () => {
    try {
      const res = await httpClient.getData("getTopics");
      return res;
    } catch (error) {
      console.error("Failed to fetch topics:", error);
      return null;
    }
  };

  const setModal = async () => {
    setIsFetchingTopics(true);
    setTopicsModalOpen(true);

    try {
      const topicsResponse = await fetchTopics();
      if (topicsResponse?.topics?.length > 0) {
        setTopics(topicsResponse.topics);
      }
    } catch (error) {
      console.error("Failed to set modal:", error);
    } finally {
      setIsFetchingTopics(false);
    }
  };

  const closeSocialsModal = () => setSocialsModalOpen(false);
  const closeTopicsModal = () => setTopicsModalOpen(false);

  return (
    <>
      <PSidebar
        pVariant="primary"
        // full={full}
        full={true}
        user={user}
        menu={menu}
        postOnClick={setModal}
        menuOnClick={handleMenuItemClick}
      />
      {topicsModalOpen && (
        <BrandTopics
          open={topicsModalOpen}
          onClose={closeTopicsModal}
          source="ComposePost"
          selectedTopicsSetter={setTopics}
          initialSelectedTopics={topics}
          isLoading={isFetchingTopics}
        />
      )}
      {socialsModalOpen && (
        <Socials
          open={socialsModalOpen}
          onClose={closeSocialsModal}
          source="ComposePost"
          topics={topics}
        />
      )}
    </>
  );
}

export default Sidebar;
