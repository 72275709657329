import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  useTheme,
  TextField,
  InputBase
} from "@mui/material";
import PBadge from "../../components/library/PBadge";
import PButton from "../../components/library/PButton";
import PCheckbox from "../../components/library/PCheckbox";
import PTypography from "../../components/library/PTypography";
import { rephrasePost } from "../../helper/apiHelper";
import { ReactComponent as ArrowLeft } from "../../assets/Icons/Arrow-Left.svg";
import { ReactComponent as ArrowRight } from "../../assets/Icons/Arrow-Right.svg";
import { updateUserPost } from "../../helper/apiHelper";
import PModal from "../../components/library/PModal";
import { Providers } from "../../constants/constant";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { debounce } from "lodash";

const toneModalOptionGroups = [{
  title: "Tone",
  items: ["Controversial", "Formal", "Fun"],
},
{
  title: "Length",
  items: ["Long", "Short"],
},
{
  title: "Hashtags",
  items: ["Use hashtags" /*, "Use emojis" */],
}];

export default function PostEdit({
  posts,
  setPosts,
  characterLimit,
  onChangeIndex,
  onApprove,

  // TODO: Refactor API calls out of this component
  disableInternalApprove
}) {
  const theme = useTheme();
  const { addSnackbar } = useSnackbar();

  const [contentHeight, setContentHeight] = useState(0);
  const [post, setPost] = useState(posts && posts[0] ? posts[0] : null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [toneModalOpen, setToneModalOpen] = useState(false);
  const [selectedTone, setSelectedTone] = useState(null);
  const [selectedLength, setSelectedLength] = useState(null);
  const [selectedBoolOptions, setSelectedBoolOptions] = useState(
    [
      // Default to 'Use hashtags' on for Twitter posts
      posts && posts[0] && posts[0].source === Providers.TWITTER
        ? "Use hashtags"
        : null,
    ].filter(Boolean)
  );
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [disableUpdateButton, setDisableRephraseButton] = useState(true);

  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);

  const contentDivRef = useRef(null);
  const footerDivRef = useRef(null);

  useEffect(() => {
    setContentHeight(getMaxContentHeight());

    const handleResize = () => {
      setContentHeight(getMaxContentHeight());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setImageLoading(true);
  }, [post]);

  useEffect(() => {
    setContentHeight(getMaxContentHeight());
  }, [post, posts, contentDivRef, footerDivRef, contentHeight]);

  useEffect(() => {
    if (posts?.length > 0) {
      setPost(posts[currentPostIndex % posts.length]);

      if (onChangeIndex && typeof onChangeIndex === "function") {
        onChangeIndex(currentPostIndex % posts.length);
      }
    }
  }, [currentPostIndex, posts]);

  useEffect(() => {
    setDisableRephraseButton(
      !selectedTone && !selectedLength && !selectedBoolOptions?.length
    );
  }, [selectedTone, selectedLength, selectedBoolOptions]);

  const getGroupSelectedOptions = (groupTitle) => {
    switch (groupTitle) {
      case toneModalOptionGroups[0].title:
        return selectedTone;
      case toneModalOptionGroups[1].title:
        return selectedLength;
      case toneModalOptionGroups[2].title:
        return selectedBoolOptions;
      default:
        return null;
    }
  };

  // Function to calculate the maximum height of the content,
  // which is rendered between the footer and header.
  const getMaxContentHeight = () => {
    if (!contentDivRef?.current || !footerDivRef?.current) {
      return "100%";
    }

    const contentRect = contentDivRef.current.getBoundingClientRect();
    const footerRect = footerDivRef.current.getBoundingClientRect();

    // The Y offset (in pixels) of where the content div starts.
    // At time of writing, this offset is exactly 207.1999969482422
    const contentYOffset = contentRect.top;

    // The total height of the footer,
    // including any padding/margin between the content and footer.
    const footerHeight = footerRect.bottom - contentRect.bottom;

    // The available height for the content div
    const totalAvailableHeight =
      window.innerHeight - contentYOffset - footerHeight;

    let bottomMargin;
    if (totalAvailableHeight > 800) {
      bottomMargin = 80;
    } else if (totalAvailableHeight < 240) {
      bottomMargin = 24;
    } else {
      bottomMargin = (totalAvailableHeight / 800) * 80;
    }

    return totalAvailableHeight - bottomMargin + "px";
  };

  const toggleModalOption = (option) => {
    if (toneModalOptionGroups[0].items.includes(option)) {
      setSelectedTone((prevTone) => (prevTone === option ? null : option));
    } else if (toneModalOptionGroups[1].items.includes(option)) {
      setSelectedLength((prevLength) =>
        prevLength === option ? null : option
      );
    } else if (toneModalOptionGroups[2].items.includes(option)) {
      setSelectedBoolOptions((prevOptions) =>
        prevOptions.includes(option)
          ? prevOptions.filter((item) => item !== option)
          : [...prevOptions, option]
      );
    }
  };

  const handleRephrasePost = async () => {
    setIsUpdating(true);
    setDisableRephraseButton(true);

    const phraseProps = {
      tone: selectedTone,
      length: selectedLength,
      hashtags: selectedBoolOptions.includes("Use hashtags")
        ? "hashtagsOn"
        : "hashtagsOff",
    };

    const rephrasedPost = await rephrasePost(
      posts[currentPostIndex],
      phraseProps
    );

    const updatedPosts = posts.map((p, index) =>
      index === currentPostIndex ? { ...p, message: rephrasedPost } : p
    );

    if (setPosts && typeof setPosts === "function") {
      setPosts(updatedPosts);
    }

    setIsUpdating(false);
    setToneModalOpen(false);
    setDisableRephraseButton(false);
  };

  // For directly editing the post inside the textfield.
  // Debounced to prevent tons of state re-renders from typing.
  const handleEditPost = debounce((newMessage) => {
    const updatedPosts = posts.map((p, index) =>
      index === currentPostIndex ? { ...p, message: newMessage } : p
    );

    if (setPosts && typeof setPosts === "function") {
      setPosts(updatedPosts);
    }
  }, 2000);

  const handleApprovePost = async () => {
    if (post.message.length > characterLimit) {
      addSnackbar({
        message: `Your post is ${post.message.length - characterLimit} characters over the limit`,
        pVariant: "destructive",
        hideAction: true,
      });
      return;
    }

    onApprove && onApprove(post);

    if (disableInternalApprove) {
      return;
    }

    const result = await updateUserPost(post, post.message, {
      delegationAccessId: authSliceSelectedDelegation?.userId,
    });
    if (!result) {
      addSnackbar({
        message: "Failed to approve post",
        pVariant: "destructive",
        hideAction: true,
      });
      return;
    }

    addSnackbar({
      message: "Post approved!",
      pVariant: "success",
      hideAction: true,
    });

    return result;
  };

  const handleTopicChange = (delta) => {
    if (!delta || typeof delta !== "number") {
      throw new Error("Invalid delta - must be a non-zero number");
    }

    let newIndex;
    if (delta > 0) {
      newIndex = (currentPostIndex + delta) % posts.length;
    }
    if (delta < 0) {
      newIndex = (posts.length + currentPostIndex + delta) % posts.length;
    }

    setCurrentPostIndex(newIndex);
  };

  const stepperButtons = () => {
    const buttons = [
      { icon: ArrowLeft, onClick: () => handleTopicChange(-1) },
      { icon: ArrowRight, onClick: () => handleTopicChange(1) },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          gap: "12px",
        }}
      >
        <PTypography
          size="body1"
          weight="regular"
          color={theme.palette.primaryCL.Black100}
        >
          {currentPostIndex + 1} of {posts.length}
        </PTypography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {buttons.map((button, index) => (
            <PButton
              key={index}
              pVariant="stepper"
              onClick={button.onClick}
              isIconicButton={true}
              Icon={button.icon}
              iconProps={{
                width: "12px",
                height: "12px",
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const CheckboxList = ({ title, items, selectedValue, onChange }) => (
    <Stack
      direction="column"
      spacing={"8px"}
      sx={{ marginLeft: "4px", marginTop: "0px" }}
    >
      <PTypography
        size="body1"
        weight="bold"
        color={theme.palette.primaryCL.Black100}
      >
        {title}
      </PTypography>
      {items.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <PCheckbox
              big
              label={item}
              checked={selectedValue === item || selectedValue?.includes(item)}
              onChange={() => onChange(item)}
            />
          }
          label={item}
          sx={{
            cursor: "default",
            gap: "8px",
          }}
        />
      ))}
    </Stack>
  );

  const PostAdjustments = () => {
    return (
      <Stack
        direction="column"
        spacing="20px"
        style={{
          width: "364px",
        }}
      >
        {toneModalOptionGroups.map((group, index) => (
          <CheckboxList
            key={index}
            title={group.title}
            items={group.items}
            selectedValue={getGroupSelectedOptions(group.title)}
            onChange={toggleModalOption}
          />
        ))}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PButton onClick={() => setToneModalOpen(false)} pVariant="outlined">
            Cancel
          </PButton>

          <PButton
            onClick={handleRephrasePost}
            disabled={disableUpdateButton}
            pVariant="black"
          >
            {isUpdating ? (
              <CircularProgress style={{ color: "inherit" }} />
            ) : (
              "Update"
            )}
          </PButton>
        </div>
      </Stack>
    );
  };

  const Header = () => {
    return (
      <div style={{ width: "100%" }}>
        <PTypography size="h3" weight="bold">
          Choose or edit your post
        </PTypography>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
            marginTop: "24px",
            marginBottom: "16px",
          }}
        >
          {post?.topic && <PBadge pVariant="purple">{post?.topic}</PBadge>}
          {posts?.length > 1 && <div>{stepperButtons()}</div>}
        </Box>
      </div>
    );
  };

  const Footer = () => {
    const buttons = [
      {
        label: "Rephrase",
        variant: "outlined",
        onClick: () => setToneModalOpen(true),
      },
      { label: "Approve", variant: "primary", onClick: handleApprovePost },
    ];

    return (
      <div
        ref={footerDivRef}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          gap: "8px",
          marginTop: "12px", // there's already 4px spacing from somewhere else
        }}
      >
        {buttons.map((button, index) => (
          <PButton
            key={index}
            pVariant={button.variant}
            onClick={button.onClick}
          >
            {button.label}
          </PButton>
        ))}
      </div>
    );
  };

  const ImageBox = () => {
    return (
      <Box
        style={{
          display: "flex",
          marginTop: "20px",
          borderRadius: "8px",
          width: "250px",
          height: "200px",
          justifyContent: imageLoading ? "center" : "flex-start",
          alignItems: imageLoading ? "center" : "flex-start",
          backgroundColor: imageLoading ? "#F5F5F5" : "transparent",
          border: imageLoading ? "1px dashed #E0E0E0" : "none",
        }}
      >
        {imageLoading && <CircularProgress style={{ color: "inherit" }} />}
        <img
          onLoad={() => setImageLoading(false)}
          src={post?.articleImageUrl}
          alt="Article thumbnail"
          style={{
            display: imageLoading ? "none" : "block",
            width: "250px",
            height: "200px",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />
      </Box>
    );
  };

  const EditPostSection = () => {
    return (
      <div
        style={{
          display: "inline-block",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
          padding: "24px",
          border: "1px solid #E0E0E0",
          borderRadius: "14px",
          height: "calc(100% - 68px)",
          width: "100%",
          overflowWrap: "break-word",
          overflowY: "scroll",
        }}
      >
        {isUpdating ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "inherit" }} />
          </div>
        ) : (
          <div>
            {/* PTextfield is not a good match for this use case. It comes with a lot of styling. */}
            <InputBase
              type="text"
              multiline
              defaultValue={post?.message}
              onChange={(e) => handleEditPost(e.target.value)}
              sx={{
                width: "100%",
                height: "100%",
                padding: "0px",
                boxShadow: "none",
                border: "none",
                m: 0,
                p: 0,
              }}
            />
            {post?.articleImageUrl && <ImageBox />}
          </div>
        )}
      </div>
    );
  };

  return (
    <Grid
      container
      id="asdf1234"
      style={{
        justifyContent: "center",
        width: "100%",
      }}
    >
      {/* TODO: Refactor to use PRephraseModal */}
      {toneModalOpen && (
        <PModal open={toneModalOpen} onClose={() => setToneModalOpen(false)} floating ultraWideContent>
          {PostAdjustments()}
        </PModal>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Header />

        <div
          ref={contentDivRef}
          style={{
            width: "100%",
            minHeight: contentHeight,
            maxHeight: contentHeight,
          }}
        >
          <EditPostSection />
          <Footer />
        </div>
      </div>
    </Grid>
  );
}
