import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import PTypography from "../PTypography";
import { Providers } from "../../../constants/constant";
import PPostCardCommonContent from "./PPostCardCommonContent";
import { TopicType } from "../../../constants/constant";
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const getHeaderText = (source) => {
  if (source === Providers.TWITTER) {
    return "Tweet";
  }

  if (source === Providers.LINKED_IN) {
    return "LinkedIn Post";
  }
};

const SocialMediaPostCard = ({ post }) => {
  const imageUrl = React.useMemo(() => {
    if (post?.topic_type === TopicType.IMAGE_POST && post?.uploadDetails?.preSignedURL) {
      return post?.uploadDetails?.preSignedURL;
    }

    if (post?.articleImageUrl) {
      return post?.articleImageUrl;
    }
  }, [post]);

  const headerNode = React.useMemo(() => {
    return (
      <PTypography size="body2" weight="bold">
        { post.source === 'linkedin' && <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }}/> }
        {getHeaderText(post.source)}
      </PTypography>
    );
  }, [post.source]);

  return (
    <PPostCardCommonContent post={post} headerNode={headerNode}>
      <PTypography size="body2" weight="regular" preformatted sx={{ textOverflow: "ellipsis", overflow: "hidden"}}>
        {post.message}
      </PTypography>
      {imageUrl && (
        <Box
          component="img"
          style={{ height: 200, width: 350, marginTop: "16px", borderRadius: 3, objectFit: "cover" }}
          alt="imagepost"
          src={imageUrl}
        />
      )}
    </PPostCardCommonContent>
  );
};

SocialMediaPostCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default SocialMediaPostCard;
