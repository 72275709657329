import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme, CircularProgress, Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { useEffect } from 'react';
import PNested from '../library/PNested';
import PTextfield from '../library/PTextfield';

import { ReactComponent as AutoFollow } from '../../assets/Icons/AutoFollow.svg';
import { ReactComponent as AutoLike } from '../../assets/Icons/AutoLike.svg';
import { ReactComponent as AutoReply } from '../../assets/Icons/AutoReply.svg';
import { ReactComponent as AutoReshare } from '../../assets/Icons/AutoReshare.svg';
import { ReactComponent as SendEmail } from '../../assets/Icons/SendEmail.svg';
import { ReactComponent as SendGift } from '../../assets/Icons/SendGift.svg';
import { ReactComponent as Back } from '../../assets/Icons/Arrow-Left.svg';
import { ReactComponent as Social } from '../../assets/Icons/Social.svg';
import { ReactComponent as XLogoCircle } from '../../assets/logos/XLogoCircle.svg';

import SequenceStep from './sequenceStep';
import { TwitterStepIds, Milestones, FeatureFlags } from '../../constants/constant';
import { SetCookie, GetCookie } from '../../helper/cookieManager';
import { handleAuthentication } from '../../helper/authUtility';
import Http from '../../http/httpClient';
import { useSelector } from 'react-redux';
import PButton from '../library/PButton';
import ResponsibilityStockSalesModal from '@CommonScreens/ResponsibilityStockSalesModal';
import { ConstructionOutlined } from '@mui/icons-material';
import { UserConst } from '@/constants/userConst';

const SequenceTemplate = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    onClose,
    open,
    selectedGoalType,
    sequenceData,
    setSequenceTemplate,
    setIsEmailLoading,
    setEmailDrafted,
    setupList,
    setSequenceData,
  } = props;

  const [twitterConnected, setTwitterConnected] = React.useState(false);
  const [linkedInConnected, setLinkedInConnected] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const titleText = [
    { id: 'retain', title: 'retain and grow current clients' },
    { id: 'acquire', title: 'acquire new clients' },
    { id: 'grow', title: 'build your brand presence' },
  ];

  const [sequenceNameField, setSequenceNameField] = React.useState(sequenceData?.sequenceName ?? '');
  const handleSequenceNameChange = (e) => {
    const newSequenceName = e.target.value;
    setSequenceNameField(newSequenceName);
    setSequenceData((prev) => ({
      ...prev,
      sequenceName: newSequenceName.trim().length > 0 ? newSequenceName : 'Untitled',
    }));
  };

  const defaultGoal = { id: 'acquire', title: 'acquire new clients' };
  const [goal, setGoal] = React.useState({});
  const [steps, setSteps] = React.useState(sequenceData?.template?.steps ?? []);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const recurringEmailFeatureFlag = featureFlags?.find(
    (featureFlag) => featureFlag.name === 'enableRecurringEmailStep',
  );
  const fundingFeatureRGFlag = featureFlags?.find(
    (featureFlag) => featureFlag.name === FeatureFlags.FUNDING_RG,
  )?.isFeatureEnabled;
  const fundingFeatureBDFlag = featureFlags?.find(
    (featureFlag) => featureFlag.name === FeatureFlags.FUNDING_BD,
  )?.isFeatureEnabled;
  const isSec144JobFeatureEnabled = featureFlags?.find(
    (featureFlag) => featureFlag.name === 'sequences.sec144',
  )?.isFeatureEnabled;
  const isRecurringEmailFeatureEnabled = recurringEmailFeatureFlag && recurringEmailFeatureFlag.isFeatureEnabled;
  const isLinkedInMailEnabled = !!featureFlags?.find((featureFlag) => featureFlag.name === 'enableLinkedInEmailStep')
    ?.isFeatureEnabled;
  const [showControversialPosts, setShowControversialPosts] = React.useState(true);
  const [skipSensitive, setSkipSensitive] = React.useState(
    sequenceData && typeof sequenceData.skipSensitive !== 'undefined' ? sequenceData.skipSensitive : true,
  );

  const stepDefinitions = [
    {
      group: 'Social',
      icon: Social,
      items: [
        {
          id: 'connect',
          desc: 'Auto-request to connect on social media',
          icon: AutoFollow,
          frequency: 'Rarely',
          type: 'connect',
          isSubMenuItemEnabled: twitterConnected || linkedInConnected,
        },
        {
          id: 'follow',
          desc: 'Auto-follow them on social media',
          icon: AutoFollow,
          frequency: 'Rarely',
          type: 'follow',
          isSubMenuItemEnabled: twitterConnected || linkedInConnected,
        },
        {
          id: 'like',
          desc: 'Auto-like their posts',
          icon: AutoLike,
          frequency: 'Sometimes',
          type: 'like',
          isSubMenuItemEnabled: twitterConnected || linkedInConnected,
        },
        {
          id: 'share',
          desc: 'Auto-reshare their posts',
          icon: AutoReshare,
          frequency: 'Often',
          approval: true,
          approveRequired: true,
          approvalVerb: 'resharing',
          type: 'share',
          isSubMenuItemEnabled: twitterConnected || linkedInConnected,
        },
        {
          id: 'comment',
          desc: 'Auto-comment on their posts',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'replying',
          icon: AutoReply,
          frequency: 'Sometimes',
          type: 'comment',
          isSubMenuItemEnabled: twitterConnected || linkedInConnected,
        },
        {
          id: 'linkedInEmail',
          desc: 'Send a LinkedIn InMail',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          isSubMenuItemEnabled: isLinkedInMailEnabled,
        },
      ],
      onSubmit: (selected) => handleAddStep(selected),
    },
    {
      group: 'Email',
      icon: SendEmail,
      isEnabled: true,
      items: [
        {
          id: 'meeting',
          desc: 'Send meeting request email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          day: 7,
          type: 'email',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Send check-in email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          day: 7,
          type: 'email',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Spot large stock sales',
          ai: true,
          approvalVerb: 'sending',
          approveRequired: true,
          subjectLine: true,
          icon: SendEmail,
          subModal: 'email',
          type: 'email',
          milestone: Milestones.SEC144,
          stepSaleValues: '$5M +',
          isSubMenuItemEnabled: isSec144JobFeatureEnabled,
          saleValueGreaterThan: 5000000,
        },
        {
          id: 'checkin.recurring',
          desc: 'Send recurring check-in email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          frequency: '4 times a year',
          type: 'email',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Birthday celebration email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          day: 0,
          type: 'email',
          milestone: 'Birthday',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'New funding email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: Milestones.FUNDING,
          isSubMenuItemEnabled: fundingFeatureRGFlag || fundingFeatureBDFlag,
          fundzIndustry: '',
          fundzGeographical: '',
          fundzValues: '$5M +',
          fundzValueGreaterThan: 5000000,
        },
        {
          id: 'followup',
          desc: 'New acquisition email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: Milestones.ACQUISITION,
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Celebrate anniversary email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: 'Anniversary',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Contract renewal email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: 'Contract Renewal',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'followup',
          desc: 'Job change email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: Milestones.JOB_CHANGE,
          isSubMenuItemEnabled: isLinkedInMailEnabled,
        },
        {
          id: 'followup',
          desc: 'Job promotion email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: Milestones.JOB_PROMOTION,
          isSubMenuItemEnabled: isLinkedInMailEnabled,
        },
        {
          id: 'followup',
          desc: 'New certification email',
          ai: true,
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          subjectLine: true,
          subModal: 'email',
          icon: SendEmail,
          type: 'email',
          milestone: Milestones.NEW_CERTIFICATION,
          isSubMenuItemEnabled: isLinkedInMailEnabled,
        },
      ],
      onSubmit: (selected) => handleAddStep(selected),
    },
    {
      group: 'Gift',
      icon: SendGift,
      isEnabled: true,
      items: [
        {
          id: 'gift',
          desc: 'Send gift',
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          giftName: 'Coffee Mug',
          giftPrice: '$5.99',
          subModal: 'gift',
          icon: SendGift,
          day: 24,
          type: 'gift',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'gift',
          desc: 'Send birthday gift',
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          giftName: 'Coffee Mug',
          giftPrice: '$5.99',
          subModal: 'gift',
          icon: SendGift,
          day: 24,
          type: 'gift',
          milestone: 'Birthday',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'gift',
          desc: 'Send contract renewal gift',
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          giftName: 'Coffee Mug',
          giftPrice: '$5.99',
          subModal: 'gift',
          icon: SendGift,
          day: 24,
          type: 'gift',
          milestone: 'Contract Renewal',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'gift',
          desc: 'Funding announcement gift',
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          giftName: 'Coffee Mug',
          giftPrice: '$5.99',
          subModal: 'gift',
          icon: SendGift,
          day: 24,
          type: 'gift',
          milestone: 'Funding',
          isSubMenuItemEnabled: true,
        },
        {
          id: 'gift',
          desc: 'Acquisition announcement gift',
          approval: true,
          approveRequired: true,
          approvalVerb: 'sending',
          giftName: 'Coffee Mug',
          giftPrice: '$5.99',
          subModal: 'gift',
          icon: SendGift,
          day: 24,
          type: 'gift',
          milestone: Milestones.ACQUISITION,
          isSubMenuItemEnabled: true,
        },
      ],
      onSubmit: (selected) => handleAddStep(selected),
    },
  ];

  const flattenStepDefinitions = (stepDefinitions) => {
    return stepDefinitions.flatMap((group) =>
      group.items.map((item) => ({
        ...item,
        group: group.group,
      })),
    );
  };

  const flatStepDefinitions = flattenStepDefinitions(stepDefinitions);

  // const flatStepDefinitions = [
  //   { id: 'follow', desc: 'Auto-follow them on social media', icon: AutoFollow, frequency: 'Rarely', type: 'follow' },
  //   { id: 'like', desc: 'Auto-like their posts', icon: AutoLike, frequency: 'Sometimes', type: 'like' },
  //   { id: 'share', desc: 'Auto-reshare their posts', icon: AutoReshare, frequency: 'Often', approval: true, approvalVerb: 'resharing', type: 'share'  },
  //   { id: 'comment', desc: 'Auto-comment on their posts', ai: true, approval: true, approvalVerb: 'replying', icon: AutoReply, frequency: 'Sometimes', type: 'comment'  },
  //   { id: 'meeting', desc: 'Send meeting request email', ai: true, approval: false, approvalVerb: 'sending', subjectLine: true, subModal: 'email', icon: SendEmail, day: 7, type: 'email' },
  //   { id: 'followup', desc: 'Send check-in email', ai: true, approval: false, approvalVerb: 'sending', subjectLine: true, subModal: 'email', icon: SendEmail, day: 7, type: 'email' },
  //   { id: 'gift', desc: 'Send gift', approval: false, approvalVerb: 'sending', giftName: 'Coffee Mug', giftPrice: '$5.99', subModal: 'gift', icon: SendGift, day: 24, type: 'gift' },
  //   { id: 'checkin.recurring', desc: 'Send recurring check-in email', ai: true, approval: false, approvalVerb: 'sending', subjectLine: true, subModal: 'email', icon: SendEmail, frequency: '4 times a year', type: 'email' },

  //   // TODO: phase this out; check production to see if we have any sequence steps that are using this
  //   { id: 'email', desc: 'Send email', ai: true, approvalVerb: 'sending', subjectLine: true, subModal: 'email', icon: SendEmail, day: 7, type: 'email' }
  // ]

  // check if the gift feature flag exists and is not enabled
  const giftFeatureFlag = featureFlags?.find((featureFlag) => featureFlag.name === 'gifts');
  /**
   * So, isGiftFeatureDisabled will be true if giftFeatureFlag does not exist or if it
   * exists but its isFeatureEnabled property is false
   * 1. If the feature flags FAIL to load, giftFeatureFlag will be undefined
   *    - isGiftFeatureDisabled => true
   * 2. If the feature flags load and the gift feature flag is not enabled
   *    - isGiftFeatureDisabled => true
   */
  const isGiftFeatureDisabled = (giftFeatureFlag && !giftFeatureFlag.isFeatureEnabled) || !giftFeatureFlag;

  // if the gift featureFlag exists and is not enabled, remove the gift step from the flatStepDefinitions
  if (isGiftFeatureDisabled) {
    const giftIndex = flatStepDefinitions.findIndex((step) => step.id === 'gift');
    if (giftIndex !== -1) {
      flatStepDefinitions.splice(giftIndex, 1);
    }
  }

  if (!isLinkedInMailEnabled) {
    const index = flatStepDefinitions.findIndex((step) => step.id === 'linkedInEmail');
    if (index !== -1) {
      flatStepDefinitions.splice(index, 1);
    }
  }

  if (!isSec144JobFeatureEnabled) {
    const index = flatStepDefinitions.findIndex((step) => step.milestone === Milestones.SEC144);
    if (index !== -1) {
      flatStepDefinitions.splice(index, 1);
    }
  }

  const filteredFlatStepDefinitions = flatStepDefinitions.filter((step) => {
    if (isGiftFeatureDisabled && step.type === 'gift') {
      return false;
    }

    if (isLinkedInMailEnabled && step.id === 'linkedInEmail') {
      if (selectedGoalType === 'grow') {
        return false;
      }
    }

    if (
      (!isRecurringEmailFeatureEnabled || selectedGoalType !== 'retain') &&
      step.desc.includes('Send recurring check-in email')
    ) {
      return false;
    }

    if (
      (!isSec144JobFeatureEnabled || selectedGoalType !== 'acquire') &&
      step.desc.includes('Spot large stock sales')
    ) {
      return false;
    }

    if (
      !isLinkedInMailEnabled &&
      (step.milestone?.includes(Milestones.JOB_CHANGE) || step.milestone?.includes(Milestones.JOB_PROMOTION))
    ) {
      return false;
    }

    if (!isLinkedInMailEnabled && step.milestone?.includes(Milestones.NEW_CERTIFICATION)) {
      return false;
    }

    if (selectedGoalType === 'retain') {
      if (!fundingFeatureRGFlag && step.milestone?.includes(Milestones.FUNDING)) {
        return false;
      }
    }
    if (selectedGoalType === 'acquire') {
      if (!fundingFeatureBDFlag && step.milestone?.includes(Milestones.FUNDING)) {
        return false;
      }
      if (step.group === 'Social') {
        return true;
      } else if (step.group === 'Email') {
        return (
          step.desc === 'Send meeting request email' ||
          step.milestone === Milestones.SEC144 ||
          (fundingFeatureBDFlag && step.milestone === Milestones.FUNDING)
        );
      } else if (step.group === 'Gift') {
        return step.desc === 'Send gift';
      }
      return false;
    }

    if (selectedGoalType === 'grow') {
      return step.milestone !== Milestones.SEC144;
    }

    if (selectedGoalType === 'retain' && step.desc === 'Send meeting request email') {
      return false;
    }

    return true;
  });

  const checkTwitterConnected = () => {
    const user = GetCookie('user');
    const isTwitterConnected = user.providers.some((provider) => provider.provider === 'twitter');
    const enabled = isTwitterConnected || user.userType === UserConst.UserTypes.PRECREATED_USER;
    setTwitterConnected(enabled);
  };

  const checkLinkedInConnected = () => {
    const user = GetCookie('user');
    const isLinkedInConnected = user.providers.some((provider) => provider.provider === 'linkedin');
    setLinkedInConnected(isLinkedInConnected);
  };

  const removeGiftStepIfDisabled = (steps) => {
    if (isGiftFeatureDisabled) {
      const giftIndex = steps.findIndex((step) => step.id === 'gift');
      if (giftIndex !== -1) {
        steps.splice(giftIndex, 1);
      }
    }
    return steps;
  };
  const groupStepsForPNested = (flatSteps) => {
    const grouped = flatSteps.reduce((acc, step) => {
      const { group, ...rest } = step;
      if (!acc[group]) acc[group] = { group, items: [] };
      acc[group].items.push(rest);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const groupedStepDefinitions = groupStepsForPNested(filteredFlatStepDefinitions);

  useEffect(() => {
    checkTwitterConnected();
    checkLinkedInConnected();

    const selectedGoalItem = titleText.find((item) => item.id === selectedGoalType) || defaultGoal;
    setGoal((prev) => selectedGoalItem);

    if (steps?.length > 0) {
      // if the gift feature flag exists and is not enabled, remove the gift step from the steps
      const filteredSteps = removeGiftStepIfDisabled(steps);
      setSteps((prev) => [...filteredSteps]);
      setInitialLoading(false);
      return;
    }

    Http.getData('getDefaultTemplates', { withStepText: 1, strategy: selectedGoalItem })
      .then(function (response) {
        if (response && response.status) {
          // const goalSequenceTemplates = response.templates?.filter(temp => temp !== null && temp.type === 'goal')
          // const selectedTemplate = goalSequenceTemplates?.find(template => template.id === selectedGoalItem.id)
          const selectedTemplate = response.templates?.[0];

          const fetchedSteps = selectedTemplate?.content.map((s) => ({
            id: s.step,
            frequency: [...TwitterStepIds, 'checkin.recurring'].includes(s.step) ? s.freq : null,
            day: s.day ?? 0,
            emailTemplate: null,
            approval: flatStepDefinitions.find((def) => def.id === s.step)?.approval,
            approveRequired: flatStepDefinitions.find((def) => def.id === s.step)?.approval,
            ai: flatStepDefinitions.find((def) => def.id === s.step)?.ai,
            desc: flatStepDefinitions.find((def) => def.id === s.step && (s.type ? def.type === s.type : true))?.desc,
            milestone: s.milestone ?? null,
            occasion: s.occasion ?? null,
            type: flatStepDefinitions.find((def) => def.id === s.step && (s.type ? def.type === s.type : true))?.type,
          }));

          // check if the gift feature flag exists and is not enabled
          // if the gift featureFlag exists and is not enabled, remove the gift step from the fetchedSteps
          const filteredSteps = fetchedSteps.filter((step) => {
            // If the gift feature flag is not enabled, exclude the 'gift' step
            if (step.id === 'gift' && isGiftFeatureDisabled) {
              return false;
            }
            return true;
          });

          setSteps(filteredSteps);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []);

  React.useEffect(() => {
    let showCheckBox = steps.some((step) =>
      ['like', 'share', 'comment', 'connect', 'follow', 'linkedInEmail'].includes(step.id),
    );
    showCheckBox ? setShowControversialPosts(true) : setShowControversialPosts(false);
  }, [steps]);

  const handleTwitterAuthentication = async () => {
    await handleAuthentication('/api/auth/twitter?via=sequenceTwitterAuth');

    const bc = new BroadcastChannel('sequenceTwitterAuthChannel');
    const eventPromise = new Promise((resolve) => {
      bc.addEventListener('message', async (event) => {
        if (event.data.status === 200) {
          console.info('Twitter authentication successful');
          checkTwitterConnected();
        }
      });
    });

    await eventPromise;
  };

  const sortoutSteps = (steps) => {
    const twitterStepOrder = ['connect', 'follow', 'like', 'share', 'comment'];
    const milestoneStepOrder = [
      Milestones.BIRTHDAY,
      Milestones.ANNIVERSARY,
      Milestones.CONTRACT_RENEWAL,
      Milestones.WINTER_HOLIDAYS,
      Milestones.JOB_PROMOTION,
      Milestones.JOB_CHANGE,
      Milestones.FUNDING,
      Milestones.ACQUISITION,
      Milestones.NEW_CERTIFICATION,
    ];
    const sortingObject = {
      twitterSteps: [],
      daySteps: [],
      milestoneSteps: [],
      customDateSteps: [],
    };

    if (!steps || steps.length === 0) {
      return steps;
    }

    for (const step of steps) {
      if (twitterStepOrder.includes(step.id)) {
        sortingObject.twitterSteps = sortingObject.twitterSteps.concat(step).sort((a, b) => {
          const indexA = twitterStepOrder.indexOf(a.id);
          const indexB = twitterStepOrder.indexOf(b.id);

          return indexA - indexB;
        });
      } else if (step.milestone) {
        sortingObject.milestoneSteps = sortingObject.milestoneSteps.concat(step).sort((a, b) => {
          const indexA = milestoneStepOrder.indexOf(a.milestone);
          const indexB = milestoneStepOrder.indexOf(b.milestone);

          return indexA - indexB;
        });
      } else if (step.customDate) {
        sortingObject.customDateSteps = sortingObject.customDateSteps.concat(step).sort((a, b) => {
          const dateA = new Date(a.customDate).getTime();
          const dateB = new Date(b.customDate).getTime();
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        });
      } else {
        sortingObject.daySteps = sortingObject.daySteps.concat(step).sort((a, b) => {
          return parseInt(a.day) - parseInt(b.day);
        });
      }
    }

    return Object.values(sortingObject).flat();
  };

  const handleSequenceItemChange = (index, step, actionType = 'modify') => {
    const prevSteps = steps;
    if (actionType === 'modify') {
      prevSteps[index] = step;
    } else if (actionType === 'delete') {
      prevSteps.splice(index, 1);
    } else if (actionType === 'add') {
      prevSteps.splice(index, 0, step);
    }
    const sortedSteps = actionType === 'checked' ? sortoutSteps(prevSteps) : prevSteps;
    setSteps((prev) => [...sortedSteps]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddStep = (item) => {
    let lastStep;
    if (['email', 'gift'].includes(item.type)) {
      lastStep = steps.findLast(
        (step) =>
          ['email', 'gift'].includes(step.type) &&
          !step.milestone &&
          !step.customDate &&
          step.id !== 'checkin.recurring',
      ) || { day: 0 };
    } else {
      lastStep = steps.findLast((step) => step.type === item.type) || { day: 0 };
    }

    setSteps((prev) => {
      const isMilestone = [
        Milestones.BIRTHDAY,
        Milestones.CONTRACT_RENEWAL,
        Milestones.ANNIVERSARY,
        Milestones.JOB_CHANGE,
        Milestones.JOB_PROMOTION,
        Milestones.NEW_CERTIFICATION,
        Milestones.SEC144,
      ].includes(item.milestone);
      const dayValue = item.milestone === Milestones.FUNDING ? 0 : isMilestone ? 0 : parseInt(lastStep?.day) + 1;
      const updatedSteps = prev.concat({
        id: item.id,
        frequency: flatStepDefinitions.find((def) => def.id === item.id)?.frequency ?? null,
        day: dayValue,
        emailTemplate: null,
        desc: item.desc ?? '',
        approval: item.approval ?? false,
        approveRequired: item.approveRequired ?? false,
        approvalVerb: item.approvalVerb ?? '',
        ai: item.ai ?? false,
        type: item.type,
        milestone: item.milestone,
        saleValueGreaterThan: item.saleValueGreaterThan ?? null,
        stepSaleValues: item.stepSaleValues ?? '',
        fundzIndustry: item.milestone === Milestones.FUNDING ? item.fundzIndustry : null,
        fundzGeographical: item.milestone === Milestones.FUNDING ? item.fundzGeographical : null,
        fundzValues: item.fundzValues ?? '',
        fundzValueGreaterThan: item.fundzValueGreaterThan ?? null,
      });

      return sortoutSteps(updatedSteps);
    });
    setAnchorEl(null);
  };

  const fetchSequenceEmails = async (seqEmails) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setIsEmailLoading(true);
      Http.postData('generateSequenceEmail', { seqEmails })
        .then(function (response) {
          if (response.length > 0) {
            setSteps((prevSteps) => {
              const updatedSteps = Array.isArray(prevSteps) ? [...prevSteps] : [];

              response.forEach((item) => {
                const stepIndex = item.stepIndex;
                if (stepIndex !== -1) {
                  updatedSteps[stepIndex].emailTemplate = updatedSteps[stepIndex].emailTemplate || {};
                  updatedSteps[stepIndex].emailTemplate.emailBody = item.email;
                  updatedSteps[stepIndex].emailTemplate.subjectLine = item.subject;
                  updatedSteps[stepIndex].emailTemplate.aiPrompt = item.prompt;
                  updatedSteps[stepIndex].emailTemplate.genReqFailed = item.genReqFailed;
                  updatedSteps[stepIndex].emailTemplate.requestDraft = false;
                  updatedSteps[stepIndex].emailTemplates = item.emailTemplates;
                }
              });
              return updatedSteps;
            });
          }
          setIsEmailLoading(false);
          setIsLoading(false); // TODO: don't set it to false while others are still loading
          resolve();
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  };

  const updateSequenceTemplates = () => {
    let templateSteps = steps;
    if (!templateSteps) {
      return;
    }

    // Remove the twitter step, if its not connected with twitter
    if (!twitterConnected && !linkedInConnected) {
      templateSteps = templateSteps.filter(
        (step) => !['connect', 'follow', 'like', 'share', 'comment'].includes(step.id),
      );
    }

    const stepsAdded = templateSteps.filter((step) => {
      return !sequenceData?.template?.steps?.some(
        (oldStep) =>
          oldStep.id === step.id &&
          parseInt(oldStep.day) === parseInt(step.day) &&
          oldStep.milestone === step.milestone &&
          oldStep.occasion === step.occasion &&
          oldStep.frequency === step.frequency &&
          oldStep.saleValueGreaterThan === step.saleValueGreaterThan &&
          oldStep.stepSaleValues === step.stepSaleValues &&
          oldStep.fundzIndustry === step.fundzIndustry &&
          oldStep.fundzGeographical === step.fundzGeographical &&
          oldStep.fundzValues === step.fundzValues &&
          oldStep.fundzValueGreaterThan === step.fundzValueGreaterThan &&
          oldStep.customDate === step.customDate,
      );
    });

    const stepsRemoved = sequenceData?.template?.steps?.filter((oldStep) => {
      return !templateSteps.some(
        (step) =>
          step.id === oldStep.id &&
          parseInt(step.day) === parseInt(oldStep.day) &&
          step.milestone === oldStep.milestone &&
          step.occasion === oldStep.occasion &&
          step.frequency === oldStep.frequency &&
          step.saleValueGreaterThan === oldStep.saleValueGreaterThan &&
          step.stepSaleValues === oldStep.stepSaleValues &&
          step.fundzIndustry === oldStep.fundzIndustry &&
          step.fundzGeographical === oldStep.fundzGeographical &&
          step.fundzValues === oldStep.fundzValues &&
          step.fundzValueGreaterThan === oldStep.fundzValueGreaterThan &&
          step.customDate === oldStep.customDate,
      );
    });

    setSequenceTemplate({
      template: {
        id: goal.id,
        title: goal.id,
        desc: goal.title,
        steps: templateSteps,
        addedSteps: stepsAdded,
        removedSteps: stepsRemoved,
        skipSensitive,
      },
    });
  };

  useEffect(() => {
    if (!steps) {
      return;
    }

    if (steps.length > 0) {
      // Inject a step index so we know how where things fit
      steps.forEach((step, index) => (step.stepIndex = index));

      // Extract the email types that have not had an email generation request
      // The step.id check is for legacy support; we'll remove it in the future once it is no longer impacting
      let emailSteps = steps.filter(
        (step) => step.type === 'email' || ['meeting', 'followup', 'email', 'gift', 'linkedInEmail'].includes(step.id),
      );
      if (emailSteps.length > 0) {
        setEmailDrafted((prev) =>
          emailSteps.some((step) =>
            step.id === 'gift' ? !step.emailTemplate?.emailBody : step.emailTemplate === null,
          ),
        );
      } else {
        setEmailDrafted(false);
      }

      let removeRecurringStep =
        !isRecurringEmailFeatureEnabled || (goal && Object.entries(goal).length > 0 && goal.id !== 'retain');
      let removeJobPromoAndJobChangeSteps = !isLinkedInMailEnabled;

      if (removeRecurringStep || removeJobPromoAndJobChangeSteps) {
        let newSteps = steps;
        if (removeRecurringStep) newSteps = newSteps.filter((step) => step.id !== 'checkin.recurring');
        if (removeJobPromoAndJobChangeSteps) {
          newSteps = newSteps.filter(
            (step) => ![Milestones.JOB_CHANGE, Milestones.JOB_PROMOTION].includes(step.milestone),
          );
        }
        setSteps(newSteps);
      }

      updateSequenceTemplates();
    }
  }, [steps]);

  // const handleClose = (item) => {
  //   setAnchorEl(null)
  // }

  React.useImperativeHandle(ref, () => ({
    generateSequenceTemplateEmail: async () => {
      return new Promise((resolve, reject) => {
        let tempSteps = steps;
        if (tempSteps && tempSteps.length > 0) {
          try {
            tempSteps.forEach((step, index) => (step.stepIndex = index));
            // The id check is for legacy support; we'll remove it in the future once it is no longer impacting
            let emailSteps = tempSteps.filter(
              (step) =>
                step.type === 'email' || ['meeting', 'followup', 'email', 'gift', 'linkedInEmail'].includes(step.id),
            );
            if (!emailSteps.some((step) => step.emailTemplate === null || !step.emailTemplate?.reviewed)) {
              setEmailDrafted(false);
              updateSequenceTemplates();
              resolve('Success');
            } else {
              emailSteps.forEach((step) => {
                if (step.id === 'checkin.recurring' && step.emailTemplates?.length > 1) {
                  // Handle if anything required
                } else {
                  if (step.emailTemplate) {
                    step.emailTemplate.requestDraft = true;
                  } else {
                    step.emailTemplate = { requestDraft: true };
                  }
                }
              });
              fetchSequenceEmails(emailSteps)
                .then((result) => {
                  setEmailDrafted(false);
                  updateSequenceTemplates();
                  resolve('Success');
                })
                .catch((error) => {
                  reject(error);
                });
            }
          } catch (error) {
            reject(error);
          }
        }
      });
    },
  }));

  return (
    <Grid container justifyContent="space-between">
      {initialLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '80vh',
            marginTop: '40vh',
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.black,
                pt: 1,
                fontSize: {
                  xs: '28px',
                  md: '32px',
                },
                lineHeight: 'normal',
              }}
            >
              Here's how you'll {titleText.find((item) => item.id === goal.id)?.title || ''}.
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 5 }}>
              Set up a sequence of steps to automate everything.
            </Typography>

            {sequenceData.sequenceName && (
              <Container
              disableGutters
                sx={{
                  py: 3,
                  my: 1,
                  borderRadius: 3,
                  width: '110%',
                }}
              >
                <Grid item xs={12} md={12}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pb: 1 }}>
                    Assistant name
                  </Typography>
                  <PTextfield
                    type="text"
                    value={sequenceNameField}
                    onChange={handleSequenceNameChange}
                    paperStyle={{ mx: 0 }}
                  />
                </Grid>
              </Container>
            )}

            {!twitterConnected && !linkedInConnected && (
              <Container
                sx={{
                  backgroundColor: theme.palette.primary.lightBlue,
                  py: 3,
                  my: 1,
                  borderRadius: 3,
                  width: '110%',
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item xs={1} md={2} lg={1}>
                    <XLogoCircle />
                  </Grid>

                  <Grid item xs={10} md={7} lg={9}>
                    <Grid container direction="column">
                      <Typography sx={{ fontWeight: 700, fontSize: '16px', color: theme.palette.primary.black }}>
                        Get faster results
                      </Typography>
                      <Typography sx={{ fontWeight: 500, fontSize: '14px', color: theme.palette.primary.black }}>
                        Unlock automated actions like auto-following, auto-liking, auto-resharing, and auto-commenting
                        on posts.
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={3} lg={2}>
                    <Button
                      sx={{
                        'backgroundColor': theme.palette.primary.blue,
                        'color': theme.palette.primary.white,
                        'textTransform': 'none',
                        'py': 1,
                        'px': 2,
                        'whiteSpace': 'nowrap',
                        'overflow': 'hidden',
                        'textOverflow': 'ellipsis',
                        'borderRadius': 2,
                        'boxShadow': '0px 4px 8px 0px #2C64E31F',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                        },
                        [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                          width: '100%',
                        },
                      }}
                      onClick={handleTwitterAuthentication}
                    >
                      Connect
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            )}

            {steps?.map((step, index) => {
              if (!twitterConnected && !linkedInConnected && TwitterStepIds.includes(step.id)) {
                return null;
              }

              return (
                <SequenceStep
                  key={index}
                  index={index}
                  step={step}
                  stepDetail={flatStepDefinitions.find((def) => def.id === step.id)}
                  setSequenceItem={handleSequenceItemChange}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  steps={steps}
                  setupList={setupList}
                  sequenceData={sequenceData}
                />
              );
            })}

            <Box alignItems="center" justifyContent="space-between" width="110%" sx={{ py: 2 }}>
              <Button
                onClick={(e) => {
                  handleClick(e);
                }}
                disabled={isLoading}
                sx={{
                  'backgroundColor': theme.palette.primary.lightBlue,
                  'color': theme.palette.primary.blue,
                  'textTransform': 'none',
                  'py': 1,
                  'px': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Add a step
              </Button>

              <FormGroup>
                <FormControlLabel
                  disabled={!showControversialPosts}
                  control={
                    <Checkbox
                      checked={skipSensitive}
                      onChange={() => {
                        setSkipSensitive(!skipSensitive);
                      }}
                      sx={{
                        'color': theme.palette.primary.blue,
                        '&.Mui-checked': {
                          color: theme.palette.primary.blue,
                          backgroundColor: 'white',
                        },
                        '&.Mui-disabled': {
                          color: theme.palette.primary.lightGrey,
                          backgroundColor: 'white',
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        },
                      }}
                    />
                  }
                  label="Skip all controversial posts"
                  sx={{
                    '& .MuiTypography-root': {
                      color: theme.palette.primary.black,
                      fontSize: '12px',
                      fontWeight: 500,
                    },
                  }}
                />
              </FormGroup>

              <PNested
                data={groupedStepDefinitions}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleAddStep={handleAddStep}
                twitterConnected={twitterConnected}
                steps={steps}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />
        </>
      )}
    </Grid>
  );
});

export default SequenceTemplate;
