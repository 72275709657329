import React from "react";
import PropTypes from "prop-types";
import { Avatar, Stack } from "@mui/material";
import PTypography from "../PTypography";
import { postScheduledTiming } from "../../../helper/utilities";
import { Box } from "@mui/system";

const PPostCardCommonContent = ({ children, avatarNode = null, headerNode = null, post }) => {
  return (
    <Stack direction="row" spacing="12px" maxWidth="100%">
      {avatarNode}
      <Stack
        direction="column"
        spacing="4px"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          direction="row"
          spacing="12px"
          justifyContent="space-between"
          width="100%"
        >
          {headerNode}
          {post?.scheduledDate && (
            <PTypography size="footnote" weight="regular">
              {postScheduledTiming(post, true)}
            </PTypography>
          )}
        </Stack>
        <Box sx={{ maxWidth: "calc(100% - 43px)" }}>
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};

PPostCardCommonContent.propTypes = {
  avatarNode: PropTypes.node,
  headerNode: PropTypes.node,
  post: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default PPostCardCommonContent;
