import { ReactComponent as Close } from '@Assets/Icons/Delete.svg';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Box, Stack, useTheme, Divider, Paper, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  createAdminInvite,
  createAdminWithoutInvite,
  createMemberInvite,
  createMemberWithoutInvite,
  createDelegatorInvite,
  createDelegatorWithoutInvite,
  uploadCsv,
} from '@/helper/apiHelper';
import {
  validateEmailAddress,
  getSelectedAssistantsString,
  getSelectedUsersDisplayString,
  getUserDisplayString,
} from '@/helper/utilities';
import { debounce } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { useAvailableDelegates } from '@Hooks/CompanyHooks';
import { PModal, PInput, PBadge, PRadio, PButton, PTypography, PToolTip, PSnackbar, PDropdownVDeux } from '@Library';

const StyledStack = ({ theme, children }) => {
  return (
    <Stack gap="16px" border={`1px solid ${theme.palette.primaryCL.Black70}`} borderRadius="4px" padding="16px">
      {children}
    </Stack>
  );
};

const OptionalBadge = () => {
  return (
    <Box display="flex" justifyContent="flex-start">
      <PBadge pVariant="blue">Optional</PBadge>
    </Box>
  );
};


export default function InviteMember(props) {
  const { members, onClose, open, fetchCompanyDetails } = props;
  const currentUser = useSelector((state) => state.auth?.currentUser);
  const availableDelegates = useAvailableDelegates();

  const theme = useTheme();
  const [currentStep, setCurrentStep] = useState(1);
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedAssistants, setSelectedAssistants] = useState([]);
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState('Member');
  const [selectedDelegates, setSelectedDelegates] = useState([]);
  const [selectedActingOnBehalfOf, setSelectedActingOnBehalfOf] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [uploadedCSV, setUploadedCSV] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const sequences = useSelector((state) => state.auth.sequences);
  const uploadCsvErrorMessage = `We were unable to parse your CSV file. Please verify it's format and contents.`;
  const showMemberOptionalBadge = useMemo(() => {
    return selectedPermissionLevel === 'Member' && !uploadedCSV && (!selectedAssistants || !selectedAssistants.length);
  }, [selectedPermissionLevel, uploadedCSV, selectedAssistants]);

  const validateEmail = useCallback((email) => {
    debounce(() => {
      if (email === '' || validateEmailAddress(email)) {
        setIsEmailValid(true);
        setEmailErrorMessage('');
      } else {
        setIsEmailValid(false);
      }
    }, 500)();
  }, []);

  const handleEmailChange = useCallback((e) => {
    const email = e.target.value;
    validateEmail(email);
    setInviteeEmail(email);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAssistantDropdownItemClick = useCallback(
    (assistantName) => {
      console.log('Assistant dropdown item clicked!', assistantName);

      // Temporary Disable Multi Select
      // if (selectedAssistants.includes(assistantName)) {
      //   // remove the assistant if it is already in the list;
      //   setSelectedAssistants(selectedAssistants.filter((assistant) => assistant !== assistantName));
      // } else {
      //   // add it if it is not in the list
      //   setSelectedAssistants([...selectedAssistants, assistantName]);
      // }
      
      // Enable Single Select
      if (selectedAssistants.includes(assistantName)) {
        setSelectedAssistants([]);
      } else {
        setSelectedAssistants([assistantName]);
      }
    },
    [selectedAssistants],
  );

  const handleDelegatesDropdownItemClick = useCallback(
    (delegateId) => {
      if (selectedDelegates.includes(delegateId)) {
        setSelectedDelegates(selectedDelegates.filter((id) => id !== delegateId));
      } else {
        setSelectedDelegates([...selectedDelegates, delegateId]);
      }
    },
    [selectedDelegates],
  );

  const handleActingOnBehalfOfDropdownItemClick = useCallback(
    (memberId) => {
      if (selectedActingOnBehalfOf.includes(memberId)) {
        setSelectedActingOnBehalfOf(selectedActingOnBehalfOf.filter((id) => id !== memberId));
      } else {
        setSelectedActingOnBehalfOf([...selectedActingOnBehalfOf, memberId]);
      }
    },
    [selectedActingOnBehalfOf],
  );

  const availablePermissionLevels = useMemo(() => ['Admin', 'Member', 'Delegate'], []);
  const inviteeData = useMemo(() => {
    const seqId = Object.values(sequences).find((seq) => seq.name === selectedAssistants[0])?._id;
    return {
      invitee: inviteeEmail,
      permission: selectedPermissionLevel,
      firstName: firstName,
      lastName: lastName,
      sequenceContactListPair: [{ sequenceId: seqId, csvfileName: uploadedCSV }],
      delegateOf: selectedDelegates,
      delegateBehalfOf: selectedActingOnBehalfOf,
    };
  }, [
    firstName,
    inviteeEmail,
    lastName,
    selectedActingOnBehalfOf,
    selectedAssistants,
    selectedDelegates,
    selectedPermissionLevel,
    sequences,
    uploadedCSV,
  ]);

  const fileInputRef = useRef(null);

  const handlePickCSVClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleUploadCSV = async () => {
    try {
      const response = await uploadCsv(csvFile);
      if (response?.status) {
        setUploadedCSV(response.handle);
        if (response?.verification?.total > 0) {
          setUploadStatus('success');
        } else {
          setUploadStatus('error');
        }
      } else {
        setUploadStatus('error');
      }
    } catch (error) {
      setUploadStatus('error');
    }
  };

  useEffect(() => {
    if (csvFile) {
      handleUploadCSV();
    }
  }, [csvFile]);

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setCsvFile(file);
    } else {
      setCsvFile(null);
      setUploadStatus('idle');
    }
  }, []);

  // like handleSend, but does not send an email
  const handleSkip = useCallback(async () => {
    setIsLoading(true);
    setLoadingButton('skip');
    try {
      let result;

      if (selectedPermissionLevel === 'Member') {
        result = await createMemberWithoutInvite(inviteeData);
      } else if (selectedPermissionLevel === 'Delegate') {
        result = await createDelegatorWithoutInvite(inviteeData);
      } else if (selectedPermissionLevel === 'Admin') {
        result = await createAdminWithoutInvite(inviteeData);
      }

      // TODO: error handling with result, Ie: csv upload fail? sequence clone fail? usercreation fail
      result?.invite?.responseStatus && (await fetchCompanyDetails());
      handleClose();
    } catch (error) {
      console.error('Error creating invite:', error);
    } finally {
      setIsLoading(false);
      setLoadingButton(null);
    }
  }, [fetchCompanyDetails, handleClose, inviteeData, selectedPermissionLevel]);

  const handleSend = useCallback(async () => {
    setIsLoading(true);
    setLoadingButton('send');
    try {
      let result;
      if (selectedPermissionLevel === 'Member') {
        result = await createMemberInvite(inviteeData);
      } else if (selectedPermissionLevel === 'Delegate') {
        result = await createDelegatorInvite(inviteeData);
      } else if (selectedPermissionLevel === 'Admin') {
        result = await createAdminInvite(inviteeData);
      }

      // TODO: error handling with result
      result?.invite?.responseStatus && (await fetchCompanyDetails());
      handleClose();
    } catch (error) {
      console.error('Error creating invite:', error);
    } finally {
      setIsLoading(false);
      setLoadingButton(null);
    }
  }, [fetchCompanyDetails, handleClose, inviteeData, selectedPermissionLevel]);

  const handleSelectAllAssistantsToggle = useCallback(() => {
    const allAssistants = Object.values(sequences).map((sequence) => sequence.name);
    // setSelectAllAssistants(!selectAllAssistants);
    // setSelectedAssistants(selectAllAssistants ? [] : allAssistants);
    setSelectedAssistants(allAssistants);
  }, [sequences]);

  const handleNext = useCallback(() => {
    if (uploadStatus === 'error') {
      setEmailErrorMessage(uploadCsvErrorMessage);
      return;
    }

    if (!isEmailValid) {
      setEmailErrorMessage('Invalid email address');
      return;
    }

    if (selectedPermissionLevel === 'Member') {
      if (!uploadedCSV && (!selectedAssistants || !selectedAssistants.length)) {
        // Both uploadedCSV and selectedAssistants are empty, allow proceeding
      } else {
        // One of uploadedCSV or selectedAssistants is filled, check the other
        if (!uploadedCSV || uploadedCSV.trim() === '') {
          setEmailErrorMessage('CSV required');
          return;
        }
        if (!selectedAssistants || !selectedAssistants.length) {
          setEmailErrorMessage('Assistant required');
          return;
        }
      }
    }

    // If all validations pass, proceed to the next step
    setCurrentStep((prevStep) => prevStep + 1);
  }, [isEmailValid, uploadedCSV, selectedAssistants, selectedPermissionLevel, uploadStatus]);

  const handleBack = useCallback(() => {
    setCurrentStep((prevStep) => prevStep - 1);
  }, []);

  const getActingOnBehalfOfName = useCallback(() => {
    if (selectedActingOnBehalfOf.length > 0) {
      return selectedActingOnBehalfOf[0]?.name ?? 'your business';
    }
    return 'your business';
  }, [selectedActingOnBehalfOf]);

  const getBorderColor = () => {
    switch (uploadStatus) {
      case 'success':
        return theme.palette.success.main;
      case 'error':
        return theme.palette.error.main;
      default:
        return theme.palette.primaryCL.Black70;
    }
  };

  const renderInviteStep = useCallback(
    () => (
      <Stack gap="28px">
        <Box>
          <PInput
            label="Email"
            onChange={handleEmailChange}
            placeholder="Their email address"
            paperSx={{ pt: '8px' }}
            error={!isEmailValid}
            helperText={emailErrorMessage}
            value={inviteeEmail}
          />
        </Box>
        <StyledStack theme={theme}>
          <PTypography size="body2" weight="bold">
            Select Permission Level
          </PTypography>

          {availablePermissionLevels.map((level, index) => (
            <>
              <Box
                key={level}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => setSelectedPermissionLevel(level)}
              >
                <PTypography size="body2" weight="regular">
                  {level}
                </PTypography>
                <PRadio
                  checked={selectedPermissionLevel === level}
                  onChange={() => setSelectedPermissionLevel(level)}
                />
              </Box>
              {index < availablePermissionLevels.length - 1 && <Divider />}
            </>
          ))}
        </StyledStack>
        {selectedPermissionLevel === 'Delegate' && (
          <StyledStack theme={theme}>
            <OptionalBadge />

            <PTypography size="body2" weight="bold">
              Quickly Onboard Them
            </PTypography>
            <Stack direction="row" spacing="8px">
              <PInput placeholder="First" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
              <PInput placeholder="Last" onChange={(e) => setLastName(e.target.value)} value={lastName} />
            </Stack>

            <Divider />

            <OptionalBadge />
            <PTypography size="body2" weight="bold">
              Acting on Behalf of
            </PTypography>
            <PDropdownVDeux
              pVariant="grey"
              displayAs="text"
              buttonText="Select Member accounts"
              inputValue={getSelectedUsersDisplayString(members, selectedActingOnBehalfOf)}
              menu={{
                menuList: members.map((member) => ({
                  isEnabled: true,
                  label: getUserDisplayString(member),
                  onSubmit: () => handleActingOnBehalfOfDropdownItemClick(member._id),
                  isSelected: selectedActingOnBehalfOf.includes(member._id),
                })),
              }}
            />
          </StyledStack>
        )}
        {selectedPermissionLevel === 'Member' && (
          <StyledStack theme={theme}>
            <OptionalBadge />

            <PTypography size="body2" weight="bold">
              Quickly Onboard Them
            </PTypography>
            <Stack direction="row" spacing="8px">
              <PInput placeholder="First" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
              <PInput placeholder="Last" onChange={(e) => setLastName(e.target.value)} value={lastName} />
            </Stack>

            {showMemberOptionalBadge && <OptionalBadge />}
            <PTypography size="body2" weight="bold">
              Copy and assign Assistant(s)
            </PTypography>
            <PDropdownVDeux
              pVariant="grey"
              displayAs="text"
              buttonText="Choose Postilize AI Assistants"
              inputValue={getSelectedAssistantsString(Object.values(sequences), selectedAssistants)}
              menu={{
                // selectAll: selectAllAssistants,
                // onSelectAllToggle: handleSelectAllAssistantsToggle,
                menuList:
                  Object.values(sequences)?.map((sequence) => ({
                    isEnabled: true,
                    label: sequence.name,
                    onSubmit: () => handleAssistantDropdownItemClick(sequence.name),
                    isSelected: selectedAssistants.includes(sequence.name),
                  })) ?? [],
              }}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <PTypography size="body2" weight="bold">
              Upload CSV
            </PTypography>
            <Stack style={{ cursor: 'pointer !important' }} onClick={handlePickCSVClick}>
              {/* <PInput disabled disableClearButton value={csvFile ? csvFile.name : 'Upload CSV'} /> */}
              <Paper
                component="form"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: 0,
                  px: '14px',
                  py: '16px',
                  borderRadius: '14px',
                  width: '100%',
                  border: `1px solid ${getBorderColor()}`,
                  cursor: 'pointer',
                }}
              >
                <PTypography size="body2" weight="regular">
                  {csvFile ? csvFile.name : 'Upload CSV'}
                </PTypography>
                {uploadStatus === 'success' && (
                  <PToolTip title="CSV uploaded successfully">
                    <CheckIcon sx={{ ml: 1, color: theme.palette.success.main }} />
                  </PToolTip>
                )}
                {uploadStatus === 'error' && (
                  <PToolTip title={uploadCsvErrorMessage}>
                    <WarningIcon sx={{ ml: 1, color: theme.palette.error.main }} />
                  </PToolTip>
                )}
              </Paper>
            </Stack>
            <Divider />
            <OptionalBadge />
            <PTypography size="body2" weight="bold">
              Assign delegate(s)
            </PTypography>
            <PDropdownVDeux
              pVariant="grey"
              displayAs="text"
              buttonText="Choose Delegate accounts"
              inputValue={getSelectedUsersDisplayString(availableDelegates, selectedDelegates)}
              menu={{
                menuList: availableDelegates.map((delegate) => ({
                  isEnabled: true,
                  label: getUserDisplayString(delegate),
                  tooltip: delegate.primaryEmail,
                  onSubmit: () => handleDelegatesDropdownItemClick(delegate._id),
                  isSelected: selectedDelegates.includes(delegate._id),
                })),
              }}
              multiple={true}
            />
          </StyledStack>
        )}
        <Box display="flex" justifyContent="flex-end">
          <PButton pVariant="black" disabled={!selectedPermissionLevel || !inviteeEmail} onClick={handleNext}>
            Next
          </PButton>
        </Box>
      </Stack>
    ),
    [
      availableDelegates,
      availablePermissionLevels,
      csvFile,
      emailErrorMessage,
      firstName,
      handleActingOnBehalfOfDropdownItemClick,
      handleAssistantDropdownItemClick,
      handleDelegatesDropdownItemClick,
      handleEmailChange,
      handleFileChange,
      handleNext,
      handlePickCSVClick,
      inviteeEmail,
      isEmailValid,
      lastName,
      members,
      selectedActingOnBehalfOf,
      selectedAssistants,
      selectedDelegates,
      selectedPermissionLevel,
      sequences,
      setSelectedPermissionLevel,
      showMemberOptionalBadge,
      theme,
    ],
  );

  const renderSendInviteStep = useCallback(
    () => (
      <Stack gap="16px">
        <PTypography size="body2" weight="bold">
          Your message
        </PTypography>

        {selectedPermissionLevel === 'Delegate' ? (
          <>
            <PTypography size="body2" weight="regular">
              Hi{firstName ? ` ${firstName},` : ','}
              <br />
              I'm excited to invite you to try out Postilize!
            </PTypography>
            <PTypography size="body2" weight="regular">
              <ol>
                <li>It's a personal digital assistant that is set up to help grow {getActingOnBehalfOfName()}.</li>
                <li>Download the iOS app or visit the website to approve and manage its tasks.</li>
                <li>Then connect [Member's] social media accounts.</li>
              </ol>
            </PTypography>
            <PTypography size="body2" weight="regular">
              Looking forward to your feedback!
            </PTypography>
            {currentUser?.name && (
              <PTypography size="body2" weight="regular">
                Best,
                <br />
                {currentUser?.name}
              </PTypography>
            )}
          </>
        ) : (
          <>
            <PTypography size="body2" weight="regular">
              I'm excited to invite you to try out Postilize!
            </PTypography>
            <PTypography size="body2" weight="regular">
              <ol>
                <li>Your personal digital assistant is set up to help grow your business relationships.</li>
                <li>Download the iOS app or visit the website to approve and manage its tasks.</li>
                <li>Then connect your social media accounts.</li>
              </ol>
            </PTypography>
            <PTypography size="body2" weight="regular">
              Looking forward to your feedback!
            </PTypography>
          </>
        )}

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" justifyContent="flex-start">
            <PButton pVariant="outlined" onClick={handleBack} disabled={isLoading}>
              Back
            </PButton>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="8px">
            <PButton pVariant="outlined" onClick={handleSkip} disabled={isLoading}>
              {isLoading && loadingButton === 'skip' ? <CircularProgress size={24} color="inherit" /> : 'Skip'}
            </PButton>
            <PButton pVariant="black" onClick={handleSend} disabled={isLoading}>
              {isLoading && loadingButton === 'send' ? <CircularProgress size={24} color="inherit" /> : 'Send'}
            </PButton>
          </Box>
        </Box>
      </Stack>
    ),
    [
      currentUser,
      firstName,
      getActingOnBehalfOfName,
      handleBack,
      handleSend,
      handleSkip,
      isLoading,
      loadingButton,
      selectedPermissionLevel,
    ],
  );

  const steps = useMemo(
    () => [
      {
        title: 'Invite',
        body: renderInviteStep(),
      },
      {
        title: 'Send the Invite',
        body: renderSendInviteStep(),
      },
    ],
    [renderInviteStep, renderSendInviteStep],
  );

  return (
    <>
      <PModal
        floating
        floatingDimensions={{ width: '744px', height: 'fit-content' }}
        ultraWideContent
        inlineHeaderText
        headerText={steps[currentStep - 1].title}
        pVariant="primary"
        open={open}
        onClose={handleClose}
        actionButtons={[
          <PButton pVariant="plain" onClick={handleClose}>
            <Close fill="black" />
          </PButton>,
        ]}
      >
        {steps[currentStep - 1].body}
      </PModal>
      <PSnackbar
        pVariant="destructive"
        open={emailErrorMessage !== ''}
        message={emailErrorMessage}
        onClose={() => setEmailErrorMessage('')}
      />
    </>
  );
}
