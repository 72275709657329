import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PPopoverMenu from '@Library/PPopoverMenu';
import { InputAdornment, InputBase } from '@mui/material';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
      };
      break;

    case 'secondary':
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        border: `1px solid ${theme.palette.primaryCL.Black70}`,
        borderFocus: theme.palette.primaryCL.Black200,
        borderComplete: theme.palette.primaryCL.Black70,
      };
      break;

    case 'gray':
    case 'grey':
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
        background: theme.palette.primaryCL.Black50,
        hoverText: theme.palette.primaryCL.Black200,
        hoverBackground: theme.palette.primaryCL.Black50,
      };
      break;

    default: // same as primary
      colorScheme = {
        text: theme.palette.primaryCL.White110,
        background: theme.palette.primaryCL.Blue100,
        hoverText: theme.palette.primaryCL.White110,
        hoverBackground: theme.palette.primaryCL.Blue90,
      };
      break;
  }

  return colorScheme;
};

function PDropdownVDeux({
  menu,
  buttonText,
  truncateButtonText,
  pVariant,
  closeWhenClicked,
  disabled,
  pSize,
  displayAs,
  inputValue,
  customNode, // CURSOR: Add customNode prop
  autoCloseOnSelect = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const handleMenuClick = useCallback(
    (event) => {
      console.log('PDropdownVDeux handleMenuClick event text: ', event.currentTarget);
      if (!disabled) {
        if (!anchorEl) {
          setAnchorEl(event.currentTarget);
        } else if (autoCloseOnSelect) {
          setAnchorEl(null);
        }
      }
    },
    [disabled, anchorEl],
  );

  const handleClose = useCallback(() => {
    console.log('clearing anchorEl in PDropdownVDeux');
    setAnchorEl(null);
  }, []);

  const { background, text, hoverBackground, hoverText } = getVariantStyles(theme, pVariant);

  const buttonHeight = pSize === 'small' ? '32px' : '48px';
  const fontSize = pSize === 'small' ? '14px' : '16px';

  const baseStyles = {
    color: text,
    borderRadius: '14px',
    padding: '0 16px',
    height: buttonHeight,
    width: '100%',
    minWidth: 0,
    fontFamily: 'Inter',
    fontSize: fontSize,
    lineHeight: buttonHeight,
    textTransform: 'none',
  };

  const buttonStyles = {
    ...baseStyles,
    'backgroundColor': background,
    '&:hover': {
      backgroundColor: hoverBackground || theme.palette.primaryCL.Blue40,
      color: hoverText,
    },
  };

  const inputBaseStyles = {
    ...baseStyles,
    border: `1px solid ${theme.palette.primaryCL.Black70}`,
  };

  const divStyles = {
    display: 'flex',
    alignItems: 'center',
    ...(truncateButtonText && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '10ch',
      display: 'block',
    }),
  };

  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

  const slotProps = { paper: { style: { minWidth: 259, width: 'auto' } } };

  React.useMemo(() => {
    console.log('anchorEl in PDropdownVDeux: ', anchorEl);
  }, [anchorEl]);

  const [width, setWidth] = useState(0);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      setWidth(dropdownRef.current.offsetWidth);
    }
  }, [displayAs, dropdownRef?.current?.offsetWidth]);

  return (
    <div>
      {displayAs === 'button' ? (
        <Button
          id="dropdown-button"
          ref={dropdownRef}
          onClick={anchorEl ? undefined : handleMenuClick}
          sx={buttonStyles}
          disabled={disabled}
        >
          <div style={divStyles}>{buttonText}</div>
          <ExpandMoreIcon sx={{ marginLeft: '8px', verticalAlign: 'middle', fill: text }} />
        </Button>
      ) : displayAs === 'text' ? (
        <InputBase
          readOnly
          id="dropdown-text"
          ref={dropdownRef}
          type="text"
          onClick={anchorEl ? undefined : handleMenuClick}
          placeholder={buttonText}
          value={inputValue || ''}
          style={inputBaseStyles}
          sx={{ cursor: 'pointer' }}
          endAdornment={
            <InputAdornment position="end">
              <ExpandMoreIcon />
            </InputAdornment>
          }
          componentsProps={{
            input: {
              sx: { cursor: 'pointer' },
            },
          }}
        />
      ) : displayAs === 'custom' && customNode ? ( // CURSOR: Render custom node if displayAs is 'custom'
        React.cloneElement(customNode, {
          onClick: anchorEl ? undefined : handleMenuClick,
          ref: dropdownRef,
        })
      ) : null}
      {anchorEl && (
        <PPopoverMenu
          onClose={handleClose}
          closeWhenClicked={closeWhenClicked}
          menu={{
            ...menu,
            isOpen: Boolean(anchorEl),
            onClick: handleMenuClick,
            onClose: handleClose,
            dimensions: {
              ...menu.dimensions,
              width: menu.dimensions?.width || width,
            },
          }}
          anchorEl={anchorEl}
          isOpen={Boolean(anchorEl)}
          dimensions={{ width: menu.dimensions?.width || width }}
        />
      )}
    </div>
  );
}

// CURSOR: Add customNode prop type
PDropdownVDeux.propTypes = {
  buttonText: PropTypes.string,
  closeWhenClicked: PropTypes.bool,
  disabled: PropTypes.bool,
  menu: PropTypes.shape({
    headerText: PropTypes.string,
    menuList: PropTypes.array.isRequired,
    dimensions: PropTypes.shape({
      width: PropTypes.number,
    }),
  }).isRequired,
  pVariant: PropTypes.string,
  truncateButtonText: PropTypes.bool,
  displayAs: PropTypes.oneOf(['button', 'text', 'custom']), // CURSOR: Add 'custom' to displayAs prop types
  inputValue: PropTypes.string,
  customNode: PropTypes.node, // CURSOR: Define customNode prop type
  autoCloseOnSelect: PropTypes.bool,
};

// CURSOR: Add default value for customNode prop
PDropdownVDeux.defaultProps = {
  buttonText: 'Select an option',
  closeWhenClicked: false,
  disabled: false,
  menu: {
    headerText: '',
    menuList: [],
  },
  pVariant: 'primary',
  truncateButtonText: false,
  displayAs: 'button',
  inputValue: '',
  customNode: null, // CURSOR: Set default value for customNode
  autoCloseOnSelect: false,
};

export default PDropdownVDeux;
