import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import DeletedPosts from './deletedPosts'
import UserStats from './userStats'

export default function UserContents () {
  const [selectedTab, setSelectedTab] = React.useState('DeletedPosts')

  const handleTabChange = (event, selected) => {
    setSelectedTab(selected)
  }

  let content
  switch (selectedTab) {
    case 'DeletedPosts':
      content = <DeletedPosts />
      break
    case 'UserStats':
      content = <UserStats />
      break
    default:
      content = <DeletedPosts />
      break
  }

  return (
    <>
      <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant='fullWidth'
          aria-label='user content menu'
        >
          <Tab label='Deleted Posts' value='DeletedPosts' />
          <Tab label='User Statss' value='UserStats' />
        </Tabs>
      </Box>
      <Toolbar />
      <>
        {content}
      </>
    </>
  )
}
