import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/system/Stack';
import Snackbar from '@mui/material/Snackbar';
import PButton from '@Library/PButton';
import PTypography from '@Library/PTypography';
import PModal from '../../../components/library/PModal';
import PCheckbox from '../../../components/library/PCheckbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Http from '../../../http/httpClient';
import { TopicType } from '../../../constants/constant.js';
import PBackButton from '../../../components/library/PBackButton.js';
import PToolTip from '../../../components/library/PToolTip.js';
import PTextfield from '@/components/library/PTextfield';
import CircularProgress from '@mui/material/CircularProgress';
import featureFlagService from '@/services/featureFlagService';
import { useSelector } from 'react-redux';
import PDropdown from '@Library/PDropdown';
import PMultiApproveButton from '@/components/library/PMultiApproveButton';

const supportedTranslationLanguages = [
  { value: 'en-US', label: 'English' },
  { value: 'fr-FR', label: 'French' },
  { value: 'pt-BR', label: 'Portuguese (Brazil)' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'it-IT', label: 'Italian' },
  { value: 'de-DE', label: 'German' },
];

export default function PostEdit(props) {
  const theme = useTheme();
  const {
    onClose,
    open,
    post,
    handlePublish,
    handleDelete,
    isDeleting,
    isLoading,
    handlePostApprove,
    handleEditToast,
    handlePost,
  } = props;
  const [subjectLine, setSubjectLine] = React.useState(post.subject ?? null);
  const [emailBody, setEmailBody] = React.useState(post.responseTo ? post.responseTo.message : post.message ?? null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const [selectedTone, setSelectedTone] = React.useState(null);
  const [selectedLength, setSelectedLength] = React.useState(null);
  const [includeHasTags, setIncludeHasTags] = React.useState('on');
  const [selectedLanguage, setSelectedLanguage] = React.useState('en-US');
  const [selectedLanguageLabel, setSelectedLanguageLabel] = React.useState('English');
  const [isAdjusting, setIsAdjusting] = React.useState(false);
  const [isRephrasing, setIsRephrasing] = React.useState(false);

  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const showShowLanguageTranslation = featureFlagService?.isFeatureEnabled(
    'ui.postEdit.languageTranslation',
    featureFlags,
  );

  const languageMenuList = [
    ...supportedTranslationLanguages.map((item) => ({
      name: item.label,
      hasSubMenu: false,
      isEnabled: true,
      onSubmit: () => {
        setSelectedLanguage(item.value);
        setSelectedLanguageLabel(item.label);
      },
    })),
  ];

  const handleToneChange = (event, label) => {
    setSelectedTone(selectedTone === label ? null : label);
  };

  const handleLengthChange = (event, label) => {
    setSelectedLength(selectedLength === label ? null : label);
  };

  const handleHashTagChange = (event) => {
    setIncludeHasTags(includeHasTags === event.target.value ? 'off' : 'on');
  };

  const resetToneAdjustments = () => {
    setSelectedTone(null);
    setSelectedLength(null);
    setIncludeHasTags('on');
  };

  const rephrasePost = (onlyMessage = false) => {
    if (onlyMessage) {
      setIsRephrasing(true);
    } else {
      setIsAdjusting(true);
    }
    const phraseProps =
      onlyMessage === true
        ? { tone: null, length: null, hashtags: null, language: null }
        : {
          tone: selectedTone,
          length: selectedLength,
          hashtags: post.source !== 'sequence' && includeHasTags === 'on' ? true : null,
          language: selectedLanguage,
        };
    const params = { post: post, ...phraseProps };

    Http.getData('rephrasePost', params)
      .then((res) => {
        if (typeof res.post === 'object') {
          setSubjectLine(res.post.subject);
          setEmailBody(res.post.body);
        } else {
          setEmailBody(res.post);
        }
        resetToneAdjustments();
        handleEditToast('rephrased successfully', post);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsRephrasing(false);
        setIsAdjusting(false);
      });
  };

  const handleSubjectChange = (e) => {
    setSubjectLine(e.target.value);
  };

  const handleEmailBodyChange = (e) => {
    setEmailBody(e.target.value);
  };

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  const getMessageCaption = (post) => {
    let caption = 'Your post';
    if (!post) {
      return caption;
    }

    if (post.source === 'sequence') {
      caption = 'Your message';
    } else if (post.source === 'twitter' && post.topic === 'twitter_tweet' && post.responseTo) {
      caption = 'Your reply';
    }

    return caption;
  };

  // const replyOrPost = () => {
  //   if (post.source === 'twitter' && post.topic === 'twitter_tweet' && post.responseTo) {
  //     return 'Reply';
  //   }

  //   return 'Post';
  // };

  const isEmail = () => {
    return post !== TopicType.USER_POST && post.source === 'sequence' ? true : false;
  };

  function adjustStyleChildren() {
    return (
      <div style={{ width: '150px' }}>
        <Stack sx={{ marginBottom: '24px' }}>
          <PTypography size={'body2'}>Adjust the style</PTypography>
        </Stack>

        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey }}>Tone</Typography>

        <Stack sx={{ marginLeft: '10px' }}>
          {!isEmail() && (
            <FormControlLabel
              control={
                <PCheckbox
                  label="Controversial"
                  value="Controversial"
                  checked={selectedTone === 'Controversial'}
                  onChange={(e) => {
                    handleToneChange(e, 'Controversial');
                  }}
                />
              }
              label="Controversial"
              checked={selectedTone === 'Controversial'}
              sx={{
                cursor: 'default',
                marginBottom: '5px',
              }}
            />
          )}

          <FormControlLabel
            control={
              <PCheckbox
                label="Formal"
                value="Formal"
                checked={selectedTone === 'Formal'}
                onChange={(e) => {
                  handleToneChange(e, 'Formal');
                }}
              />
            }
            label="Formal"
            checked={selectedTone === 'Formal'}
            sx={{
              cursor: 'default',
              marginBottom: '5px',
            }}
          />

          {isEmail() && (
            <FormControlLabel
              control={
                <PCheckbox
                  label="Informal"
                  value="Informal"
                  checked={selectedTone === 'Informal'}
                  onChange={(e) => {
                    handleToneChange(e, 'Informal');
                  }}
                />
              }
              label="Informal"
              checked={selectedTone === 'Informal'}
              sx={{
                cursor: 'default',
                marginBottom: '5px',
              }}
            />
          )}

          {!isEmail() && (
            <FormControlLabel
              control={
                <PCheckbox
                  label="Fun"
                  value="Fun"
                  checked={selectedTone === 'Fun'}
                  onChange={(e) => {
                    handleToneChange(e, 'Fun');
                  }}
                />
              }
              label="Fun"
              checked={selectedTone === 'Fun'}
              sx={{
                cursor: 'default',
                marginBottom: '5px',
              }}
            />
          )}
        </Stack>

        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pt: 2 }}>
          Length
        </Typography>
        <Stack sx={{ marginLeft: '10px' }}>
          <FormControlLabel
            control={
              <PCheckbox
                label="Short"
                value="Short"
                checked={selectedLength === 'Short'}
                onChange={(e) => {
                  handleLengthChange(e, 'Short');
                }}
              />
            }
            label="Short"
            sx={{
              cursor: 'default',
              marginBottom: '5px',
            }}
          />
          <FormControlLabel
            control={
              <PCheckbox
                label="Long"
                value="Long"
                checked={selectedLength === 'Long'}
                onChange={(e) => {
                  handleLengthChange(e, 'Long');
                }}
              />
            }
            label="Long"
            sx={{
              cursor: 'default',
              marginBottom: '5px',
            }}
          />
        </Stack>
        {!isEmail() && (
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pt: 2 }}>
            Hashtags
          </Typography>
        )}
        {!isEmail() && (
          <Stack sx={{ marginLeft: '10px' }}>
            <FormControlLabel
              control={
                <PCheckbox
                  label="Include Hashtags"
                  value="on"
                  checked={includeHasTags === 'on'}
                  onChange={handleHashTagChange}
                />
              }
              label="Include Hashtags"
              sx={{
                cursor: 'default',
                marginBottom: '5px',
                width: 'fit-content',
              }}
            />
          </Stack>
        )}

        {showShowLanguageTranslation && (
          <Stack sx={{ marginLeft: '10px' }}>
            <Typography
              sx={{ marginTop: '24px', fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey }}
            >
              Language
            </Typography>

            <PDropdown
              truncateText={true}
              menuList={languageMenuList}
              pVariant="primary"
              closeWhenClicked={true}
              selectedItem={selectedLanguageLabel}
            />
          </Stack>
        )}

        <Stack sx={{ marginTop: '24px' }}>
          <PToolTip title="Adjust the tone of your post" placement="bottom">
            <PButton
              pVariant={'outlined'}
              onClick={() => {
                rephrasePost();
              }}
              disabled={isAdjusting}
            >
              {' '}
              {isAdjusting ? 'Adjusting...' : 'Adjust'}{' '}
            </PButton>
          </PToolTip>
        </Stack>
      </div>
    );
  }

  function restChildren() {
    return (
      <>
        <PTypography size={'body2'}>{getMessageCaption(post)}</PTypography>
        {isEmail() && (
          <PTypography size={'body2'}>
            Subject line
            <span style={{ color: theme.palette.primary.black }}>*</span>
          </PTypography>
        ) && (
            <PTextfield
              value={subjectLine}
              onChange={handleSubjectChange}
              disabled={isLoading}
              type="text"
              onFocus={(e) => (e.target.placeholder = '')}
              sx={{
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
                fontWeight: theme.typography.fontRegular,
                width: '100%',
              }}
              paperStyle={{ ml: 0 }}
            />
          )}
        <PTextfield
          // id='emailBody'
          value={emailBody}
          disabled={isLoading}
          onChange={handleEmailBodyChange}
          type="text"
          onFocus={(e) => (e.target.placeholder = '')}
          multiline
          rows={12}
          sx={{
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            fontWeight: theme.typography.fontRegular,
            width: '100%',
          }}
          paperStyle={{ ml: 0 }}
        />
        {post && post.articleImageUrl && (
          <Box
            component="img"
            sx={{
              height: 200,
              width: 250,
              m: 2,
              borderRadius: 3,
              objectFit: 'cover',
            }}
            alt="imagepost"
            src={post.articleImageUrl}
          />
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center" width>
          <PToolTip title="Delete" placement="bottom">
            <PButton pVariant={'red'} onClick={() => handleDelete(post)}>
              Delete
            </PButton>
          </PToolTip>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ '& > *': { marginRight: '8px' } }}
          >
            {!post.scheduledDate && post.topic_type === 'userPost' && (
              <PToolTip title="Schedule" placement="bottom">
                <PButton
                  pVariant={'blue'}
                  disabled={isRephrasing}
                  Icon={AccessTimeFilledIcon}
                  isIconicButton={true}
                  additionalStyles={{ display: 'flex' }}
                  showToolTip={true}
                  tooltip={'Schedule for Later'}
                  onClick={() => {
                    post.action = 'Schedule';
                    handlePost(post);
                    onClose();
                  }}
                ></PButton>
              </PToolTip>
            )}

            <div style={{ marginRight: '5px' }} />

            {/* {!isEmail() && (
              <>
                <PButton
                  pVariant={"secondary"}
                  disabled={isRephrasing}
                  onClick={() => rephrasePost(true)}
                >
                  Try another {replyOrPost()}{" "}
                </PButton>

                <div style={{ marginRight: "5px" }} />

                {!post.scheduledDate &&
                (<PButton
                  pVariant={"secondary"}
                  onClick={() => {
                    handlePublish(post);
                    onClose();
                  }}
                >
                  Send now
                </PButton>)}

              </>
            )} */}

            <div style={{ marginRight: '5px' }} />
            {post.source === 'sequence' ? (
              <PMultiApproveButton
                onApprove={(event) => {
                  event.stopPropagation();
                  handlePostApprove(post, emailBody, subjectLine);

                }}
                onApproveAndSend={(event) => {
                  event.stopPropagation();
                  post.subAction = 'SendNow';
                  handlePostApprove(post, emailBody, subjectLine);
                }}
              // onApproveWithCustomTime={(event) => {
              //   event.stopPropagation();
              //   handleButtonClick(event, post, { ...action, subAction: 'SendLater' })
              // }}
              />

            ) : (
              <PToolTip title="Approve" placement="bottom">
                <PButton
                  disabled={isLoading}
                  onClick={
                    () => {
                      if (post.source === 'sequence' || post.approved) {
                        handlePostApprove(post, emailBody, subjectLine);
                      } else {
                        post.message = emailBody;
                        handlePublish(post);
                      }
                    }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Approve'}
                </PButton>
              </PToolTip>
            )}
          </Stack >
        </Stack >
      </>
    );
  }

  const maxAjustStyleChildrenWidth = '150px';
  return (
    <>
      <PModal
        floating
        ultraWideContent
        inlineHeaderText
        open={open}
        onClose={onClose}
        actionButtons={[
          <PToolTip title="Close" placement="bottom">
            <PBackButton onClick={onClose} />
          </PToolTip>,
        ]}
        headerText={'Edit'}
        floatingDimensions={{ width: 'max(80vw, 506px)' }}
      >
        <Stack direction={'row'} sx={{ width: '100%', gap: '24px' }}>
          <Box sx={{ width: 'fit-content', maxWidth: maxAjustStyleChildrenWidth }}>{adjustStyleChildren()}</Box>

          <Box sx={{ width: `calc(100% - ${maxAjustStyleChildrenWidth})` }}>{restChildren()}</Box>
        </Stack>
      </PModal>
      {alertMessage && (
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
