import Http from '../http/httpClient'
import dayjs from 'dayjs';
import { PostPriority } from '../constants/constant';

export function handleListCreation(listItem) {
  return new Promise((resolve, reject) => {
    const listId = listItem?.lisId

    if (listItem.provider === "rocketreach") {
      listItem.name = listItem.providers[0].AIRequest
    } else {
      listItem.name = listItem.provider
    }

    if (listId) {
      resolve(listId);
    } else {
      Http.postData('createList', { listDetails: listItem })
        .then((res) => {
          if (res.status) {
            resolve(res.listId);
          } else {
            reject('Error creating list.');
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => { });
    }
  });
}

export async function getSequenceContacts(sequenceId, delegationAccessId) {
  try {
    const payload = { sequenceId };
    const endpoint = delegationAccessId ? `getSequenceContacts/${delegationAccessId}` : 'getSequenceContacts';
    const response = await Http.getData(endpoint, payload);
    if (response && response.contacts) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching sequence contacts:', error);
    return null;
  }
}

export async function getAllUserSequenceContacts(sequenceIds) {
  try {
    // array of sequenceIds
    const payload = { sequenceIds };

    const response = await Http.getData('sequenceContacts', payload);
    console.log('response:', response);
    if (response && response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching sequence contacts:', error);
    return null;
  }
}

export function updateSequenceContacts(sequenceId, listId, addedContacts, removedContacts, sequenceData) {
  return new Promise((resolve, reject) => {
    Http.postData('updateSequenceContacts',
      {
        seqId: sequenceId,
        listId,
        addContactIds: addedContacts,
        removeContacts: removedContacts,
        sequenceData
      })
      .then(function (response) {
        if (response && response.status) {
          resolve(response.status)
        }
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
      .finally(function () {
      })
  })
}

export function addContactsToList(selectedItem, listItem) {
  return new Promise((resolve, reject) => {
    if (listItem.provider === "rocketreach") {
      listItem.name = listItem.providers[0].AIRequest
    } else {
      listItem.name = listItem.provider
    }
    Http.postData(`addContactsToList`, { listItem: selectedItem, listDetails: listItem })
      .then((res) => {
        if (res.status) {
          resolve(res)
        } else {
          reject('Failed to add the contacts to list.')
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
      .finally(() => {
      })
  })
};

export function getDefaultStrategies() {
  return new Promise((resolve, reject) => {
    Http.getData(`strategy/all`)
      .then((res) => {
        if (res.status) {
          resolve(res)
        } else {
          reject('Failed to retrieve the strategies.')
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
      .finally(() => {
      })
  })
}

export function getDefaultTemplates() {
  return new Promise((resolve, reject) => {
    Http.getData(`getDefaultTemplates`)
      .then((res) => {
        if (res.status) {
          resolve(res)
        } else {
          reject('Failed to retrieve the default templates.')
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
      .finally(() => {
      })
  })
}

export function setStrategy(goal) {
  return new Promise((resolve, reject) => {
    Http.postData(`setup/setStrategy`, { strategy: goal.id, strategyTemplate: goal._id })
      .then((res) => {
        if (res.status) {
          resolve(res)
        } else {
          reject('Failed to set the strategy.')
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
      .finally(() => {
      })
  })
}

export function getAvailableList() {
  return new Promise((resolve, reject) => {
    Http.getData(`getLists`)
      .then((res) => {
        if (res.status) {
          resolve(res)
        } else {
          reject('Failed to retrieve the already created lists.')
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
      .finally(() => {
      })
  })
}

/**
 * Get the current user's saved topics from the DB.
 *
 * @returns {Promise<Array>} - Array of topics (strings)
 */
export async function getSavedTopics() {
  try {
    const res = await Http.getData("getTopics");
    if (res && res.topics && res.topics.length > 0) {
      return res.topics;
    } else {
      // No topics found associated with the user.
      return [];
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Get a list of topic suggestions generated by the AI.
 * Posts the prompt to the backend, which then wraps our prompt in a template
 * and sends it to the AI to generate topics.
 *
 * @param {string} prompt - The prompt to generate topics from
 * @returns {Promise<Array>} - Array of topics (strings)
 */
export async function generateTopics(prompt) {
  try {
    const res = await Http.postData("generateExampleTopics", {
      prompt,
    });
    if (res && res.topics && res.topics.length > 0) {
      return res.topics;
    } else {
      console.error("no topics generated by the backend?");
      return [];
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Save the user's selected topics to the DB.
 *
 * @param {Array<String>} topics - Array of topics (strings)
 * @returns {Promise<boolean>} - True if the topics were saved successfully
 */
export async function updateTopics(topics) {
  try {
    const res = await Http.postData("topic", {
      topics: { addedTopics: [...new Set(topics)] },
    });
    if (res && res.status) {
      console.debug("Saved topics count:", topics.length);
      return true;
    } else {
      console.error("Topics failed to save");
      return false;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Generate example posts from the AI.
 *
 * @param {Array<String>} topics - Array of topics (strings)
 * @returns {Promise<Array<{post: string, isSelected: boolean}>>} - Array of posts
 */
export async function generateExamplePosts(topics) {
  try {
    const res = await Http.postData("generateExamplePosts", { topics });
    if (res && res.posts && res.posts.length > 0) {
      return res.posts;
    } else {
      console.error("no posts generated by the backend?");
      return [];
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Generate a single user post from the AI.
 * 
 * @param {Object} postDetails - The details of the post to generate
 * @returns {Promise<Object>} - The generated post
 */
export async function generateUserPost(postDetails) {
  if (!postDetails.post) {
    try {
      const response = await Http.postData("composePost", {
        text: postDetails.postUrl,
        topic: postDetails.postTopic,
        provider: postDetails.provider,
      });
      return { ...response.post };
    } catch (error) {
      console.log("Error generating post:", error);
      return null;
    }
  }
}

export async function composePostv2({ provider, topic = '' }) {
  try {
    const response = await Http.postData("composePost/v2", {
      topic,
      provider,
    });
    return response;
  } catch (error) {
    console.error("Error composing post:", error);
    return null;
  }
}

/**
 * Refresh (fetch or generate) the user's post queue.
 * 
 * @param {string} provider - The social media provider to refresh the post queue for
 * @returns {Promise<Array>} - The user's post queue
 */
export async function refreshUserPostQueue(provider) {
  try {
    const response = await Http.getData("refreshedUserPostQueues", { selectedSocial: provider });
    if (response?.contactPosts) {
      return response.contactPosts;
    }

    return null;
  } catch (error) {
    console.error("Error refreshing post queue:", error);
    return null;
  }
}

/**
 * Fetch the user's post queue.
 *
 * @returns {Promise<Array>} - The user's post queue
 */
export async function getUserPostQueue(accessId) {
  try {
    const response = accessId ? await Http.getData(`userPosts/${accessId}`) : await Http.getData('userPosts');
    return response?.contactPosts ? response.contactPosts : null;
  } catch (error) {
    console.error("Error fetching post queue:", error);
    return null;
  }
}

export async function rephrasePost(post, phraseProps) {
  try {
    const response = await Http.getData("rephrasePost", {
      post,
      ...phraseProps,
    });

    if (response && response.post) {
      return response.post;
    }

    return null;
  } catch (error) {
    console.error("Error rephrasing post:", error);
    return null;
  }
}

export async function updateUserPost(post, textToUpdate, { approved = true, delegationAccessId = null } = {}) {
  try {
    const endpoint = delegationAccessId ? `updateUserPost/${delegationAccessId}` : 'updateUserPost';

    const response = await Http.postData(endpoint, {
      post,
      approved,
      textToUpdate,
    });

    if (response && response.post) {
      return response.post;
    }

    return null;
  } catch (error) {
    console.error('Error updating user post:', error);
    return null;
  }
}

export async function publishUserPost({
  post,
  time,
  priority,
  isStandalonePost,
}) {
  const payload = {
    post,
    isStandalonePost: !!isStandalonePost,
  };

  const postTime = dayjs(time);
  if (!time || !postTime.isValid()) {
    throw new Error("Invalid time");
  }
  payload["time"] = postTime.toISOString();

  const validPriorities = Object.values(PostPriority);
  if (!validPriorities.includes(priority)) {
    throw new Error("Invalid priority");
  }
  payload["priority"] = priority;

  try {
    const res = await Http.postData("publishPost", payload);
    if (res.statusCode === 200 || !!res?.status || !!res?.status?.data?.id) {
      return res.status;
    }

    throw new Error("Error in publishing post");
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function publishScheduledUserPost({ post, time, isStandalonePost }) {
  const payload = {
    post,
    time,
    priority: PostPriority.SCHEDULED,
    isStandalonePost,
  };

  const res = await publishUserPost(payload);
  return res;
};

export async function publishImmediateUserPost({ post, isStandalonePost }) {
  const payload = {
    post,
    time: dayjs().toISOString(),
    isStandalonePost,
    priority: PostPriority.IMMEDIATE,
  };

  const res = await publishUserPost(payload);
  return res;
}

export async function getRemainingCredits() {
  try {
    const response = await Http.getData('credits');

    if (response) {
      return response;
    } else {
      return response.error;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Fetches the number of remaining invites for a specific company.
 * @param {string} companyId - The unique identifier for the company.
 * @returns {number|null} The number of remaining invites or null if an error occurs.
 */
export async function fetchRemainingInvites(companyId) {
  try {
    const response = await Http.getData(`company/${companyId}/remaining-invites`);
    if (response && response.remainingInvites !== undefined) {
      return response.remainingInvites;
    }
  } catch (error) {
    console.error('Error fetching remaining invites:', error);
    return null;
  }
}

/**
 * Fetches company data.
 * @returns {Promise<Object>} The company data or null if an error occurs.
 */
export async function getCompany() {
  try {
    const response = await Http.getData('company/get');
    if (response && response.company) {
      return response;
    } else {
      console.error("Failed to fetch company details.");
      return null;
    }
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error;
  }
}

/**
 * Removes a team member from the company.
 * @param {array} memberId - The unique identifier of the member to remove.
 * @returns {boolean} True if the member was successfully removed; false otherwise.
 */
export async function removeTeamMembers(memberId) {
  try {
    const response = await Http.postData(`company/removeUser`, { memberIds: memberId });
    if (response && response.company) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error removing team member:', error);
    return false;
  }
}

/**
 * Changes the role of a team member within the company.
 * @param {array} memberId - The unique identifier of the member whose role is to be changed.
 * @param {string} newRole - The new role to assign to the member.
 * @returns {boolean} True if the role was successfully updated; false otherwise.
 */
export async function roleChange(memberId, newRole) {
  try {
    const response = await Http.postData(`company/updateUserRole`, {
      memberIds: memberId,
      role: newRole
    });
    if (response && response.company) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error updating member role:', error);
    return false;
  }
}

/**
 * Fetches search results from a specified API endpoint.
 * This function is generic and can be used to fetch data for different types
 * of searches by providing an appropriate path and search term.
 * 
 * @param {string} path - The API endpoint path to send the request to.
 * @param {string} searchTerm - The search term or query to be sent as a parameter.
 * @returns {Promise<Array|Object>} - The fetched data from the server.
 * @throws Will throw an error if the HTTP request fails.
 */
export const getSearchResults = async (path, searchTerm) => {
  try {
    const response = await Http.getData(path, { searchTerm });
    return response || [];
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error);
    throw error;
  }
};


/**
 * Generates an AI search payload based on the provided AI request.
 * This function calls the backend service via HTTP POST to process the AI request.
 * 
 * @param {string} AIRequest The request string for the AI to process and generate payload.
 * @returns {Promise<Object>} A promise that resolves to the response from the server if successful.
 * If the generation fails, it resolves to an object with an error message.
 * @throws {Error} Throws an error if the HTTP request fails.
 */
export async function generateAISearchPayload(AIRequest) {
  try {
    const response = await Http.postData('generateAISearchPayload', { AIRequest });
    if (response && response.payload && Object.keys(response.payload).length > 0) {
      return response;
    } else {
      return { error: 'Failed to generate AI search payload.' };
    }
  } catch (error) {
    console.error('Error generating AI search payload:', error);
    throw new Error('Error generating AI search payload.');
  }
}

/**
 * Fetches SEC Form 144 data for specified contact IDs.
 * 
 * @param {Array<String>} contactIds - Array of contact IDs to fetch the SEC Form 144 data for.
 * @returns {Promise<Object>} - The fetched SEC Form 144 data or null if an error occurs.
 */
export async function getSECForm144ByContactIds(contactIds) {
  try {
    const response = await Http.postData('sec144/contacts', { contactIds: contactIds });
    if (response && response.data) {
      return response.data;
    } else {
      console.error("Failed to fetch SEC Form 144 data.");
      return null;
    }
  } catch (error) {
  console.error('Error fetching SEC Form 144 data:', error);
  return null;
  }
}

// Returns true if successful, false otherwise.
export async function setBccAndSignature({ sequenceBcc, emailSignature }) {
  try {
    const response = await Http.postDataAndReturnFullResponse(
      '/user/setBccAndSignature',
      { sequenceBcc, emailSignature },
    );
    console.log('apiHelper response: ', response);
    return response?.status === 204;
  } catch (error) {
    console.error('Error setting BCC and signature:', error);
    return false;
  }
}

export async function getSequences(accessId) {
  try {
    const endpoint = accessId ? `getSequences/${accessId}` : 'getSequences';
    const response = await Http.getData(endpoint);
    if (response && response.sequences) {
      return response.sequences;
    }
    return [];
  } catch (error) {
    console.error('Error fetching sequences:', error);
    return [];
  }
}

export async function getLists(accessId) {
  try {
    const endpoint = accessId ? `getLists/${accessId}` : 'getLists';
    const response = await Http.getData(endpoint);
    if (response && response.lists) {
      return response.lists;
    }
    return [];
  } catch (error) {
    console.error('Error fetching lists:', error);
    return [];
  }
}

export async function storeSequenceDetails(sequenceDetails) {
  try {
    const response = await Http.postData('storeSequenceDetails', sequenceDetails);
    console.log("response!: ", response);
    // return response?.status === 204;
  } catch (error) {
    console.error('Error storing sequence details:', error);
    return false;
  }
}

/**
 * Fetches Fundz records by a single contact ID.
 *
 * @param {string} contactId - The ID of the contact.
 * @returns {Promise<Object|null>} The Fundz record for the contact, or null if not found.
 *
 * @example
 * const recordObject = await getFundzRecordsByContactId('664e2b6f2917f2e0ee482369');
 */
export async function getFundzRecordsByContactId(contactId) {
  if (!contactId) {
    console.warn('No contact id provided.');
    return null;
  }
  try {
    const response = await Http.getData(`fundzRecords/contactId`, { contactId });
    if (response && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error('Error fetching fundz contact details:', error);
    return null;
  }
}

/**
 * Fetches Fundz records by multiple contact IDs and returns both data and fundingRecords.
 *
 * @param {string[]} contactIds - An array of contact IDs.
 * @returns {Promise<{data: Object[], fundingRecords: Object[]}>} An object containing two arrays: `data` with Fundz records for the contacts, and `fundingRecords` with additional funding information, or empty arrays if none are found.
 *
 * @example
 * const { data, fundingRecords } = await getFundzRecordsByContactIds(['664e2b6f2917f2e0ee482369', '664e2b6f2917f2e0ee482370']);
 */
export async function getFundzRecordsByContactIds(contactIds) {
  if (!contactIds || contactIds.length === 0) {
    console.warn('No contact ids provided.');
    return { data: [], fundingRecords: [] };
  }
  try {
    const response = await Http.getData(`fundzRecords/contactIds`, { contactIds });
    // Initialize an object to hold both data and fundingRecords
    let result = { data: [], fundingRecords: [] };
    
    if (response) {
      // Assign data if it exists in the response
      if (response.data) {
        result.data = response.data;
      }
      // Assign fundingRecords if it exists in the response
      if (response.fundingRecords) {
        result.fundingRecords = response.fundingRecords;
      }
    }
    return result;
  } catch (error) {
    console.error('Error fetching fundz records:', error);
    return { data: [], fundingRecords: [] };
  }
}

/**
 * Asynchronously fetches contact details based on a post's attributes.
 * Supports delegation by accepting an optional delegationAccessId.
 * 
 * @param {string} contactId - The ID of the contact to fetch details for.
 * @param {string} [delegationAccessId] - Optional delegation access ID.
 * @returns {Promise<Object>} The fetched contact details.
 */
export async function getContactDetails(contactId, delegationAccessId = null) {
  try {
    const payload = { contact: { id: contactId } };

    const endpoint = delegationAccessId ? `getContact/${delegationAccessId}` : 'getContact'; 
    const response = await Http.getData(endpoint, payload);

    const { contactDetails } = response;
    return contactDetails;
  } catch (error) {
    console.error('Error fetching getContact record:', error);
    return null;
  }
}

/**
 * Creates a member invite.
 * @param {Object} memberData - The invite data.
 * @param {string} memberData.invitee - The email of the invitee.
 * @param {string} memberData.permission - The permission level for the invitee
 * @param {string} memberData.firstName - The first name of the invitee.
 * @param {string} memberData.lastName - The last name of the invitee.
 * @param {Array} memberData.sequenceContactListPair - The sequence contact list pair.
 * @param {Array} memberData.delegateOf - The list of delegators.
 * @returns {Promise<Object>} The created invite.
 */
export async function createMemberInvite(memberData) {
  try {
    const response = await Http.postData('invites/user/createMember', memberData);
    if (response && response.status) {
      const inviteWithStatus = { ...response.invite, responseStatus: response.status };
      return { invite: inviteWithStatus };
    } else {
      throw new Error('Failed to create member invite');
    }
  } catch (error) {
    console.error('Error creating member invite:', error);
    throw error;
  }
}

/**
 * Creates a member without sending an invite.
 * @param {Object} memberData - The member data.
 * @param {string} memberData.invitee - The email of the invitee.
 * @param {string} memberData.permission - The permission level for the invitee
 * @param {string} memberData.firstName - The first name of the invitee.
 * @param {string} memberData.lastName - The last name of the invitee.
 * @param {Array} memberData.sequenceContactListPair - The sequence contact list pair.
 * @param {Array} memberData.delegateOf - The list of delegators.
 * @returns {Promise<Object>} The created member.
 */
export async function createMemberWithoutInvite(memberData) {
  try {
    const response = await Http.postData('invites/user/createMember', { ...memberData, toSkipInvite: true });
    if (response && response.status) {
      return response;
    } else {
      throw new Error('Failed to create member');
    }
  } catch (error) {
    console.error('Error creating member without invite:', error);
    throw error;
  }
}

/**
 * Creates a delegator invite.
 * @param {Object} delegatorData - The invite data.
 * @param {string} delegatorData.invitee - The email of the invitee.
 * @param {string} delegatorData.permission - The permission level for the invitee.
 * @param {Array} delegatorData.delegateBehalfOf - The list of access IDs for delegation.
 * @returns {Promise<Object>} The created invite.
 */
export async function createDelegatorInvite(delegatorData) {
  try {
    const response = await Http.postData('invites/user/createDelegator', delegatorData);
    if (response && response.status) {
      const inviteWithStatus = { ...response.invite, responseStatus: response.status };
      return { invite: inviteWithStatus };
    } else {
      throw new Error('Failed to create delegator invite');
    }
  } catch (error) {
    console.error('Error creating delegator invite:', error);
    throw error;
  }
}

/**
 * Fetches the list of delegations for the current user.
 * @returns {Promise<Array>} - The list of delegations for the user.
 */
export async function getUserDelegationsList() {
  try {
    const response = await Http.getData(`delegations`);
    if (response && response.status && response.delegations) {
      return response.delegations;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching user delegations:', error);
    return null;
  }
}

/**
 * Creates a delegator without sending an invite.
 * @param {Object} delegatorData - The delegator data.
 * @param {string} delegatorData.invitee - The email of the invitee.
 * @param {string} delegatorData.permission - The permission level for the invitee.
 * @param {Array} delegatorData.delegateBehalfOf - The list of access IDs for delegation.
 * @returns {Promise<Object>} The created delegator.
 */
export async function createDelegatorWithoutInvite(delegatorData) {
  try {
    const response = await Http.postData('invites/user/createDelegator', { ...delegatorData, toSkipInvite: true });
    if (response && response.status) {
      return response;
    } else {
      throw new Error('Failed to create delegator without invite');
    }
  } catch (error) {
    console.error('Error creating delegator without invite:', error);
    throw error;
  }
}

/**
 * Creates an admin invite.
 * @param {Object} inviteData - The invite data.
 * @param {Object} inviteData.inviter - The inviter's details.
 * @param {string} inviteData.invitee - The email of the invitee.
 * @param {string} inviteData.companyId - The company ID.
 * @returns {Promise<Object>} The created invite.
 */
export async function createAdminInvite(inviteData) {
  try {
    const response = await Http.postData('invites/user/createAdmin', inviteData);
    if (response && response.status) {
      const inviteWithStatus = { ...response.invite, responseStatus: response.status };
      return { invite: inviteWithStatus };
    } else {
      throw new Error('Failed to create admin invite');
    }
  } catch (error) {
    console.error('Error creating admin invite:', error);
    throw error;
  }
}

/**
 * Creates an admin without invite.
 * @param {Object} inviteData - The invite data.
 * @param {Object} inviteData.inviter - The inviter's details.
 * @param {string} inviteData.invitee - The email of the invitee.
 * @param {string} inviteData.companyId - The company ID.
 * @returns {Promise<Object>} The created invite.
 */
export async function createAdminWithoutInvite(inviteData) {
  try {
    const response = await Http.postData('invites/user/createAdmin', { ...inviteData, toSkipInvite: true });
    if (response && response.status) {
      return response.invite;
    } else {
      throw new Error('Failed to create admin invite');
    }
  } catch (error) {
    console.error('Error creating admin invite:', error);
    throw error;
  }
}

/**
 * Uploads a CSV file to the server.
 *
 * @param {File} file - The CSV file to upload.
 * @returns {Promise<Object>} - The response from the server.
 */
export async function uploadCsv(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('verify', 'true'); // Add this line to verify to view the CSV upload description

  try {
    const response = await Http.postFormData('api/uploadCsv', formData);
    return response;
  } catch (error) {
    console.error('Error uploading CSV file:', error);
    throw error;
  }
}

/**
 * Fetches the persona information for the current user.
 *
 * @returns {Promise<Object|null>} The persona information or null if an error occurs.
 */
export async function getUserPersona() {
  try {
    const response = await Http.getData('user/persona');
    if (response && response.persona) {
      return response.persona;
    } else {
      console.error('Failed to fetch persona information.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching persona information:', error);
    return null;
  }
}

/**
 * Accept or reject a contact recommendation.
 *
 * @param {string} recommendationId - The ID of the recommendation.
 * @param {string} action - The action to take, either 'accept' or 'reject'.
 * @returns {Promise<Object>} - The response from the server.
 */
export async function handleContactRecommendation(recommendationId, action) {
  try {
    const payload = {
      recommendation: {
        _id: recommendationId,
        action: action,  // 'accept' or 'reject'
      },
    };

    const response = await Http.postData('actionRecommendation', payload);

    if (response && response.status) {
      return response;
    } else {
      throw new Error('Failed to handle the recommendation action.');
    }
  } catch (error) {
    console.error(`Error handling recommendation with action ${action}:`, error);
    throw error;
  }
}

/**
 * Clones a sequence.
 * @param {string} sequenceId - The ID of the sequence to clone.
 * @param {string} targetUserId - The ID of the user to clone the sequence for.
 * @param {Object} options - Additional options for cloning, see cloneSequence in sequenceService.js
 * @returns {Promise<Object>} The cloned sequence.
 */
export async function cloneSequence(sequenceId, targetUserId, options = {}) {
  try {
    const response = await Http.postData('cloneSequence', { sequenceId, targetUserId, options });
    return response;
  } catch (error) {
    console.error('Error cloning sequence:', error);
    throw error;
  }
}

/**
 * Fetches the contacts for a given list and delegation access ID.
 * @param {string} listId - The ID of the list.
 * @param {string} accessId - The delegation access ID.
 * @returns {Promise<Object>} The response from the server.
 */
export async function getListContacts(listId, accessId = null) {
  try {
    const endpoint = accessId ? `getListContacts/${accessId}` : 'getListContacts';
    const response = await Http.getData(endpoint, { listId });
    return response;
  } catch (error) {
    console.error('Error fetching list contacts:', error);
    return null;
  }
}

export async function toggleSequencePaused(sequenceData, statusOverride = null, accessId = null) {
  try {
    const newStatus = statusOverride ? statusOverride : sequenceData.status === 'Active' ? 'Paused' : 'Active';
    const newSequenceData = { ...sequenceData, status: newStatus };
    const payload = {
      type: 'Status',
      sequenceData: newSequenceData,
      pausedType: 'Manually paused',
    };
    const endpoint = accessId ? `updateSequenceSettings/${accessId}` : 'updateSequenceSettings';
    const response = await Http.postData(endpoint, payload);
    return response;
  } catch (error) {
    console.error('Error toggling sequence paused:', error);
    return null;
  }
}
