import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Stack } from '@mui/material';
import PTypography from '@Library/PTypography';
import { useTheme } from '@mui/material/styles';
import { postScheduledTiming } from '@/helper/utilities';
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const styles = {
  container: {
    borderRadius: 'var(--8, 8px)',
    width: '100%',
    ml: '1px',
    mb: '20px',
    p: '12px',
    overflow: 'hidden',
  },
  avatar: {
    height: '32px',
    width: '32px',
  },
  messageBox: {
    mt: '12px',
    maxWidth: '100%',
  },
  messageText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

const SocialMediaReplyCard = ({ post, onContactClick }) => {
  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();

      if (onContactClick) {
        onContactClick();
      }
    },
    [onContactClick],
  );

  const headerNode = React.useMemo(() => {
    const contactName = post?.sequenceAttr?.contactDetails?.name ?? post?.name;
    const contactTitle = post?.sequenceAttr?.contactDetails?.title;
    const contactCompany = post?.sequenceAttr?.contactDetails?.company;

    return (
      <Box onClick={post.source !== 'twitter' ? (e) => handleContactClick(e) : undefined}>
        <Stack direction="column" spacing="2px" alignItems="flex-start">
        {contactName && (
          <PTypography size="body2" weight="bold">
            { post.source === 'linkedin-comment' && <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }}/> }
            {contactName}
          </PTypography>
        )}
        {contactTitle && (
          <PTypography size="footnote" weight="regular">
            {contactTitle}
          </PTypography>
        )}
        {contactCompany && (
          <PTypography size="footnote" weight="regular">
            {contactCompany}
          </PTypography>
        )}
        </Stack>
      </Box>
    );
  }, [handleContactClick, post]);

  const avatarNode = React.useMemo(() => {
    return (
      <Box onClick={post.source !== 'twitter' ? (e) => handleContactClick(e) : undefined}>
        <Avatar sx={styles.avatar} src={post.ImageUrl} />
      </Box>
    );
  }, [handleContactClick, post]);

  const theme = useTheme();

  // We don't use the common content component because this has its own layout
  return (
    <Stack direction="column" spacing="24px">
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={styles.container}
        bgcolor={theme.palette.primaryCL.Black50}
      >
        <Stack direction="row" spacing={1} alignItems="flex-start">
          {avatarNode}
          {headerNode}
        </Stack>

        <Box sx={styles.messageBox}>
          <PTypography
            size="body2"
            weight="regular"
            preformatted
            sx={styles.messageText}
          >
            {post.message}
          </PTypography>
        </Box>
      </Stack>

      <Stack direction="row" spacing="12px" width="100%">
        <Avatar
          sx={styles.avatar}
          src={post.responseTo?.ImageUrl}
        />

        <Stack direction="column" spacing="4px" justifyContent="space-between" maxWidth="calc(100% - 43px)">
          <Stack direction="row" spacing="12px" justifyContent="space-between" width="100%">
            <PTypography size="body2" weight="bold">
            { post.source === 'linkedin-comment' && <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }}/> }
              {post.responseTo?.name}
            </PTypography>
            {post?.scheduledDate && (
              <PTypography size="footnote" weight="regular">
                {postScheduledTiming(post, true)}
              </PTypography>
            )}
          </Stack>
          <PTypography
            size="body2"
            weight="regular"
            preformatted
            sx={styles.messageText}
          >
            {post.responseTo.message}
          </PTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

SocialMediaReplyCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default SocialMediaReplyCard;
