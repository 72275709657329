import React, { useState, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import PPopoverMenu from './PPopoverMenu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PTypography from '@Library/PTypography';
import PCheckbox from '@/components/library/PCheckbox';
import PRadio from '@/components/library/PRadio';
import PToolTip from '@/components/library/PToolTip';

const makeStyles = (theme) => ({
  menuItem: {
    'borderRadius': '8px',
    'fontSize': '16px',
    '&:hover': {
      backgroundColor: theme.palette.primaryCL.Blue40,
    },
  },
  checkIcon: {
    marginLeft: 'auto',
    width: '16px',
    height: '16px',
  },
  arrowIcon: {
    marginLeft: 'auto',
  },
});

// Extract the common JSX structure into a separate function to avoid repetition.
const renderListItemButton = (item, checkType, styles, augmentedSubmenu, anchorEl) => (
  <ListItemButton sx={styles.menuItem} key={item?.label}>
    <Box sx={{ gap: '16px', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>
        <PTypography size="body2" weight="regular">
          {item?.label}
        </PTypography>
      </div>
      <div>
        {checkType === 'checkbox' && <PCheckbox checked={item?.isSelected} />}
        {checkType === 'radio' && <PRadio checked={item?.isSelected} />}
        {augmentedSubmenu && <ArrowRightIcon sx={styles.arrowIcon} />}
        {augmentedSubmenu && anchorEl && <PPopoverMenu key={item?.label} menu={augmentedSubmenu} anchorEl={anchorEl} />}
      </div>
    </Box>
  </ListItemButton>
);

export default function PPopoverMenuItem({ item, checkType }) {
  const theme = useTheme();
  const styles = useMemo(() => makeStyles(theme), [theme]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSubMenuOpen = useCallback((event) => setAnchorEl(event?.currentTarget), []);
  const handleSubMenuClose = useCallback(() => setAnchorEl(null), []);

  const augmentedSubmenu = useMemo(() => {
    if (item?.submenu) {
      return {
        menuList: item?.submenu?.menuList,
        headerText: item?.submenu?.headerText,
        isOpen: !!anchorEl,
        onClose: handleSubMenuClose,
      };
    }
  }, [anchorEl, handleSubMenuClose, item?.submenu]);

  const handleItemClick = useCallback(
    (event) => {
      if (typeof item?.onSubmit === 'function') {
        item.onSubmit(event);
      }
      if (augmentedSubmenu) {
        handleSubMenuOpen(event);
      }
    },
    [item, augmentedSubmenu, handleSubMenuOpen],
  );

  const memoizedBox = useMemo(
    () => (
      <Box onClick={handleItemClick}>
        {item?.tooltip ? (
          <PToolTip title={item?.tooltip}>
            {renderListItemButton(item, checkType, styles, augmentedSubmenu, anchorEl)}
          </PToolTip>
        ) : (
          renderListItemButton(item, checkType, styles, augmentedSubmenu, anchorEl)
        )}
      </Box>
    ),
    [handleItemClick, item, checkType, styles, augmentedSubmenu, anchorEl],
  );

  return memoizedBox;
}
