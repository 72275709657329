import React from 'react';
import { Container, CssBaseline, Stack, Typography, IconButton, Button, List, ListItemButton, ListItemText, Divider } from '@mui/material';
import { useTheme, CircularProgress } from '@mui/material';
import { Box, MenuItem } from '@mui/material';


import EmptyState from './components/emptyStates/emptyState.js';
import SequenceModal from './components/addSequenceModal/sequenceModal.js';
import SequenceReview from './components/sequenceReview.js';
import PauseModal from './components/sequenceSettingsModal/pause.js';
import DeleteModal from './components/sequenceSettingsModal/delete.js';
import RenameModal from './components/sequenceSettingsModal/rename.js';
import EditModal from './components/sequenceSettingsModal/edit.js';
import { getDefaultStrategies, getDefaultTemplates, getAvailableList } from '../../helper/apiHelper'
import { GetCookie } from '../../helper/cookieManager'
import { Milestones } from '@/constants/constant';

import Http from '../../http/httpClient'

import PSectionNav from '../../components/library/PSectionNav';

const ClientAcquisition = () => {
  const curUser = React.useMemo(() => GetCookie("user"), []);
  const theme = useTheme();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalIndex, setModalIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [createdSequenceId, setCreatedSequenceId] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const [progress, setProgress] = React.useState({});
  const [sequenceContacts, setSequenceContacts] = React.useState({});
  const [sequenceContacts2, setSequenceContacts2] = React.useState({});
  const [stepTypes, setStepTypes] = React.useState([]);
  const [sequenceRefreshKey, setSequenceRefreshKey] = React.useState(0);
  const [defaultTemplates, setDefaultTemplates] = React.useState([])
  const [strategies, setStrategies] = React.useState([])
  const [availableList, setAvailableList] = React.useState([])
  const [targetAudience, setTargetAudience] = React.useState({})
  const [stepText, setStepText] = React.useState([])
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [strategyLoading, setStrategyLoading] = React.useState(true);
  const [initialListLoading, setInitialListLoading] = React.useState(true);
  const [userBio, setUserBio] = React.useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [renameOpen, setRenameOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [pauseOpen, setPauseOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [sequenceList, setSequenceList] = React.useState([]);

  const setModal = (index) => {
    setModalIndex(index);
    setModalOpen(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => setModalOpen(false);
  const closeRename = () => setRenameOpen(false);
  const closeEdit = () => setEditOpen(false);
  const closePause = () => setPauseOpen(false);
  const closeDelete = () => setDeleteOpen(false);
  //const closeSettings = () => setSettingsOpen(false);

  const handlePauseClick = () => {
    setPauseOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
    handleMenuClose();
  };

  const handleRenameClick = () => {
    setRenameOpen(true);
    handleMenuClose();
  };

  const handleEditClick = () => {
    setEditOpen(true);
    handleMenuClose();
  };


  const handleSequenceStatusChange = () => {
    setSequenceRefreshKey(prevKey => prevKey + 1);
  };

  // click handler for the sequence item.
  // when the activeItem is updated, the sequence data is fetched again via useEffect,
  // which can be found directly beneath this function.
  const handleClick = (item) => {
    item.template.steps = item?.template?.steps.map(step => {
      if (step?.milestone === Milestones.SEC144) {
        return { ...step, desc: 'Spot large stock sales' };
      }
      if (step?.milestone === Milestones.FUNDING) {
        return { ...step, desc: 'New funding email' };
      }
      if (step?.milestone === Milestones.ACQUISITION) {
        return { ...step, desc: 'New acquisition email' };
      }
      return step;
    });
    setActiveItem(item);
  };

  const expireCache = (sequenceId) => {
    setTimeout(() => {
      setSequenceContacts((prevContacts) => {
        return {
          ...prevContacts,
          [sequenceId]: { ...prevContacts[sequenceId], replaceable: true },
        };
      });
      setSequenceContacts2((prevContacts) => {
        return {
          ...prevContacts,
          [sequenceId]: { ...prevContacts[sequenceId], replaceable: true },
        };
      });
    }, 15000);
  };

  // fetch sequence contacts when the activeItem is updated.
  React.useEffect(() => {
    if (!activeItem) {
      return;
    }
    if (
      sequenceContacts[activeItem._id] &&
      sequenceContacts2[activeItem._id] &&
      !sequenceContacts[activeItem._id].replaceable &&
      !sequenceContacts2[activeItem._id].replaceable
    ) {
      return;
    }

    setProgress((prev) => ({ ...prev, getContacts: true }));
    Http.getData('getSequenceContacts', { sequenceId: activeItem._id })
      .then(function (response) {
        if (response && response.status) {
          setSequenceContacts((prevContacts) => ({
            ...prevContacts,
            [activeItem._id]: { contacts: response.contacts, replaceable: false },
          }));
          setSequenceContacts2((prevContacts) => ({
            ...prevContacts,
            [activeItem._id]: { contacts: response.contacts2, replaceable: false },
          }));
          expireCache(activeItem._id);
          setStepTypes(response.steps);
          setProgress((prev) => ({ ...prev, getContacts: false }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [activeItem]);

  const fetchBio = async () => {
    const response = await Http.getData('setup/getBio')
    setUserBio({ name: curUser.name, company: response?.bio?.companyName })
  }

  const fetchSequenceData = (sequenceId) => {
    setProgress((prev) => ({ ...prev, getSequence: true }));
    Http.getData('getSequences', { sequenceId })
      .then(function (response) {
        if (response && response.status) {
          setSequenceList(response.sequences)
          const selectedItem = sequenceId ? response.sequences.find(item => item._id.toString() === sequenceId.toString()) : response.sequences[0]
          setActiveItem(selectedItem)
          setProgress((prev) => ({ ...prev, getSequence: false, }));
          setIsEmpty(response.sequences.length === 0)
        }
      })
      .catch(function (error) {
        console.error(error);
      })
  }

  const fetchDefaultTemplates = () => {
    getDefaultTemplates()
      .then(function (response) {
        if (response && response.status) {
          setDefaultTemplates([...response.templates])
          if (response.stepText) setStepText([...response.stepText])
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const fetchStrategies = async () => {
    getDefaultStrategies()
      .then((response) => {
        if (response && response.status) {
          setStrategies([...response.strategies])
          setTargetAudience(response.targetAudience)
        }
      })
      .catch((error) => {
        console.error('Error : ', error)
      })
      .finally(() => {
        setStrategyLoading(false)
      })
  }

  const fetchInitialLists = async () => {
    getAvailableList()
      .then((response) => {
        if (response && response.status) {
          setAvailableList([...response.lists])
        }
      })
      .catch((error) => {
        console.error('Error : ', error)
      })
      .finally(() => {
        setInitialListLoading(false)
      })
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const seqId = urlParams.get('seqId');

    fetchBio()
    fetchSequenceData(seqId);
    fetchDefaultTemplates();
    fetchStrategies()
    fetchInitialLists()
  }, []);


  React.useEffect(() => {
    if (!createdSequenceId) { return; }

    fetchSequenceData(createdSequenceId);
  }, [createdSequenceId]);



  const handleSequenceModification = () => {
    setActiveItem({ ...activeItem })
  }

  React.useEffect(() => {
    if (activeItem) {
      setActiveItem({ ...sequenceList.find(item => item._id.toString() === activeItem._id.toString()) });
    }
  }, [sequenceList]);

  const handleSequenceDelete = (sequenceId) => {
    try {
      Http.postData('deleteSequenceData', { seqId: sequenceId }).then(
        function (response) {
          if (response.status) {
            const filteredUserSequence = sequenceList.filter(seq => seq._id !== sequenceId)
            const selectedItem = filteredUserSequence[0]
            setActiveItem(selectedItem)
            setSequenceList(filteredUserSequence)
            setIsEmpty(filteredUserSequence.length === 0)
          }
        }
      ).finally(() => {
        //closeSettings()
      })
    } catch (error) {
      console.error(error);
    }
  };

  const processedMenuItems = sequenceList.map(item => ({
    label: item.name,
    onClick: () => handleClick(item),
    badgeText: item.status === 'Paused' ? 'Paused' : null,
    badgeTheme: "orange",
    active: activeItem._id === item._id,
  }));

  return (
    <>
      <CssBaseline />
      {!initialLoading && !strategyLoading && !initialListLoading && <SequenceModal open={modalOpen} onClose={closeModal} setCreatedSequenceId={setCreatedSequenceId} templates={defaultTemplates} templateSteps={stepText} strategies={strategies} targetAudience={targetAudience} availableList={availableList} />}
      {activeItem && (
        <>
          {pauseOpen && <PauseModal open={pauseOpen} onClose={closePause} activeItem={activeItem} onRefresh={fetchSequenceData} />}
          {deleteOpen && <DeleteModal open={deleteOpen} onClose={closeDelete} handleSequenceDelete={() => handleSequenceDelete(activeItem._id)} />}
          {renameOpen && <RenameModal open={renameOpen} onClose={closeRename} activeItem={activeItem} setCreatedSequenceId={setCreatedSequenceId} setSequenceList={setSequenceList} sequenceList={sequenceList} />}
          {editOpen && <EditModal open={editOpen} onClose={closeEdit} activeItem={activeItem} template={activeItem.template} onRefresh={fetchSequenceData} />}
        </>)}
      {/*settingsOpen && <SequenceSettings
        open={settingsOpen}
        onClose={closeSettings}
        activeItem={activeItem}
        onUpdateActiveItem={handleActiveItemUpdate}
        handleSequenceDelete={handleSequenceDelete}
        onSequenceStatusChange={handleSequenceStatusChange}
        templates={defaultTemplates}
        templateSteps={stepText}
        fetchSequenceData={fetchSequenceData} />
      */}
      <Stack direction="row" sx={{ width: '100%', height: '100vh' }}>
        <Box sx={{ maxWidth: 'fit-content', flex: 1, display: 'flex', flexDirection: 'column' }}>
          {isEmpty && progress.getSequence === false ? <EmptyState buttonClick={() => setModal(0)} />
            :
            <Box sx={{ flexGrow: 1, overflow: 'auto', width: 'fit-content' }}>
              <PSectionNav
                pVariant="primary"
                headerText="Assistants"
                headerButtonText="Create"
                headerOnClick={() => setModal(0)}
                buttonTheme="secondary"
                width="417px"
                menu={processedMenuItems}
              />
            </Box>
          }
        </Box>

        <Divider orientation="vertical" flexItem sx={{ height: '100vh' }} />

        <Box sx={{ width: 'auto', flex: 3, display: 'flex', flexDirection: 'column' }}>
          {
            progress.getContacts ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50vh' }}>
                <CircularProgress color='inherit' />
              </div>
            ) : (
              progress.getContacts === false && !isEmpty && !initialLoading && !strategyLoading && !initialListLoading && (
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  <SequenceReview
                    userBio={userBio}
                    setCreatedSequenceId={setCreatedSequenceId}
                    setSequenceList={setSequenceList}
                    sequenceList={sequenceList}
                    refreshKey={sequenceRefreshKey}
                    contacts={sequenceContacts[activeItem?._id]?.contacts || []}
                    contacts2={sequenceContacts2[activeItem?._id]?.contacts || []}
                    stepTypes={stepTypes}
                    listItem={activeItem}
                    handleSequenceModification={handleSequenceModification}
                    fetchSequenceData={fetchSequenceData}
                    handleSequenceDelete={handleSequenceDelete}
                    targetAudience={targetAudience}
                    availableList={availableList} />
                </Box>
              )
            )
          }
        </Box>

      </Stack>
    </>
  );
};

export default ClientAcquisition;