import * as React from "react";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import { handleAuthentication } from "../../helper/authUtility";
import { SetCookie } from './../../helper/cookieManager';
import { LoginLayout, loginTheme } from "./loginLayout";

import GoogleIcon from "../../assets/logos/Google-Icon.png";
import MicrosoftIcon from "../../assets/logos/MicrosoftLogo.png";
import { ThemeProvider } from "@emotion/react";
import { useTheme } from "@mui/material";

const isFromChromeExtension = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const fromParam = urlParams.get('from');
  if (!fromParam) {
    return false;
  }
  return fromParam === 'extension';
}

const doAfterChromeExtensionLogin = (luser) => {
  SetCookie('chromeExtEmail', luser.email);
  window.close();
}

export default function Login() {
  const theme = useTheme();

  const google = () => {
    const isFromChromeExt = isFromChromeExtension();
    handleAuthentication("/api/auth/google", isFromChromeExt ? doAfterChromeExtensionLogin : null);
  };

  const microsoft = () => {
    const isFromChromeExt = isFromChromeExtension();
    handleAuthentication("/api/auth/microsoft", isFromChromeExt ? doAfterChromeExtensionLogin : null);
  };

  return (
    <LoginLayout>
      <ThemeProvider theme={loginTheme}>
          <Button
            variant="contained"
            component={Link}
            onClick={google}
            sx={{
              mt: 3,
              py: 2,
              backgroundColor: theme.palette.primary.white,
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "none",
              borderRadius: 1,
              width: "100%",
              boxShadow: "none",
              fontFamily: "Roboto",
              justifyContent: "flex-start",
              paddingLeft: "16px",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.168)",
              "&:hover": {
                backgroundColor: theme.palette.primary.white,
              },
              "&:active": {
                backgroundColor: "#EEEEEE",
              },
            }}
          >
            <img
              src={GoogleIcon}
              width="18px"
              style={{ marginRight: "16px" }}
            />
            Sign in with Google
          </Button>
          <Button
            variant="contained"
            component={Link}
            onClick={microsoft}
            sx={{
              mt: 3,
              py: 2,
              backgroundColor: theme.palette.primary.white,
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "none",
              borderRadius: 1,
              width: "100%",
              boxShadow: "none",
              fontFamily: "Roboto",
              justifyContent: "flex-start",
              paddingLeft: "16px",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.168)",
              "&:hover": {
                backgroundColor: theme.palette.primary.white,
              },
              "&:active": {
                backgroundColor: "#EEEEEE",
              },
            }}
          >
            <img
              src={MicrosoftIcon}
              width="18px"
              style={{ marginRight: "16px" }}
            />
            Sign in with Microsoft
          </Button>
      </ThemeProvider>
         </LoginLayout>
  );
}
