import * as React from "react";
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Config from "../../clientConfig/config";
import Container from "@mui/material/Container";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomModal from "./components/confirmModal";
import { handleAuthentication } from "../../helper/authUtility";

import { useTheme } from "@mui/material";

import { ReactComponent as LoginLogo } from "../../assets/Icons/LoginLogo.svg";

import TOSModal from "./components/tosModal";
import PrivacyModal from "./components/privacyModal";

export const loginTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          background: #3063EA
        }
      `,
    },
  },
});

export const LoginLayout = ({ children }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState(false);
  const [validateModalOpen, setValidateModalOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");

  const [openTOSModal, setOpenTOSModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  const handleOpenTOSModal = () => setOpenTOSModal(true);
  const handleCloseTOSModal = () => setOpenTOSModal(false);
  const handleOpenPrivacyModal = () => setOpenPrivacyModal(true);
  const handleClosePrivacyModal = () => setOpenPrivacyModal(false);


  React.useEffect(() => {
    console.log(openModal);
  }, [openModal]);

  const handleModalAction = (action) => {
    setValidateModalOpen(false);
    setAlertText("");
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="md"
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.lightGrey,
            px: 10,
            py: 11,
            borderRadius: 5,
            minWidth: 300,
          }}
        >
          {validateModalOpen && (
            <CustomModal
              clickAction={handleModalAction}
              alertText={alertText}
            />
          )}

          <LoginLogo width={150} height={25} />

          <Typography
            sx={{
              fontSize: "35px",
              fontWeight: 700,
              color: theme.palette.primary.grey,
              lineHeight: "1",
              mt: 1,
            }}
          >
            <span style={{ color: theme.palette.primary.blue }}>
              AI-powered
            </span>{" "}
            customer acquisition and retention tools.
          </Typography>
          {children}
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              py: 2,
              color: theme.palette.primary.grey,
              "& a": {
                color: "inherit",
                textDecoration: "none",
                borderBottom: "1px solid currentColor",
                paddingBottom: "2px",
                transition: "border-color 0.3s",
                "&:hover": {
                  borderColor: "transparent",
                },
              },
            }}
          >
            <p>
              By signing in, I agree to the{" "}
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: "14px",
                  fontWeight: 800,
                  color: theme.palette.primary.grey,
                  cursor: 'pointer',
                }}
                onClick={handleOpenTOSModal}
              >
                TOS
              </Typography>{" "}
              and{" "}
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: "14px",
                  fontWeight: 800,
                  color: theme.palette.primary.grey,
                  cursor: 'pointer',
                }}
                onClick={handleOpenPrivacyModal}
              >
                Postilize Privacy Policy
              </Typography>
            </p>
            <TOSModal open={openTOSModal} close={handleCloseTOSModal} />
            <PrivacyModal
              open={openPrivacyModal}
              close={handleClosePrivacyModal}
            />
          </Typography>
        </Box>
      </Container>
      <Outlet />
    </ThemeProvider>
  );
}
