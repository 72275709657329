import { ErrorCodes } from '@/constants/errorHandlers'
import { SetupConst, ProviderType } from '@/constants/constant'
import sizeof from 'object-sizeof'

export function CountByPercent (percent, total) {
  return (percent == 0 || total == 0) ? 0 : Math.round((percent / 100) * total)
}

export function PercentByCount (count, total) {
  return (count == 0 || total == 0) ? 0 : Math.round((count / total) * 100)
}

export function getProcessingTime (min, max) {
  return Math.floor(
    Math.random() * (max - min) + min
  )
}

export function generateRandomId(length) {
  const allowedKeys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomId = ''

  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * allowedKeys.length)
    randomId += allowedKeys.charAt(index)
  }

  return randomId
}

export function replacePlaceholdersWithValue(value, modifiers) {
  if (!value) { return }

  const placeholderRegex = /{([^}]+)}/g

  const replacedString = value.replace(placeholderRegex, (match, placeholderName) => {
    const modifier = modifiers.find((m) => m.name === `{${placeholderName}}`)
    return modifier ? modifier.value : match
  })

  return replacedString
}

export function CompareSequenceStartEndAscending (obj1, obj2) {
  const startDate1 = new Date(obj1.startDate)
  const endDate1 = new Date(obj1.endDate)
  const startDate2 = new Date(obj2.startDate)
  const endDate2 = new Date(obj2.endDate)

  if (startDate1 < startDate2) {
    return -1
  } else if (startDate1 > startDate2) {
    return 1
  } else {
    if (endDate1 < endDate2) {
      return -1
    } else if (endDate1 > endDate2) {
      return 1
    } else {
      return 0
    }
  }
}

export function snakeCaseToCamelCase (input) {
  if (!input) return

  const topLevelJobTitleAbbreviations = [
    'ceo', // Chief Executive Officer
    'cfo', // Chief Financial Officer
    'cto', // Chief Technology Officer
    'coo', // Chief Operating Officer
    'cio', // Chief Information Officer
    'cmo', // Chief Marketing Officer
    'chro', // Chief Human Resources Officer
    'cso', // Chief Security Officer
    'clo', // Chief Legal Officer
    'cco', // Chief Compliance Officer
    'cdo', // Chief Data Officer
    'cpo', // Chief Procurement Officer
    'caio', // Chief Artificial Intelligence Officer
    'csio', // Chief Strategy Information Officer
    'cbo', // Chief Business Officer
    'cno', // Chief Networking Officer
    'cro', // Chief Risk Officer
    'csro', // Chief Social Responsibility Officer
    'ckao', // Chief Knowledge Officer
    'cvo', // Chief Visionary Officer
    'cpeo', // Chief People Officer
    'ctro', // Chief Technology Risk Officer
    'ccuo', // Chief Customer Officer
    'cpro', // Chief Product Officer
    'civo', // Chief Innovation Officer
    'cdao', // Chief Data Analytics Officer
    'cgdo', // Chief Growth Development Officer
    'cclo', // Chief Creative Lead Officer
    'coco', // Chief Operational Compliance Officer
    'cqo' // Chief Quality Officer
  ]

  const isAbbreviation = (word) => word.length <= 4

  return input.replace(/(?:^|_)([a-zA-Z.]+)/g, (match, p1, offset) => {
    const wordLower = p1.toLowerCase()

    if (p1.includes('.')) {
      return (offset > 0 ? ' ' : '') + p1.split('.').map(s => s.toUpperCase()).join('.')
    }

    if (topLevelJobTitleAbbreviations.includes(wordLower) || isAbbreviation(wordLower)) {
      return (offset > 0 ? ' ' : '') + wordLower.toUpperCase()
    } else {
      return (offset > 0 ? ' ' : '') + wordLower.charAt(0).toUpperCase() + wordLower.slice(1)
    }
  })
}

export function truncateText(str, n, ellipsis = '...') {
  return (str?.length > n) ? str.slice(0, n-1) + ellipsis : str;
}

export function parseDateValue(month, day, year) {
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  const parsedYear = parseInt(year, 10);

  if (
    isNaN(parsedMonth) ||
    isNaN(parsedDay) ||
    isNaN(parsedYear) ||
    parsedMonth < 1 ||
    parsedMonth > 12 ||
    parsedDay < 1 ||
    parsedDay > 31 ||
    parsedYear < 1000 ||
    parsedYear > 9999
  ) {
    return null;
  }

  const date = new Date(parsedYear, parsedMonth - 1, parsedDay);
  return date
}

export function validatedContacts(contact, forContinue = false ) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  let resContacts = null

  const { first_name, last_name, email, month, day, year, anniversaryMonth, 
          anniversaryDay, anniversaryYear, contractRenewalMonth, contractRenewalDay, 
          contractRenewalYear, role, companyName, twitter_url, linkedin_url, phoneNumber } = contact

  if(first_name && last_name &&
    // Temporarily removing email as required field
     (!email || emailRegex.test(email))) {
    const isMonthEmpty = !month?.trim();
    const isDayEmpty = !day?.trim();
    const isYearEmpty = !year?.trim();
    let birthday = null

    const isAnniversaryMonthEmpty = !anniversaryMonth?.trim();
    const isAnniversaryDayEmpty = !anniversaryDay?.trim();
    const isAnniversaryYearEmpty = !anniversaryYear?.trim();
    let anniversary = null

    const isContractRenewalMonthEmpty = !contractRenewalMonth?.trim();
    const isContractRenewalDayEmpty = !contractRenewalDay?.trim();
    const isContractRenewalYearEmpty = !contractRenewalYear?.trim();
    let contractRenewal = null

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (month && year && day) {
      birthday = parseDateValue( month, day, year)
      if (!birthday || birthday.getTime() > today.getTime() ) {
        return { code: ErrorCodes.INVALID_BIRTHDAY, contact }
      }
    } else {
      if (!isMonthEmpty || !isDayEmpty || !isYearEmpty){
        return { code: ErrorCodes.INCOMPLETE_BIRTHDAY, contact }
      }
    }

    if (anniversaryMonth && anniversaryDay && anniversaryYear ) {
      anniversary = parseDateValue(anniversaryMonth, anniversaryDay, anniversaryYear)
      if (!anniversary) {
        return { code: ErrorCodes.INVALID_ANNIVERSARY, contact }
      }
    } else {
      if(!isAnniversaryMonthEmpty || !isAnniversaryDayEmpty || !isAnniversaryYearEmpty){
        return { code: ErrorCodes.INCOMPLETE_ANNIVERSARY, contact }
      }
    }
    
    if ( contractRenewalMonth && contractRenewalDay && contractRenewalYear) {
      contractRenewal = parseDateValue(contractRenewalMonth, contractRenewalDay, contractRenewalYear)

      if (!contractRenewal) {
        return { code: ErrorCodes.INVALID_CONTRACT_RENEWAL, contact }
      } else {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        contractRenewal.setHours(23, 59, 59, 999)
        if (contractRenewal.getTime() < today.getTime()) {
          return { code: ErrorCodes.INVALID_CONTRACT_RENEWAL, contact }
        }
      }
    } else {
      if(!isContractRenewalMonthEmpty || !isContractRenewalDayEmpty || !isContractRenewalYearEmpty){
        return { code: ErrorCodes.INCOMPLETE_CONTRACT_RENEWAL, contact }
      }
    }

    const newContact = { 
      first_name,
      last_name,
      email,
      linkedin_url: linkedin_url ?? null,
      twitter_url: twitter_url ?? null,
      company: companyName ?? null,
      birthday: birthday ?? null,
      anniversary: anniversary ?? null,
      contractRenewal: contractRenewal ?? null,
      title: role ?? null,
      phoneNumber: phoneNumber ?? null,
    };

    resContacts = newContact

  } else {
    if(forContinue && !first_name?.length || !last_name?.length
      // Temporarily removing email as required field
      // || !email?.length
    ){
      return { code: ErrorCodes.REQUIRED_FIELDS_MISSING, contact }
    }
    else if (!first_name?.length || !last_name?.length
      // Temporarily removing email as required field
      // || !email?.length
    ) {
      return { code: ErrorCodes.REQUIRED_FIELDS_MISSING, contact }
    } else if (email && !emailRegex.test(email)) {
      return { code: ErrorCodes.INVALID_EMAIL, contact }
    }
  }

  return { code: ErrorCodes.VALID_CONTACT, contact: resContacts }
}

export function ordinalSuffixOf(i) {
  const j = i % 10,
        k = i % 100
  if (j === 1 && k !== 11) {
    return i + "st"
  }
  if (j === 2 && k !== 12) {
    return i + "nd"
  }
  if (j === 3 && k !== 13) {
    return i + "rd"
  }
  return i + "th";
}

export function postScheduledTiming(post, forApproval = false) {
  if (!post.scheduledDate) {
    return ''
  }

  const timeNow = new Date()
  const dateVal = new Date(post.scheduledDate)
  const diff = dateVal.getTime() - timeNow.getTime()

  if (diff <= 0) {
    return (!post.approved) ? 'Needs Approval' : 'Today'
  }

  const mins = 60 * 1000
  const hrs = 60 * mins
  const day = 24 * hrs
  const week = 7 * day
  const month = 30 * day

  const months = Math.floor(diff / month)
  if (months > 0) {
    var dayVal = dateVal.getDate();
    var monthVal = dateVal.toLocaleString('default', { month: 'short' });
    var yearVal = dateVal.getFullYear();

    return (dayVal + ' ' + monthVal + ' ' + yearVal)
  }

  const weeks = Math.floor(diff / week)
  if (weeks > 0) { return `${forApproval ? 'Sends in' : 'In'} ${weeks}w` }

  const days = Math.floor(diff / day)
  if (days > 0) { return `${forApproval ? 'Sends in' : 'In'} ${days}d` }

  const hours = Math.floor(diff / hrs)
  if (hours > 0) { return `${forApproval ? 'Sends in' : 'In'} ${hours}h` }

  const minutes = Math.floor(diff / mins)
  if (minutes > 0) { return `${forApproval ? 'Sends in' : 'In'} ${minutes}m` }

  return `${forApproval ? 'Sends in' : 'In'} few secs`
}

export function getTimeUntilScheduledDate(scheduledDate) {
  if (!scheduledDate) {
    return '';
  }

  const timeNow = new Date();
  const dateVal = new Date(scheduledDate);
  const diff = dateVal.getTime() - timeNow.getTime();

  if (diff <= 0) {
    return 'Today';
  }

  const mins = 60 * 1000;
  const hrs = 60 * mins;
  const day = 24 * hrs;
  const week = 7 * day;
  const month = 30 * day;

  const months = Math.floor(diff / month);
  if (months > 0) {
    return dateVal.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' });
  }

  const weeks = Math.floor(diff / week);
  if (weeks > 0) { return `In ${weeks}w`; }

  const days = Math.floor(diff / day);
  if (days > 0) { return `In ${days}d`; }

  const hours = Math.floor(diff / hrs);
  if (hours > 0) { return `In ${hours}h`; }

  const minutes = Math.floor(diff / mins);
  if (minutes > 0) { return `In ${minutes}m`; }

  return 'In few secs';
}

export function formatLargeNumber(num) {
  if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + 'M';
  } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + 'K';
  } else {
      return num.toString();
  }
}

export function validateEmailAddress(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function getUserDisplayString(member) {
  return member.name || member.primaryEmail;
}

export function getSelectedUsersDisplayString(allUsers, selectedUsers) {
  return selectedUsers
    .map((userId) => allUsers.find((user) => user._id === userId))
    .map((user) => truncateText(getUserDisplayString(user), 20))
    .join(', ');
}

export function getSelectedAssistantsString(allAssistants, selectedAssistants) {
  if (!allAssistants || !selectedAssistants) {
    return '';
  }

  return selectedAssistants
    .map((seqName) => allAssistants.find((asst) => asst.name === seqName))
    .filter(Boolean)
    .map((seq) => truncateText(seq.name, 50))
    .join(', ');
}


export function ConstructUserCookie(userObj, getCookieResult) {
  if (!userObj) {
    throw new Error('User object is empty.');
  }

  const MAX_COOKIE_SIZE = 4000;

  var cookieData = {
    _id: userObj._id,
    id: userObj.id,
    name: userObj.name,
    imageUrl: userObj.profileUrl,
    userType: userObj.userType,
    userRoles: userObj.userRoles,
    initialSetupDone: userObj.initialSetupDone,
    tonesSetupDone: userObj.tonesSetupDone,
    topicsSetupDone: userObj.topicsSetupDone,
    importListSetupDone: userObj.importListSetupDone,
    importListFiltersSetupDone: userObj.ImportListFiltersSetupDone,
    focusContactsSetupDone: userObj.focusContactsSetupDone,
    email: userObj.emailNotifier?.email,
    disableTourGuide: userObj.disableTourGuide,
    providers: [],
    callbackStep:
      getCookieResult && getCookieResult.callbackStep ? getCookieResult.callbackStep : SetupConst.Steps.TONES,
    redirected: getCookieResult && getCookieResult.redirected ? getCookieResult.redirected : false,
    companyId: userObj.companyId,
  };

  let existingProviders = [];
  let newlistedProviders = [];
  if (getCookieResult && getCookieResult.providers) {
    getCookieResult.providers.forEach((element) => {
      if (element) {
        existingProviders.push(element.provider);
      }
    });
  }

  if (userObj.providers.length > 0) {
    userObj.providers.forEach((element) => {
      if (element.provider.length > 0 && !existingProviders.includes(element.provider)) {
        newlistedProviders.push(element.provider);
      }

      let p = null;
      if (getCookieResult && getCookieResult.providers) {
        p = getCookieResult.providers.find((acc) => acc !== null && acc.provider === element.provider);
      }

      const providerObj = {
        provider: element.provider,
        profileId: element.profileId,
        email: element.email,
        primary: element.primary,
        imageUrl: element.profileImageUrl,
        isInitialEnrichDone: element.isInitialEnrichDone ?? true,
        isOnBoard: p && p.isOnBoard ? p.isOnBoard : element.isOnBoard,
      };

      if (!cookieData.email && element.primary) {
        cookieData.email = element.email ? element.email : '';
      }

      cookieData.providers.push(providerObj);
    });
  }

  if (existingProviders.length > 0 && newlistedProviders.length > 0) {
    let navigateStep = SetupConst.Steps.TONES;
    if (ProviderType.IMPORT_LIST_FILTERS.includes(newlistedProviders[0])) {
      navigateStep = SetupConst.Steps.IMPORT_LIST;
    } else if (ProviderType.SOCIAL_NETWORKS.includes(newlistedProviders[0])) {
      navigateStep = SetupConst.Steps.CONNECT;
    }
    cookieData.callbackStep = navigateStep;
  }

  const sizeObj = sizeof(cookieData);
  if (sizeObj > MAX_COOKIE_SIZE) {
    console.error(`The cookie with size ${sizeObj} bytes exceeded the maximum limit.`);
}

  return JSON.stringify(cookieData);
}
