import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PContactCardInfo from '@Library/PContactCardInfo';
import { handleContactRecommendation } from '@/helper/apiHelper';

export default function ContactCard({ contactPosts }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (Array.isArray(contactPosts)) {
      const validPosts = contactPosts.filter(
        post => post.recommendationId && post.contactDetails
      );
      setPosts(validPosts);
    }
  }, [contactPosts]);

  // Function to handle adding or removing contact recommendations
  const handleContactAction = async (contact) => {
    const { recommendationId, action } = contact;

    try {
      if (action === 'Approve') {
        await handleContactRecommendation(recommendationId, 'accept');
        console.log('Contact approved successfully');
      } else if (action === 'Delete') {
        await handleContactRecommendation(recommendationId, 'reject');
        console.log('Contact removed successfully');
      }
    } catch (error) {
      console.error(`Failed to ${action === 'Approve' ? 'approve' : 'remove'} contact`, error);
    }
  };

  return (
    <Box>
      {posts?.length > 0 && (
        posts.map((post, index) => (
          <div key={index} style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingLeft: '14px',
            paddingRight: '14px',
            paddingTop: '28px',
          }}>
            <PContactCardInfo
              sequenceDetails={post.sequenceDetails}
              contact={{
                name: post.contactDetails.name,
                email: post.contactDetails.email,
                title: post.contactDetails.title,
                company: post.contactDetails.company,
                linkedin: post.contactDetails.linkedin,
                avatarUrl: post.contactDetails.avatarUrl || '',
                sequenceName: post.contactDetails.sequenceName,
              }} 
              recommendationId={post.recommendationId}
              onClick={handleContactAction}
            />
          </div>
        ))
      )}
    </Box>
  );
}

ContactCard.propTypes = {
  contactPosts: PropTypes.array.isRequired
};
