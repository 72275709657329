import axios from 'axios'
import { GetCookie } from '../helper/cookieManager'

class HttpClient {
  constructor() {
    this.user = GetCookie('user')
    this.instance = axios.create({
      baseURL: '/api',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Agent': false,
      },
      withCredentials: true
    })
  };

  async getData(url, data = String.empty) {
    try {
      if (!this.user) {
        this.user = GetCookie('user')
        if (!this.user) throw 'User Session Not Found.'
      }

      const response = await this.instance.get(`/${url}`,
        {
          params: {
            id: this.user._id,
            userId: this.user.id,
            username: this.user.name,
            data
          }
        }
      )

      return response.data
    } catch (error) {
      console.error(error)
      if (((typeof (error) === 'string') && (error == 'User Session Not Found.')) ||
        ((error.response.status == 401 && error.response.data.message === 'User session expired.'))) {
        console.log('Going to redirect to login page')
        window.location.href = '/'
      }
    }

    return null
  };
  async postData(url, data = String.empty) {
    try {
      if (!this.user) {
        this.user = GetCookie('user')
        if (!this.user) throw 'User Session Not Found.'
      }

      const response = await this.instance.post(`${url}`, {
        id: this.user._id,
        userId: this.user.id,
        username: this.user.name,
        data
      }
      )

      return response.data
    } catch (error) {
      if (error.response) {
        console.error('Error status:', error.response.status)
        console.error('Error data:', error.response.data)
        return error.response
      } else {
        console.error(error)
      }
    }

    return null
  };

  async postDataAndReturnFullResponse(url, data = String.empty) {
    try {
      if (!this.user) {
        this.user = GetCookie('user');
        if (!this.user) throw 'User Session Not Found.';
      }

      const response = await this.instance.post(`${url}`, {
        id: this.user._id,
        userId: this.user.id,
        username: this.user.name,
        data,
      });

      return response; // <-- Where the magic happens
    } catch (error) {
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
        return error.response;
      } else {
        console.error(error);
      }
    }

    return null;
  }

  async putData(url, data = {}) {
    try {
      if (!this.user) {
        this.user = GetCookie('user');
        if (!this.user) throw new Error('User Session Not Found.');
      }

      const response = await this.instance.put(`${url}`, data);

      return response.data;
    } catch (error) {
      console.error(error);
      // Handle unauthorized or session not found errors specifically
      if ((typeof error === 'string' && error === 'User Session Not Found.') ||
        (error.response && error.response.status === 401 && error.response.data.message === 'User session expired.')) {
        console.log('Redirecting to login page');
        window.location.href = '/';
        return null;
      } else if (error.response) {
        // Handle other API errors
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
        return null;
      }
    }
  }

  async deleteData(url) {
    try {
      if (!this.user) {
        this.user = GetCookie('user');
        if (!this.user) throw new Error('User Session Not Found.');
      }

      const response = await this.instance.delete(url);
      return response.data;
    } catch (error) {
      console.error(error);
      // Similar error handling as in putData
      if ((typeof error === 'string' && error === 'User Session Not Found.') ||
        (error.response && error.response.status === 401 && error.response.data.message === 'User session expired.')) {
        console.log('Redirecting to login page');
        window.location.href = '/';
        return null;
      } else if (error.response) {
        // Handle other API errors
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
        return null;
      }
    }
  }


  async postFormData(url, data) {
    try {
      if (!this.user) {
        this.user = GetCookie('user')
        if (!this.user) throw 'User Session Not Found.'
      }

      data.append('id', this.user._id)
      data.append('userId', this.user.id)
      data.append('username', this.user.name)

      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      })

      return response.data
    } catch (error) {
      console.error(error)
    }

    return null
  }

  async postDataAnonymously(url, data = String.empty) {
    try {
      const response = await this.instance.post(`${url}`, { data })

      return response.data
    } catch (error) {
      console.error(error)
    }

    return null
  }

  async postUnauthenticatedData(url, data = {}) {
    try {
      const response = await this.instance.post(url, data);
      // Return a consistent structure for success responses
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      // Always return a consistent structure, even for errors
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
        return { success: false, data: error.response.data, status: error.response.status };
      } else {
        // Handle network errors or other issues that don't have an HTTP status code
        console.error(error);
        return { success: false, data: null, status: null, error: error.message };
      }
    }
  }


};

export default new HttpClient()
