import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import { Avatar, Badge, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Gift from '@mui/icons-material/CardGiftcard';
import Mail from '@mui/icons-material/Mail';
import PTypography from '@Library/PTypography';
import { TopicType } from '@/constants/constant';
import { truncateText, postScheduledTiming } from '@/helper/utilities';
import ManualListModal from "@/pages/setup/simpleSetupComponents/Lists/ManualListModal";
import { useSelector, useDispatch } from "react-redux";
import { refreshSequencesAndContacts } from "@/App";
import { setActionFlag } from "@/redux/authSlice";

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        border: theme.palette.primaryCL.Black70,
        hoverBorder: theme.palette.primaryCL.Black70,
        activeBorder: theme.palette.primaryCL.Blue100,
        button: theme.palette.primaryCL.Blue100,
        buttonBG: theme.palette.primaryCL.White100,
        disabledText: theme.palette.primaryCL.Black100,
        disabledBackground: theme.palette.primaryCL.Black50,
        disabledButton: theme.palette.primaryCL.Black70,
        disabledButtonBG: theme.palette.primaryCL.White110,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

const StyledPCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, disabled }) => {
  const {
    background,
    text,
    border,
    hoverBorder,
    activeBorder,
    disabledBackground,
    hoverBackground,
    disabledText,
  } = getVariantStyles(theme, pVariant);

  return {
    borderRadius: '14px',
    border: '1px solid',
    borderColor: border,
    padding: '16px',
    width: '100%',
    height: 'auto',
    fontFamily: 'Inter',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    color: disabled ? disabledText : text,
    backgroundColor: disabled ? disabledBackground : background,
    '&:hover': {
      borderColor: hoverBorder,
      backgroundColor: disabled ? disabledBackground : hoverBackground,
    },
    '&:active': {
      borderColor: activeBorder,
    },
    // Apply any additional styles you need here
  };
});

const getBadgeContent = (post) => {
  if (post.source === 'twitter') {
    return (<TwitterIcon sx={{ fontSize: 12 }} />)
  } else if (post.source === 'linkedin' || post.source === 'linkedin-comment' || post.topic_type === 'linkedInEmail') {
    return (<LinkedInIcon sx={{ fontSize: 12 }} />)
  } else if (post.topic_type === 'gift') {
    return (<Gift sx={{ fontSize: 12 }} />)
  } else {
    return (<Mail sx={{ fontSize: 12 }} />)
  }
}

const getPostHeader = (post) => {
  let header = post.name
  if (post.source === 'twitter') {
    header = post.topic === 'twitter_tweet' ? `Reply to ${post.name}` : 'Post on Twitter'
  } else if (post.source === 'linkedin') {
    header = 'Post on LinkedIn'
  }
  return header
}

const getPostMessage = (message) => {
  return truncateText(message, 50) || ''
}

const PScheduledPostCard = ({ post, pVariant, disabled, onClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allKnownSequences = useSelector((state) => state.auth.sequences);
  const [editContactModalOpen, setEditContactModalOpen] = useState(false);
  const [editContact, setEditContact] = useState(null);

  useEffect(() => {
    if ((!post?.sequenceAttr?.sequenceId && !post?.extraData?.sequenceId) || (!post?.sequenceAttr?.contactId && !post?.extraData?.contactId)) {
      return;
    }

    const sequenceId = post.sequenceAttr?.sequenceId || post?.extraData?.sequenceId;
    const contactId = post.sequenceAttr?.contactId || post.extraData?.contactId;

    const mySequence = allKnownSequences[sequenceId];
    const myContact = mySequence?.contacts?.[contactId];

    setEditContact(myContact);
  }, [allKnownSequences, post]);

  const handleAvatarClick = useCallback((event) => {
    event.stopPropagation();
    if (post.source === 'twitter' || post.source === 'linkedin') {
        onClick(post); 
    } else {
        setEditContactModalOpen(true);
    }
}, [post, onClick]);

  const handleCloseModal = useCallback((event, refresh) => {
    setEditContactModalOpen(false);
    if (refresh) {
      setTimeout(() => {
        refreshSequencesAndContacts(dispatch);
        dispatch(setActionFlag({ flag: 'refreshPosts', value: true }));
      }, 250);
    }
  }, [dispatch]);

  const editContactModalElement = useMemo(() => {
    return editContactModalOpen ? (
      <ManualListModal
        open={true}
        onClose={(e) => handleCloseModal(e, false)}
        contact={editContact}
        forSetup={false}
        refreshListContacts={(e) => handleCloseModal(e, true)}
      />
    ) : null;
  }, [editContactModalOpen, editContact, handleCloseModal]);

  const handleMessageBodyClick = useCallback((event) => {
    const allowedTags = ['P', 'SPAN', 'DIV'];
    if (!allowedTags.includes(event?.target?.tagName)) {
      return;
    }
    if (editContactModalOpen) {
      return;
    }
    onClick(post);
  }, [onClick, post, editContactModalOpen]);

  return (
    <StyledPCard
      disableElevation
      pVariant={pVariant}
      disabled={disabled}
      onClick={!editContactModalOpen ? handleMessageBodyClick : undefined}
      sx={{ cursor: 'pointer' }}
    >
      {editContactModalElement}
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%' }}>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={getBadgeContent(post)}
            sx={{
              '& .MuiBadge-badge': {
                color: '#4999E9',
                backgroundColor: 'white',
                border: '1px solid #4999E9',
                mx: 1
              }
            }}
          >
            <Avatar sx={{ mx: 1, cursor: 'pointer' }} src={post.ImageUrl} onClick={handleAvatarClick} />
          </Badge>
          <Stack direction="row" alignItems="flex-start">
            <div style={{ marginTop: '8px', cursor: 'pointer' }} onClick={handleAvatarClick}>
              <PTypography size="body2" weight="bold">
                {getPostHeader(post)}
              </PTypography>
            </div>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <PTypography size="footnote" weight="regular">
            {postScheduledTiming(post)}
          </PTypography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2} sx={{ ml: '68px' }}>
        {post !== null && post.source === 'sequence' && ['meeting', 'followup', 'email', 'checkin.recurring', 'gift', 'linkedInEmail'].includes(post.topic_type) &&
          <div style={{ paddingBottom: '8px', }}>
            <PTypography size="body2" weight="regular">
              {getPostMessage(post.subject)}
            </PTypography>
            <div style={{ marginTop: '8px' }}>
              <PTypography size="body1" weight="regular">
                {getPostMessage(post.message)}
              </PTypography>
            </div>
          </div>}
        {post !== null && post.topic_type === TopicType.USER_POST &&
          <div style={{ paddingBottom: '8px' }}>
            <div style={{ marginTop: '8px' }}>
              <PTypography size="body2" weight="regular">
                {getPostMessage((post.topic === "twitter_tweet" && post.responseTo !== null) ? post.responseTo.message : post.message)}
              </PTypography>
            </div>
          </div>}
        {post !== null && post.topic_type === TopicType.IMAGE_POST && post.uploadDetails?.preSignedURL &&
          <Box
            component='img'
            sx={{ height: 250, width: 350, m: 2, borderRadius: 3 }}
            alt='imagepost'
            src={post.uploadDetails?.preSignedURL}
          />}
      </Stack>
    </StyledPCard>
  );
};

PScheduledPostCard.propTypes = {
  /** React children */
  children: PropTypes.node,

  /** Postilize design variant. So far, all we have is primary, keeping for posterity */
  pVariant: PropTypes.oneOf(['primary']),

  /** Disable state of the button */
  disabled: PropTypes.bool,

  /** Click handler */
  onClick: PropTypes.func.isRequired,

  /** post*/
  post: PropTypes.object,
};

PScheduledPostCard.defaultProps = {
  pVariant: 'primary',
  disabled: false,
};

export default PScheduledPostCard;
