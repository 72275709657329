import * as React from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import Http from '../../../http/httpClient'
import UserStat from './userStat'

const StyledTableCell = styled(TableCell)(() => ({
  padding: '8px 16px',
  borderBottom: 'none'
}))

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 'bold',
  textAlign: 'left',
  width: '15%',
  backgroundColor: theme.palette.action.selected,
  borderBottom: '1px solid white'
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  transition: theme.transitions.create('background-color'),
  '&:hover': {
    backgroundColor: theme.palette.action.selected
  },
  borderBottom: 'none'
}))

export default function UserStats () {
  const [isLoading, setIsLoading] = React.useState(true)
  const [userStats, setUserStats] = React.useState([])
  const [buttonLoad, setButtonLoad] = React.useState(false)

  React.useEffect(() => {
    const fetchUserStats = async () => {
      setIsLoading(true)
      Http.getData('userStats/list')
        .then((res) => {
          setUserStats(res.userStats)
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          setIsLoading(false)
        })
    }

    fetchUserStats()
  }, [])

  const handleCloseModal = async (userStats) => {
    if (userStats) {
      try {
        /*const response = await Http.postData('companies/updateOrCreate', { companyDetails })
        if (response && response.status) {
          setCompanies(prevCompanies => {
            const index = prevCompanies.findIndex(company => company._id === response.company._id)
            if (index === -1) {
              const newCompanies = [...prevCompanies, response.company]
              return newCompanies
            } else {
              const updated = [...prevCompanies]
              updated[index] = response.company
              return updated
            }
          })
        } */
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      {
        isLoading
          ? <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          :
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              <TableHead>
                <StyledTableRow>
                  <StyledHeaderTableCell>User Name</StyledHeaderTableCell>
                  <StyledHeaderTableCell>User ID</StyledHeaderTableCell>
                  <StyledHeaderTableCell>No. of Logins</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Replies</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Posts</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Deleted Replies</StyledHeaderTableCell>
                  <StyledHeaderTableCell>Deleted Posts</StyledHeaderTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {
                  (userStats && userStats.length > 0)
                    ? userStats.map((userStat) => (userStat && <UserStat stats={userStat} handleCloseModal={handleCloseModal} buttonLoad={buttonLoad} />))
                    : (
                        <TableRow>
                          <TableCell align="center" colSpan={"7"}>
                            <Typography align='center' gutterBottom>
                              There is no user stats exists with this environment.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                }
              </TableBody>
            </Table>
          </TableContainer>
      }
    </>
  )
}
